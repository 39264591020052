import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { CarrouselArrow } from "./styles";

export default function Arrow({ directon, onCLick, disabled }) {
  const ArrowComponent = directon === 'left' ? FaAngleLeft : FaAngleRight;

  return (
    <CarrouselArrow onClick={onCLick} directon={directon} disabled={disabled}>
      <ArrowComponent size="25px" color="white" />
    </CarrouselArrow>
  )
}
