import styled from "styled-components";
import colors from "../../../../../styles/colors";

export const AccordionHeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-bottom: 16px;
  border-bottom: 1px solid #B1ADAD;
  cursor: pointer;

  &.active, &:hover {
    border-bottom: 1px solid ${colors.red};
  }

  @media screen and (min-width: 1024px) {
    justify-content: center;
  }
`;

export const AccordionTitle = styled.h3`
  font-weight: 400;
  width: 80%;
  font-size: 16px;
  line-height: 18px;

  &.active {
    font-weight: 700;
  }
  
  @media screen and (min-width: 1024px) {
    font-size: 20px;
    width: 100%;
    line-height: 22.8px;
    padding: 0 30px;
    text-align: center;
  }
`;

export const AccordionIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const Button = styled.button`
  border: 1px solid ${colors.red};
  border-radius: 25px;
  height: 24px;
  width: 24px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    transition: all 0.6s;
  }

  &.active img {
    transform: rotate(45deg);
  }

  @media screen and (min-width: 1024px) {
    height: 30px;
  width: 30px;
  }
`;