import styled from "styled-components";
import colors from "../../../styles/colors";
import CustomContainer from "../../Common/Container";

export const LineHeader = styled.div`
  height: 29px;
  width: 100%;
  background: ${colors.lightpurple};
  transform: matrix(-1, 0, 0, 1, 0, 0);
`;

export const Section = styled.section`
  background-color: ${colors.darkpurple};
  padding: 80px 0;

  @media (min-width: 1024px) {
    padding: 40px 0;
  }
`;

export const Container = styled(CustomContainer)`
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  gap: 38px;
  
  @media (min-width: 1024px) {
    gap: 138px;
    flex-direction: row;
    height: 643px;
  }
`;

export const GraduaitonContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 38px;
  width: 300px;
  align-items: center;

  @media screen and (min-width: 1024px) {
    align-items: flex-start;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  gap: 12px;
`;

export const Title = styled.h2`
  font-size: ${({ strong }) => (strong ? "72px" : "32px")};
  line-height: 36px;
  font-weight: ${({ strong }) => (strong ? "800" : "400")};
  color: white;
  text-transform: uppercase;
  display: ${({ strong }) => (strong ? "flex" : "block")};
  align-items: center;

  span {
    margin-right: -25px;
    z-index: 1;
  }
`;

export const Detail = styled.p`
  background-image: url(${({ src }) => (src)});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  width: 151px;
  height: 45px;
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: white;
`;

export const Button = styled.button`
  display: flex;
  border: none;
  color: white;
  background: url(${({ src }) => (src)});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 8px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 284px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
`;

export const Carroussel = styled.div`
  width: 90%;
`;

export const Professionals = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  margin: 0 auto;
  max-width: 850px;

  ::-webkit-scrollbar {
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
`;

export const ProfessionalCard = styled.div`
  flex: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => (src)});
  padding: 180px 20px 10px;
  width: 282px;
  height: 330px;
  border-radius: 13px;
  margin: 16px;
  color: #fff;
  overflow: hidden;
  position: relative;
`;

export const ProfessionalGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(180deg, rgba(118, 113, 113, 0) 0%, #746E6E 100%);
`;

export const ProfessionalInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  position: absolute;
  z-index: 3;
  right: 0;
  padding: 0 40px;
  bottom: 60px;
  margin: 0 auto;;
`;

export const ProfessionalName = styled.h3`
  border-bottom: 1pt solid #ff0040;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 12px;
  margin-bottom: 12px;
  width: 100%;
  text-align: center;
`;

export const ProfessionalOcupation = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;
