import styled from "styled-components";

const colors = {
  redWine: '#8A0538',
  white: '#fff',
}

export const FooterContainer = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.white};
  display: flex;
  max-width: 1010px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid ${colors.redWine};
  border-bottom: 0;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 10px 0;
  width: 100%;
  border-bottom: ${props => props.borderBottom ? `1px solid ${colors.redWine}` : 'none'};
`;

export const IconContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5px;
  overflow: hidden;
`;

export const FooterText = styled.p`
  letter-spacing: 7px;
  font-weight: 500;
  color: ${colors.redWine};
  font-size: 1.5rem;
  text-transform: uppercase;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 768px) {
    font-size: 0.875rem;
    letter-spacing: 4px;
    gap: 5px;
    margin: 0 5px;
  }
`;

export const Span = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${colors.redWine};
`;

export const FooterInformation = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${colors.redWine};
  font-size: 14px;
  font-weight: 400;
  padding: 20px 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0;
  }
`;

export const FooterInformationIcon = styled(FooterInformation)`
  gap: 5px;
  flex-direction: row;
  align-items: center;

  a {
    color: ${colors.redWine};
    text-decoration: underline;
  }
`;
