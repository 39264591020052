import React, { useEffect, useState } from "react";
import CardCoursesContainer from "../CardCoursesContainer";
import { Container, ContainerInner, Title } from "./style";

export default (props) => {
  const [hasRelatedCourses, setHasRelatedCourses] = useState();

  useEffect(() => {
    if (props.content.course.related_courses.length > 0) {
      setHasRelatedCourses(true);
    } else {
      setHasRelatedCourses(false);
    }
  }, [props.content.course.related_courses.length]);

  return (
    <Container visible={hasRelatedCourses}>
      <ContainerInner>
        <Title>
          CONHEÇA <br />
          OUTROS <span>CURSOS</span>
        </Title>
        <CardCoursesContainer course={props.content.course} />
      </ContainerInner>
    </Container>
  );
};
