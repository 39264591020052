import React, { useCallback, useEffect, useRef, useState } from "react";

import _ from "lodash";

import {
  Overlay,
  Container,
  Header,
  Title,
  SecondTitle,
  FilterContainer,
  FilterContent,
  FilterTitle,
  FilterList,
  FilterItem,
  Name,
  ButtonArea,
  Button,
  Icon,
  FilterItemDisabled,
  NameDisabled,
} from "./style";

import closeIcon from "../../../assets/images/close.png";
import arrowIcon from "../../../assets/images/white-arrow.png";

export default function AdvancedFilter({
  onShowFilter,
  onFilterChange,
  content,
  currentButtonArea,
  currentButtonCity,
}) {
  const [indexAreaSelected, setIndexAreaSelected] = useState(0);
  // const [indexModalitySelected, setIndexModalitySelected] = useState(0);
  const [indexCitySelected, setIndexCitySelected] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(true);
  const buttonRef = useRef(null);

  const setShowFilter = useCallback(
    (showFilter) => {
      onShowFilter && onShowFilter(showFilter);
    },
    [onShowFilter]
  );

  const close = () => {
    const htmlTag = document.querySelector("html");
    htmlTag.classList.remove("overflow-hidden");

    setShowFilter(false);
  };

  // const modalities = [
  //   "Todas",
  //   "Presencial",
  //   "EAD",
  //   "Semipresencial",
  //   "Internacional",
  // ];

  const areas = content.knowledge_areas.areas.split(",").map(_.trim);
  const cities = content.list_cities.cities.split(",").map(_.trim);

  useEffect(() => {
    areas.filter((area, index) => {
      if (area === currentButtonArea && currentIndex) {
        setIndexAreaSelected(index);
        setCurrentIndex(false);
      }

      return false;
    });

    cities.filter((city, index) => {
      if (city === currentButtonCity && currentIndex) {
        setIndexCitySelected(index);
        setCurrentIndex(false);
      }

      return false;
    });
  });

  const selectTag = (tagIndexPosition, filterCategoryName) => {
    if (filterCategoryName === "area") {
      setIndexAreaSelected(tagIndexPosition);
      // } else if (filterCategoryName === "modality") {
      // setIndexModalitySelected(tagIndexPosition);
    } else {
      setIndexCitySelected(tagIndexPosition);
    }

    buttonRef.current.value = "";
  };

  const applyFilter = () => {
    const htmlTag = document.querySelector("html");
    htmlTag.classList.remove("overflow-hidden");

    setShowFilter(false);

    onFilterChange(
      areas[indexAreaSelected],
      // modalities[indexModalitySelected],
      cities[indexCitySelected]
    );
  };

  return (
    <Overlay>
      <Container>
        <Header>
          <Title>
            BUSCA
            <SecondTitle>AVANÇADA</SecondTitle>
          </Title>
          <img
            style={{ cursor: "pointer" }}
            src={closeIcon}
            alt="Fechar"
            onClick={(e) => close(e)}
          />
        </Header>

        <FilterContainer>
          <FilterContent>
            <FilterTitle>ÁREAS DO CONHECIMENTO</FilterTitle>
            <FilterList>
              {areas.map((area, index) => {
                if (index === indexAreaSelected) {
                  return (
                    <FilterItem
                      key={`area-${index}`}
                      ref={buttonRef}
                      onClick={() => selectTag(index, "area")}
                    >
                      <Name>{area}</Name>
                    </FilterItem>
                  );
                } else {
                  return (
                    <FilterItemDisabled
                      key={`area-${index}`}
                      ref={buttonRef}
                      onClick={() => selectTag(index, "area")}
                    >
                      <NameDisabled>{area}</NameDisabled>
                    </FilterItemDisabled>
                  );
                }
              })}
            </FilterList>
          </FilterContent>
          {/* <FilterContent>
            <FilterTitle>MODALIDADE</FilterTitle>
            <FilterList>
              {modalities.map((modality, index) => {
                if (index === indexModalitySelected) {
                  return (
                    <FilterItem
                      key={`modality-${index}`}
                      ref={buttonRef}
                      onClick={() => selectTag(index, "modality")}
                    >
                      <Name>{modality}</Name>
                    </FilterItem>
                  );
                } else {
                  return (
                    <FilterItemDisabled
                      key={`modality-${index}`}
                      ref={buttonRef}
                      onClick={() => selectTag(index, "modality")}
                    >
                      <NameDisabled>{modality}</NameDisabled>
                    </FilterItemDisabled>
                  );
                }
              })}
            </FilterList>
          </FilterContent> */}
          <FilterContent>
            <FilterTitle>CIDADE</FilterTitle>
            <FilterList>
              {cities.map((city, index) => {
                if (index === indexCitySelected) {
                  return (
                    <FilterItem
                      key={`city-${index}`}
                      ref={buttonRef}
                      onClick={() => selectTag(index, "city")}
                    >
                      <Name>{city}</Name>
                    </FilterItem>
                  );
                } else {
                  return (
                    <FilterItemDisabled
                      key={`city-${index}`}
                      ref={buttonRef}
                      onClick={() => selectTag(index, "city")}
                    >
                      <NameDisabled>{city}</NameDisabled>
                    </FilterItemDisabled>
                  );
                }
              })}
            </FilterList>
          </FilterContent>
        </FilterContainer>
        <ButtonArea>
          <Button onClick={() => applyFilter()}>
            APLICAR FILTROS
            <Icon src={arrowIcon} alt="Seta" />
          </Button>
        </ButtonArea>
      </Container>
    </Overlay>
  );
}
