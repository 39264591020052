import React from "react";
import slugify from "slugify";

import {
  AsideKnowMore,
  ButtonKnowMore,
  Container,
  Icon,
  ModalitiesContainer,
  ModalitiesInner,
  Modality,
  ModalityDisabled,
  Name,
  NameDisabled
} from "./style";

import grayArrow from "../../../assets/images/gray-arrow-right.svg";

export default ({
  isFilter,
  modalities,
  getGridState,
  setGridState,
  getPageState,
  setPageState,
}) => {
  const modalitiesCourses = [
    // "TODAS",
    "PRESENCIAL",
    "EAD",
    // "SEMIPRESENCIAL",
    "INTERNACIONAL",
    "NOVO",
    "4D",
  ];

  // const icnsModalitiesCourse = [
  //   icnPresencial,
  //   icnEad,
  //   icnInternational,
  //   icnNew,
  // ]

  const getModalitySlug = (modalityName) => {
    return slugify(modalityName).toLowerCase().trim();
  };

  const slug = window.location.hash.replace("#", "");

  const path = window.location.pathname;

  const slugIndex =
    path === "/cursos"
      ? modalitiesCourses?.findIndex(
        (modality) => slug === getModalitySlug(modality)
      )
      : modalities?.findIndex(
        (modality) => slug === getModalitySlug(modality.name)
      );

  const currentIndex = getGridState
    ? getGridState("currentIndex", slugIndex !== -1 ? slugIndex : 0)
    : getPageState("currentIndex", slugIndex !== -1 ? slugIndex : 0);

  const updateIndex = (newIndex, slug, name) => {
    if (setGridState) {
      window.history.pushState("", "", `#${slug}`);
      setGridState({ currentIndex: newIndex });
    } else {
      setPageState({ currentIndex: newIndex });
      window.history.pushState("", "", `#${slug}`);
    }
  };

  const getCurrentIndex = () => {
    if (getGridState) {
      return getGridState("currentIndex", 0);
    } else {
      return getPageState("currentIndex", 0);
    }
  };

  const filterItem = (name, index) => {
    // if (name === "NOVO") {
    //   return (
    //     <Modality
    //       key={`modality-${index}`}
    //       id={`${getModalitySlug(name)}`}
    //       onClick={() => updateIndex(index, getModalitySlug(name), name)}
    //     >
    //       <IconModality src={icnsModalitiesCourse[index]} />
    //       <Name>{name}</Name>
    //     </Modality>
    //   );
    // }

    // if (name === "Novo") {
    //   return (
    //     <ModalityDisabled
    //       key={`modality-${index}`}
    //       id={`disabled-${getModalitySlug(name)}`}
    //     >
    //       <IconModality src={icnsModalitiesCourse[index]} />
    //       <NameDisabled>{name}</NameDisabled>
    //     </ModalityDisabled>
    //   );
    // }

    return (index === getCurrentIndex() && !currentIndex) ||
      index === currentIndex ? (
      <Modality
        key={`modality-${index}`}
        id={`${getModalitySlug(name)}`}
        onClick={() => updateIndex(index, getModalitySlug(name), name)}
      >
        <Name>{name}</Name>
      </Modality>
    ) : (
      <ModalityDisabled
        key={`modality-${index}`}
        id={`${getModalitySlug(name)}`}
        onClick={() => updateIndex(index, getModalitySlug(name), name)}
      >
        <NameDisabled>{name}</NameDisabled>
      </ModalityDisabled>
    );
  };

  return (
    <Container>
      <ModalitiesContainer isFilter={isFilter}>
        {isFilter && <h3>MODALIDADES DE GRADUAÇÃO</h3>}

        <ModalitiesInner isFilter={isFilter}>
          {isFilter &&
            modalitiesCourses.map((name, index) => filterItem(name, index))}
          {!isFilter &&
            modalities.map((modality, index) => filterItem(modality.name, index))}
        </ModalitiesInner>
      </ModalitiesContainer>

      {isFilter && <AsideKnowMore>
        <h3>PÓS GRADUAÇÃO</h3>

        <ButtonKnowMore target="_blank" href="https://estudenapuc.pucpr.br/pos-graduacao">
          SAIBA MAIS
          <Icon src={grayArrow} />
        </ButtonKnowMore>

      </AsideKnowMore>}
    </Container>
  );
};
