import React, { useState } from 'react';
import { AiOutlineDesktop, AiOutlineFileText } from 'react-icons/ai';
import { RiContactsBookFill } from 'react-icons/ri';
import Container from '../../Common/Container';
import { Button } from '../Components/Button';
import Section from '../Components/Section';
import SectionTitle from '../Components/SectionTitle';
import SignUpModal from '../SignUpModal';
import { ButtonDownload, StepItem, StepItemDescription, StepItemTitle, StepsContainer } from './styles';

export default function StepsToJoin() {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  return (
    <Section bgColor="grey">
      <Container>
        <SectionTitle bgColor="grey">
          Como ingressar
        </SectionTitle>

        <StepsContainer>
          <StepItem>
            <StepItemTitle>
              <AiOutlineFileText color='#FF0141' size={30} />
              Faça sua inscrição
            </StepItemTitle>

            <StepItemDescription>
              Não perca tempo, inscreva-se para a próxima turma e mude já seu futuro
            </StepItemDescription>

            <Button
              onClick={handleOpenModal}
              primary
              style={{ width: '100%', justifyContent: 'center' }}
            >
              Fazer inscrição
            </Button>
          </StepItem>

          <StepItem>
            <StepItemTitle>
              <RiContactsBookFill color='#FF0141' size={30} />
              Formas de ingresso
            </StepItemTitle>

            <StepItemDescription>
              <p>Vestibular online</p>
              <p>Enem</p>
              <p>Transferência</p>
              <p>2ª graduação</p>
              <p>2ª graduação para ex-alunos PUCPR</p>
            </StepItemDescription>
          </StepItem>

          <StepItem>
            <StepItemTitle>
              <AiOutlineDesktop color='#FF0141' size={30} />
              Realize sua matrícula
            </StepItemTitle>

            <StepItemDescription>
              <p>Sua matricula será efetivada após a aprovação na modalidade de ingresso escolhida.</p>
              <p> Baixe o edital do vestibular e saiba mais</p>
            </StepItemDescription>

            <ButtonDownload
              href="https://20760896.fs1.hubspotusercontent-na1.net/hubfs/20760896/Re%20297-2022%20Consun_PS%20EaD_1osem2023.docx%20(1).pdf"
              target="_blank"
            >Baixar edital do vestibular</ButtonDownload>
          </StepItem>
        </StepsContainer>

        {openModal && (
          <SignUpModal isOpen={openModal} onRefuse={handleCloseModal} />
        )}
      </Container>

    </Section>
  );
}
