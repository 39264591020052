import React from "react";
import {
  TopicsItemContainer,
  TopicsItem,
  DashedLine,
  Icon,
  Link,
} from "./style";
import arrow from "../../../assets/images/big-white-arrow.png";
import slugify from "slugify";

const getTopicSlug = (topicName) => {
  return slugify(topicName).toLowerCase().trim();
};

export default ({ topics, setGridState, getGridState }) => {
  const updateIndex = (newIndex, topicName, slug) => {
    setTimeout(() => {
      const element = document.getElementById(`t-${getTopicSlug(topicName)}`);
      element.scrollIntoView({ behavior: "smooth" });
    }, 300);

    window.history.pushState("", "", `/faq/#t-${slug.replace(".", "-")}`);
    setGridState({ currentIndex: newIndex });
  };

  const slug = window.location.hash.replace("#t-", "");
  const slugIndex = topics.findIndex(
    (topic) => slug === getTopicSlug(topic.replace(".", "-"))
  );
  const currentIndex = getGridState(
    "currentIndex",
    slugIndex !== -1 ? slugIndex : 0
  );

  return (
    <TopicsItemContainer className="TopicsItemContainer">
      {topics.map((topicName, index) => (
        <TopicsItem key={`topic-${index}`}>
          <Link
            id={`${getTopicSlug(topicName)}`}
            className={index === currentIndex ? "is-active" : ""}
            onClick={() =>
              updateIndex(index, topicName, getTopicSlug(topicName))
            }
          >
            <Icon src={arrow} alt="Seta apontada para a direita" />
            {topicName}
          </Link>
          <DashedLine />
        </TopicsItem>
      ))}
    </TopicsItemContainer>
  );
};
