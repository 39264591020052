import styled from "styled-components";

export const TopicsItemContainer = styled.ul`
  display: flex;
  flex-direction: column;
`

export const TopicsItem = styled.li`
  list-style: none;
`

export const Link = styled.a`
  width: 23.438rem;
  max-width: 300px;
  color: #fff;
  font-size: 1.125rem;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 400;
  display: flex;
  align-items: center;
  padding-block: .5rem;

  &.is-active {
    font-weight: 800;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.03em;
  }

  &:hover {
    opacity: .8
  }
`

export const Icon = styled.img`
  padding-right: 16px;
`

export const DashedLine = styled.hr`
  border: 1px dashed #fff9; 
  width: 23.438rem;
  max-width: 300px;
  margin: 20px 0;
`;