import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import colors from "../../../../../styles/colors";

export const CardWrapper = styled.div`
  min-width: 302px;
  height: 357px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${colors.lightpurple};
  color: ${colors.white};
  overflow: hidden;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;

  &:hover {
  background-color: ${colors.red};
  }


  @media screen and (min-width: 1024px) {
    max-width: 30%;
  }
`;

export const CardTitle = styled.h4`
  color: ${colors.white};
  text-transform: uppercase;
  font-weight: 700;

  font-size: 16px;
  line-height: 18px;
`;

export const CardAction = styled.div`
  border-top: 1px solid ${colors.white};
  border-bottom: 1px solid ${colors.white};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  padding: 6px 0;
  margin-top: 16px;
`;

export const CardActionText = styled.p`
  color: ${colors.white};
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
`;

export const CardActionIcon = styled.div`
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: none;
`;

export const CardContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 220px;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CardImageWrapper = styled.div`
  width: 302px;
  height: 136px;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
`;

export const CardLink = styled(Link)`
  padding-bottom: 16px;
`;