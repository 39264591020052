import React from "react";
import { BsArrowRight } from 'react-icons/bs';

import SbEditable from "storyblok-react";
import { render } from "storyblok-rich-text-react-renderer";
import Container from "../../Common/Container";
import TitleSection from "../components/TitleSection";
import { Anchor, Background, CardBody, CardFooter, CardHeader, CardIcon, CardItem, CardLink, CardLinkWrapper, CardListLinks, CardTextWrapper, CardTitle, ContentWrapper } from "./styles";

export default function HowToJoin(props) {
  return (
    <SbEditable content={props.content}>
      <Background>
        <Anchor id="how-to-join" />
        <Container>
          <TitleSection>
            COMECE AGORA SUA GRADUAÇÃO 4D NA PUCPR
          </TitleSection>

          <ContentWrapper>
            {props.content.cards.map((card) => (
              <CardItem key={card._uid}>
                <div>
                  <CardHeader>
                    <CardIcon>
                      <img src={card.icon} alt="" />
                    </CardIcon>
                    <CardTitle>{card.title}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CardListLinks>
                      {card.links.map((link) => (
                        <CardLinkWrapper
                          key={link._uid}
                          href={link.link}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <p>{link.text}</p>
                          <BsArrowRight />
                        </CardLinkWrapper>
                      ))}
                    </CardListLinks>

                    <CardTextWrapper>
                      {render(card.text)}
                    </CardTextWrapper>
                  </CardBody>
                </div>
                {card.button_text && (
                  <CardFooter>
                    <CardLink
                      href={card.button_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {card.button_text}
                    </CardLink>
                  </CardFooter>
                )}
              </CardItem>
            ))}
          </ContentWrapper>
        </Container>
      </Background>
    </SbEditable>
  )
}