import React from "react"
import Container from "../../Common/Container"
import Section from "../Components/Section"
import SectionDescription from "../Components/SectionDescription"
import SectionTitle from "../Components/SectionTitle"
import { Video, VideoWrapper } from "./styles"

export default function About() {
  return (
    <Section
      bgColor="grey"
    >
      <Container>
        <SectionTitle bgColor="grey">
          Todos criando juntos
        </SectionTitle>
        <SectionDescription>
          O curso funciona como um guia para aprender a influenciar as pessoas na internet de forma estratégica. Uma graduação 4D, onde você aprende com influenciadores, profissionais de referência e professores PUCPR a usar a web para se tornar uma voz inspiradora e relevante no mercado da influência digital.
        </SectionDescription>
        <VideoWrapper>
          <Video>
            <iframe
              title="PUCPR | Graduação 4D em Economia da Influência Digital"
              src="https://www.youtube.com/embed/l7B_fRiQeTM?feature=oembed"
              width="100%"
              height="100%"
              allowFullScreen
            />
          </Video>
        </VideoWrapper>
      </Container>
    </Section>
  )
}
