import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  background: #f4f4f4;
  padding-left: 10px;
  display: ${(props) => (props.visible ? "flex" : "none")};
  justify-content: space-between;
  overflow-x: hidden;

  @media (min-width: 1024px) {
    padding: 0 0 0 0;
    height: 420px;
    overflow: hidden;
    justify-content: center;
  }
`;

export const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 13px;
  max-width: 100%;
  width: 100%;

  a {
    padding: 0 16px !important;
    height: 183px !important;
  }

  @media (min-width: 1024px) {
    align-items: flex-start;
    max-width: 1080px;
  }

  @media (min-width: 1440px) {
    max-width: 1260px;
  }
`;

export const Title = styled.h2`
  font-size: 34px;
  font-weight: 700;
  margin: 0 0 25px;

  span {
    font-weight: 400;
  }

  @media (min-width: 1024px) {
    font-size: 42px;
    line-height: 48px;
  }
`;

export const ImgWrapper = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    position: absolute;
    right: 20px;
    z-index: 9;
    bottom: 750px;
    width: 512px;
    height: 516px;
    overflow: hidden;
  }
  @media (max-width: 1300px) {
      width: 480px;
      height: 480px;
  }
`;

export const ImgBox = styled.div`
  @media (min-width: 1024px) {
    background-image: url(${({ source }) => source});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
`;
