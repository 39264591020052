import React, { useRef } from "react";
import SbEditable from "storyblok-react";
import { render } from "storyblok-rich-text-react-renderer";
import backgroundHeroMobile from "../../../assets/images/hero-1-mobile.png";
import backgroundHero from "../../../assets/images/hero-1.png";
import arrow from "../../../assets/images/white-arrow.png";
import {
  Button,
  FakeAnchor,
  FeaturedTitle,
  HeroContainer,
  Icon,
  InfoContainer,
  Subtitle,
  Title,
} from "./style";

const Hero = (props) => {
  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();
  // run this function from an event handler or an effect to execute scroll

  return (
    <SbEditable content={props.content}>
      <HeroContainer
        className="hero"
        src={backgroundHero}
        srcMobile={backgroundHeroMobile}
      >
        <InfoContainer>
          <Title>{render(props.content.Titulo_1)}</Title>
          <FeaturedTitle>{render(props.content.Titulo_2)}</FeaturedTitle>
          <Subtitle>{render(props.content.Titulo_3)}</Subtitle>

          {props.content.Link.url.includes("#") ? (
            <Button onClick={() => executeScroll()} rel="noopener noreferrer">
              {props.content.Button_Text}
              <Icon src={arrow} alt="Seta apontada para a direita" />
            </Button>
          ) : (
            <Button
              href={props.content.Link.url}
              onClick={() =>
                console.log("Dispatching event to Google Analytics") ||
                window.ga("send", {
                  hitType: "event",
                  eventCategory: "Header site",
                  eventAction: "Clique",
                })
              }
              rel="noopener noreferrer"
            >
              {props.content.Button_Text}
              <Icon src={arrow} alt="Seta apontada para a direita" />
            </Button>
          )}
          <FakeAnchor id="fake-anchor" ref={myRef} />
        </InfoContainer>
      </HeroContainer>
    </SbEditable>
  );
};

export default Hero;
