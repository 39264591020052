import _ from "lodash";
import React, { useEffect, useState } from "react";
import slugify from "slugify";
import { render } from "storyblok-rich-text-react-renderer";
import arrow from "../../../assets/images/white-arrow.png";
import Modalities from "../Modalities";
import SubscribeMobile from "../SubscribeMobile/Subscribe";
import VideoContainer from "../VideoContainer";
import VideoMobile from "../VideoContainerMobile";
import {
  Arrow,
  Button,
  ContentContainer,
  CourseGuideDescription,
  CourseGuideWrapper,
  Description,
  Link,
  Select,
  SelectArea,
  SelectItem,
  Title,
} from "./style";

const strimContent = (content) => {
  return render(content);
};

export default (props) => {
  const [selectValue, setSelectValue] = useState([]);
  let isDisabled = true;

  if (props.content.course.redirect && props.content.course.redirect_url) {
    window.location.href = props.content.course.redirect_url;
  }

  if (props.content.course.sidebar_hidden) {
    return null;
  }

  const modalities = props.content.course.modalities;
  const currentIndexModality = props.getGridState("currentIndex", 0);
  let campus_items = props.content.course.campus_items;

  if (modalities[currentIndexModality].campus_items?.length > 0) {
    campus_items = modalities[currentIndexModality].campus_items;
  }

  useEffect(() => {
    if (window.location.hash) {
      window.scrollTo(0, 0);
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 1);
    }
  }, []);

  const getModalitySlug = (modalityName) => {
    return slugify(modalityName).toLowerCase().trim();
  };

  const slug = window.location.hash.replace("#", "");

  const slugIndex = modalities.findIndex(
    (modality) => slug === getModalitySlug(modality.name)
  );

  const currentIndex = props.getGridState(
    "currentIndex",
    slugIndex !== -1 ? slugIndex : 0
  );

  let courseContent = props.content.course.modalities[currentIndex].conteudo;

  if (!courseContent) {
    courseContent = props.content.course.content;
  } else if (courseContent.content.length === 1) {
    if (courseContent.content[0].type === "paragraph") {
      if (_.keys(courseContent.content[0]).length === 1) {
        courseContent = props.content.course.content;
      }
    }
  } else if (courseContent.content.length === 0) {
    courseContent = props.content.course.content;
  }

  if (
    (selectValue.length > 0 && selectValue !== "Selecione um campus") ||
    modalities[currentIndexModality].campus_items?.length === 1
  ) {
    isDisabled = false;
  }

  const handleNextStep = (event) => {
    event.preventDefault();
    window.open(event.target.campus.value, "_blank");
  };

  const isModality4D = modalities.some((modality) => modality.name === "4D");

  return (
    <ContentContainer>
      <SubscribeMobile
        methods={props.content.course.metodos || []}
        currentIndexModality={currentIndexModality}
        modalities={props.content.course.modalities}
      />
      <Modalities
        setGridState={props.setGridState}
        getGridState={props.getGridState}
        modalities={props.content.course.modalities}
        isFilter={false}
      />
      <Title>Sobre o curso</Title>
      <Description className="description">
        {strimContent(courseContent)}
      </Description>

      {!isModality4D && (
        <SelectArea onSubmit={handleNextStep}>
          <Select
            name="campus"
            onChange={(e) => setSelectValue(e.target.value)}
            style={{
              color:
                selectValue.length > 0 || campus_items.length === 1
                  ? "black"
                  : "#0207",
            }}
          >
            {campus_items.length > 1 || !campus_items[0] ? (
              <SelectItem> Selecione um campus </SelectItem>
            ) : (
              <SelectItem value={campus_items[0].link.url}>
                {campus_items[0].campus}
              </SelectItem>
            )}
            {campus_items.length > 1
              ? campus_items.map((item, index) => {
                  return (
                    <SelectItem key={`campus-${index}`} value={item.link.url}>
                      {item.campus}
                    </SelectItem>
                  );
                })
              : null}
          </Select>

          <Button
            type="submit"
            disabled={isDisabled}
            onClick={() =>
              console.log("Dispatching event to Google Analytics") ||
              window.ga("send", {
                hitType: "event",
                eventCategory: "Campus",
                eventAction: "Clique",
              })
            }
          >
            SAIBA MAIS
            <Arrow src={arrow} />
          </Button>
        </SelectArea>
      )}

      {isModality4D && (
        <CourseGuideWrapper>
          <CourseGuideDescription>Saiba mais em:</CourseGuideDescription>
          <Link
            rel="noopener noreferrer"
            target="_blank"
            href={props.content.course.modalities[0].link}
          >
            Guia do curso
          </Link>
        </CourseGuideWrapper>
      )}

      <VideoContainer
        size="large"
        url={props.content.course.modalities[0].video_url.url}
        alt_text={props.content.course.thumbnail.alt}
        isModality4d={isModality4D}
      />

      <VideoMobile
        url={props.content.course.modalities[0].video_url.url}
        alt_text={props.content.course.thumbnail.alt}
        isModality4d={isModality4D}
      />
    </ContentContainer>
  );
};
