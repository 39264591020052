import { BsPlayCircle } from "react-icons/bs";
import styled from "styled-components";
import Colors from "../../../styles/colors";

export const VideoContainer = styled.div`
  width: 100%;
  background: ${Colors.persian_indigo};
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    height: ${({ size }) => (size === "large" ? "100%" : "25rem")};
    min-height: 25rem;
    max-height: 675px;
    justify-content: flex-start;
    align-items: ${({ size }) => (size === "large" ? "center" : "flex-start")};
    padding: ${({ size }) => (size === "large" ? "0" : "80px 0 0 32px")};

    width: 100%;
    max-width: 780px;
  }
`;

export const VideoInner = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    width: 100%;
    padding: ${({ size }) => (size === "large" ? "20px 0" : "0")};
    flex-direction: ${({ size }) => (size === "large" ? "column" : "row")};
    justify-content: ${({ size }) =>
    size === "large" ? "center" : "flex-start"};
  }
`;

export const Img = styled.img`
  @media (min-width: 1024px) {
    margin-right: ${({ size }) => (size === "large" ? "0" : "16px")};
    margin-bottom: ${({ size }) => (size === "large" ? "1rem" : "0")};
    max-width: ${({ size }) => (size === "large" ? "610px" : "")};
    height: ${({ size }) => (size === "large" ? "370px" : "")};
    width: 90%;
  }
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;
  flex-direction: ${({ isModality4d }) => (isModality4d ? "column" : "column-reverse")};
  color: white;
  font-size: ${({ size }) => (size === "large" ? "32" : "16")}px;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
`;

export const Icon = styled(BsPlayCircle)`
  margin-right: ${({ size }) => (size === "large" ? "15" : "10")}px;
  width: ${({ size }) => (size === "large" ? "48" : "32")}px;
  font-size: ${({ size }) => (size === "large" ? "" : "32")}px;
`;
