import styled from "styled-components";
import colors from "../../../styles/colors";

export const Background = styled.section`
  background-color: ${colors.lightpurple};
  padding: 59px 10px;

  @media screen and (min-width: 1024px) {
    padding: 80px 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  @media screen and (min-width: 1024px) {
    max-width: 980px;
  }
  
  @media (min-width: 1440px) {
    flex-direction: row;
    justify-content: space-between;
    max-width: 1260px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  @media screen and (min-width: 1440px) {
    max-width: 508px;
    justify-content: center;
  }
`;

export const Title = styled.h4`
  line-height: 27px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 3%;
  color: ${colors.white};
  
  @media screen and (min-width: 1440px) {
    font-size: 46px;
    line-height: 60px;
    text-align: left;
  }
`;

export const SubTitle = styled.p`
  line-height: 20px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 3%;
  color: ${colors.white};
  text-align: center;

  @media screen and (min-width: 1440px) {
    line-height: 26px;
    font-size: 20px;
    text-align: left;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  @media screen and (min-width: 1440px) {
    max-width: 592px;
  }
`;

export const Text = styled.p`
  border-left: 6px solid ${colors.red};
  padding-left: 8px;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  font-weight: 400;
  word-wrap: break-word;
  
  @media screen and (min-width: 1024px) {
    font-size: 24px;
    padding-left: 24px;
    line-height: 31px;
  }
`;
