import styled from "styled-components";

export const CarrouselArrow = styled.button`
  position: absolute;
  z-index: 2;
  top: 50%;
  width: 77px;
  height: 77px;
  background-color: transparent;
  border: none;

  align-items: center;
  justify-content: center;
  transform: ${({ direction }) => direction === "left" ? "translateY(-50%) rotate(180deg)" : "translateY(-50%)"};

  @media screen and (max-width: 1023px) {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    display: ${({ disabled }) => disabled ? "none" : "flex"};
    right: ${({ direction }) => direction === "right" && "0"};
    left: ${({ direction }) => direction === "left" && "0"};
  }
`