import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { CloseButton, ModalContainer, ModalContent, ModalDescription, ModalHeader, ModalSubtitle, ModalTitle } from './styles'

export default function Modal({ children, isOpen, onClose, title, subtitle, obs }) {

  return (
    <ModalContainer isOpen={isOpen}>
      <ModalContent>
        <CloseButton onClick={onClose}>
          <AiOutlineCloseCircle />
        </CloseButton>

        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <ModalSubtitle>{subtitle}</ModalSubtitle>
        </ModalHeader>

        <ModalDescription>{children}</ModalDescription>
        {obs && <em>*{obs}</em>}
      </ModalContent>
    </ModalContainer>
  )
}