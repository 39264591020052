import React from "react";
import Container from "../../Common/Container";
import CarrouselList from "../Carrousel";
import Section from "../Components/Section";
import SectionDescription from "../Components/SectionDescription";
import SectionTitle from "../Components/SectionTitle";
import { teachers } from "./teachers";

export default function Teachers() {

  return (
    <Section bgColor="white">
      <Container>
        <SectionTitle bgColor="white">
          Professores
        </SectionTitle>
        <SectionDescription>
          Masterclass e conteúdos atuais produzidos por profissionais renomados
        </SectionDescription>
        <CarrouselList items={teachers} buttonText="Conheça os professores" />
      </Container>
    </Section>
  );
}
