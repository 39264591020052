import React, { useEffect, useState } from 'react';
import { ImSpinner2 } from 'react-icons/im';
import { setNameMask, setPhoneMask } from '../../../../helpers/formFieldMask';
import './styles.css';

export default function Form({
  region,
  portalId,
  formId,
  target,
  onFormSubmit,
  onFormSubmitted,
  leadForm,
  inlineMessage,
}) {
  const [loadedForm, setLoadedForm] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region,
          portalId,
          formId,
          target,
          onFormSubmit,
          inlineMessage,
          onFormSubmitted,
          onFormReady: function () {
            setLoadedForm(true);
          }
        });
      }
    });
  }, [region, portalId, formId, target, onFormSubmit, onFormSubmitted, inlineMessage]);

  if (loadedForm) {
    const phoneInput = document.querySelector('.hs-form input[name="phone"]');
    setPhoneMask(phoneInput);

    const nameInputs = document.querySelectorAll('.hs-form input[name="firstname"], .hs-form input[name="lastname"]');
    setNameMask(nameInputs);
  }

  return (
    <div>
      {!loadedForm && <div className="form-loading">
        <ImSpinner2 className='loading-icon' size={40} />
      </div>}
      <div id="hubspotForm" className={leadForm ? 'lead-container' : ''}></div>
    </div>
  )
};
