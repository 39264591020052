import Colors from "../../../styles/colors";
import styled from "styled-components";

export const EnrollContainer = styled.div`
  width: 100%;
  height: auto;
  background: ${Colors.lightpurple};
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`
export const EnrollInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media(min-width: 1024px) {
    max-width: 847px;
    flex-direction: row;
  }
`

export const Text = styled.h2`
  font-size: 24px;
  line-height: 27px;
  font-weight: 400;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;

  @media(min-width: 1024px) {
    align-items: flex-start;
    font-size: 40px;
    line-height: 45px;
  }
`

export const FeaturedText = styled.span`
  font-size: 32px;
  line-height: 36px;
  font-weight: 900;

  @media(min-width: 1024px) {
    font-size: 50px;
    line-height: 56px;
  }
`

export const Button = styled.a`
  width: 300px;
  height: 60px;
  background: white;
  color: ${Colors.lightpurple};
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s;

  img {
    transition: all .5s;
  }

  &:hover {
    background: ${Colors.grey};
  }

  @media(min-width: 1024px) {
    width: 380px;
  }
`

export const Img = styled.img`
  margin-left: 10px;
`