import styled from "styled-components";

export const CarrouselContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

export const CarrouselItems = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  margin: 0 auto;

  max-width: 950px;

  @media screen and (min-width: 768px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const CarrouselItem = styled.a`
  border: none;
  position: relative;
  min-width: 280px;
  height: 380px;
  max-width: 33%;
  border-radius: 4px;
  margin: 16px;
  color: #fff;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 180px 15px 30px 15px;
  box-shadow: 0 5px 10px #0002;
  background: #FDF2F4;
  overflow: hidden;

`;

export const CarrouselImage = styled.img`
  float: unset;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  animation: fade-in 400ms;
  max-width: 100%;
`;

export const CarrouselBanner = styled.span`
  padding: unset;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(0,rgba(118,113,113,1) 0%,rgba(118,113,113,1) 34%,rgba(11,11,17,0.2) 100%);
  background-size: 100% 133%;
  background-position: center top;
  border-radius: unset;
  transition: ease-in-out 400ms;

  ${CarrouselItem}:hover & {
    background-position: center bottom;
  }
`;

export const CarrouselInfo = styled.div`
  position: relative;
  width: 100%;
  bottom: 62px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  ${CarrouselItem}:hover & {
    bottom: auto;
  }
`;

export const CarrouselTitle = styled.h3`
  border-bottom: 1pt solid #ff0040;
  font-weight: 600;
  font-size: 1.2rem;
  padding-bottom: 12px;
  margin-bottom: 12px;
  width: 100%;
  text-align: center;
`;

export const CarrouselDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;

export const CarrouselButton = styled.button`
  display: none;
  background-color: #ff0141;
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 600;
  width: 250px;
  bottom: auto;
  position: relative;
  ${CarrouselItem}:hover & {
    display: block;
  }
`;

export const CarrouselArrow = styled.button`
  background: #212121;
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 0;
  opacity: 0.5;
  transition: opacity 100ms;
  cursor: pointer;
  z-index: 2;

  &:hover {
    opacity: 1;
  }

  left: ${({ directon }) => (directon === "left" ? "6%" : "unset")};
  right: ${({ directon }) => (directon === "right" ? "6%" : "unset")};

  @media screen and (max-width: 768px) {
    display: none;
  }

  display: ${({ disabled }) => (disabled ? "none" : "flex")};
`
