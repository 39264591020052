import React from "react";

import { Hr, Title, TitleWrapper } from "./styles";

export default function TitleSection({ children }) {
  return (
    <TitleWrapper>
      <Hr />
      <Title>{children}</Title>
    </TitleWrapper>
  )
}