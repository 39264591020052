import styled from "styled-components";
import colors from "../../../styles/colors";

export const HeroContainer = styled.section`
  display: flex;
  justify-content: center;
  padding: 30px 0;
  width: 100%;
  height: 47rem;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ srcMobile }) => srcMobile});
  background-position: center 40%;
  align-items: flex-end;

  @media (min-width: 600px) {
    align-items: center;
    background-image: url(${({ src }) => src});
    background-position: center 50%;
  }

  @media (min-width: 1024px) {
    background-position: center center;
    height: 80vh;
    min-height: 580px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 1600px) {
    background-position: center bottom;
  }
`;

export const Background = styled.img`
  width: 80%;
  margin-bottom: 30px;

  @media (min-width: 1024px) {
    width: 40%;
    margin-bottom: 0;
    transform: scale(1.2);
  }

  @media (min-width: 1440px) {
    transform: scale(1.5);
  }

  @media (min-width: 1620px) {
    transform: scale(1.3);
  }

  @media (min-width: 1920px) {
    transform: scale(1.1);
    margin-top: -2rem;
  }
`;

export const InfoContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;

  @media (min-width: 1024px) {
    max-width: 980px;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: 1440px) {
    max-width: 1260px;
  }
`;

const Headings = styled.div`
  margin-bottom: 16px;

  h1 {
    font-size: 80px;
    line-height: 90px;
  }

  h2 {
    font-size: 65px;
    line-height: 70px;
  }

  h3 {
    font-size: 50px;
    line-height: 55px;
  }

  h4 {
    font-size: 35px;
    line-height: 40px;
  }

  h1 span span,
  h2 span span,
  h3 span span,
  h4 span span {
    padding: 8px;
  }

  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 32px;
      line-height: 40px;
    }

    h2,
    h3,
    h4 {
      font-size: 24px;
      line-height: 30px;
    }

    h1,
    h2,
    h3,
    h4 {
      text-align: center;
    }
  }
`;

export const Title = styled(Headings)``;

export const FeaturedTitle = styled(Headings)`
  h1,
  h2,
  h3,
  h4 {
    font-weight: 900;
  }
`;

export const Subtitle = styled(Headings)`
  h1,
  h2,
  h3,
  h4 {
    font-weight: 700;
  }
`;

export const FeaturedSubtitle = styled(Headings)`
  font-weight: 800;
`;

export const Button = styled.a`
  width: 300px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  background-color: ${colors.red};
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    letter-spacing: 0.7px;
  }

  @media (min-width: 1024px) {
    margin-top: 48px;
  }
`;

export const Icon = styled.img`
  margin-left: 10px;
  transition: all 0.7s;
`;

export const FakeAnchor = styled.span`
  visibility: hidden;
  position: absolute;
  bottom: 0;
`;

// export const SliderItem = styled.div`
//   background-image: url(${({ srcMobile }) => srcMobile});
//   width: 100%;
//   background-position: center 60%;
//   background-size: cover;
//   background-repeat: no-repeat;
//   display: flex !important;
//   padding: 30px 0;
//   height: 80vh;
//   justify-content: center;
//   align-items: flex-end;

//   @media (min-width: 1024px) {
//     background-image: url(${({ src }) => src});
//     background-position: center center;
//     min-height: 580px;
//     flex-direction: row;
//     align-items: center;
//   }
// `;

// export const StyledDots = styled.div`
//   display: none;

//   div {
//     padding: 10px;
//     background-color: ${colors.white};
//   }
//   .slick-active {
//     width: 40px;
//   }

//   .slick-active > div {
//     background-color: ${colors.red};
//     width: 40px;
//   }

//   @media (min-width: 1024px) {
//     position: absolute;
//     transform: translateX(-50%);
//     left: 50%;
//     display: block;
//     bottom: 150px;
//   }
//   `;

// export const StyledPage = styled.div`
//   margin: 0 !important;
//   height: 20px;
//   width: 20px;
//   border-radius: 30px;
//   background-color: ${colors.white};
// `;
