import React, { Fragment } from "react";

import AguilarImg from '../../../assets/images/professores/aguilar-selhorst.png';
import AndreiaImg from '../../../assets/images/professores/andra-serrato.png';
import CesarImg from '../../../assets/images/professores/cesar-leandro.png';
import ClaudioImg from '../../../assets/images/professores/claudio-thiele.png';
import DianaImg from '../../../assets/images/professores/daiana-decock.png';
import DanielImg from '../../../assets/images/professores/daniel-pala-abeche.png';
import FrancieleImg from '../../../assets/images/professores/franciele-mognon.png';
import GustavoImg from '../../../assets/images/professores/gustavo-ravaglio.png';
import LeandroImg from '../../../assets/images/professores/leandro-catapam.png';
import LucianeImg from '../../../assets/images/professores/luciane-hilu.png';
import MuriloImg from '../../../assets/images/professores/murilo-karasinski.png';

export const teachers = [
  {
    name: 'Claudio Thiele Machado da Fonseca',
    ocupation: 'Graduado em Design e Mestre em Gestão de Cooperativas',
    image: ClaudioImg,
    about: (
      <Fragment>
        <p>
          Designer formado pela PUCPR e CEFET-PR, com Pós Graduação em Design de Embalagens pela UTFPR e Mestrando em Gestão de Cooperativas (linha de pesquisa: Marketing com temática em Percepção de Marca) pela Escola de Negócios da PUCPR. Atua na área desde 1995, com foco em Branding e Design Estratégico, tendo realizado trabalhos para grandes empresas como Renault, SEBRAE, GVT, New Holland, Brookfield, Paganini (Itália), Recipe 33 (EUA), Electrolux entre outras. Atualmente é Coordenador de Criação na Editorial Design, empresa com mais de 20 anos no mercado, e professor nos cursos de graduação e pós graduação em Design da PUCPR, nas disciplinas de Projeto de Design, Branding, Identidade Visual, Semiótica e Design Thinking.
        </p>
      </Fragment>
    ),
    obs: null,
  },
  {
    name: 'Francieli Mognon',
    ocupation: 'Graduada Relações Públicas, Mestre em Tecnologia em Saúde e Doutora em Ciências da Saúde',
    image: FrancieleImg,
    about: (
      <Fragment>
        <p>
          Formada em Relações Públicas, Mestre em Tecnologia em Saúde e Doutora em Ciências da Saúde pela PUCPR. Professora Titular do Curso de Graduação da Escola de Belas Artes da PUCPR. Coordenou o Curso de Relações Públicas da PUCPR (2013 - 2020). Atuou como conselheira do Conselho Universitário e da Câmara de Graduação e Cursos Sequenciais da PUCPR e do Conselho Federal dos Profissionais de Relações Públicas (2012-2015). Atualmente é Coordenadora dos Cursos de Teatro, Artes Visuais, Produção Musical e Licenciatura em Música da PUCPR. Tem experiência na área de Comunicação em Saúde; administrou projetos de pesquisa no Instituto de Ciências Biológicas e da Saúde (2001-2008) e gestora de projetos no Centro de Tecnologia Celular (2009-2012), ambos na PUCPR. Atua também nas áreas de comunicação interna, organizacional e gestão de eventos. Acredita que a comunicação deve ser humanizada e as relações precisam ser construídas de maneira transparente e ética, o que faz se apaixonar cada vez mais pelo universo das Relações Públicas.
        </p>
      </Fragment>
    ),
    obs: null,
  },
  {
    name: 'Luciane Hilu',
    ocupation: 'Graduada em Design e Mestre em Música',
    image: LucianeImg,
    about: (
      <Fragment>
        <p>
          Graduada em Design pela UFPR (1990) e em Música pela EMBAP (1993), possui mestrado em Música pela UFRJ (1998) com dissertação intitulada Uma análise Semiótica da Música para o cinema; e especialização em Comunicação e semiótica: teoria e crítica da sociedade de informação pela PUCPR (2006) com monografia intitulada Utilização da sinestesia e da tradução intersemiótica no desenvolvimento de práticas criativas aplicadas ao design. Tem doutorado em Educação pela PUCPR (2016), na área da educação usando TICs e Metodologia de Projeto (Design Thinking). Atuou como coordenadora dos cursos de Licencitura em Música (2011 a 2013), Design Gráfico da PUCPR de 2013 a 2017, Design digital (2016). Atua professora do curso superior de Design da PUCPR nas áreas de design digital, web design, projeto, metodologia de design. Atuou durante 15 anos como coordenadora de design e designer de interfaces digitais na Diretoria de Tecnologias Educacionais (DTE) da PUCPR. Foi assessora para EAD da vice-reitoria da PUCPR (2017, 2018) onde foi responsável pela construção das diretrizes gerais para Educaçãoa distância da Instituição. Neste cargo implantou os cursos de graduação online na instituição. Tem experiência na área de Desenho Industrial, com ênfase em Programação Visual, atuando principalmente nos seguintes temas: educação à distância, educação, tecnologia, design.
        </p>
      </Fragment>
    ),
    obs: null,
  },
  {
    name: 'Daniel Pala Abeche',
    ocupation: 'Graduado em Comunicação Social, Mestre em Comunicação e Semiótica e Doutor em Filosofia.',
    image: DanielImg,
    about: (
      <Fragment>
        <p>
          É doutor em Filosofia pela PUCPR, Mestre em Comunicação e Semiótica pela PUC - SP e possui graduação em Comunicação Social pela Universidade Presbiteriana Mackenzie. É Professor Assistente II em tempo integral na Escola de Belas Artes da Pontifícia Universidade Católica do Paraná (PUCPR), lecionando em cursos de graduação e de pós-graduação. É também professor em cursos de especialização na FURB. Atualmente dedica-se ao estudo da filosofia da linguagem, da estética e da cultura, tendo como foco central o pensamento de Wittgenstein, em especial as abordagens do autor em relação à cultura, às artes, à música e a aproximação entre a filosofia e a poesia. É membro pesquisador do grupo de pesquisa "Mística e Estética", da Faculdade Jesuíta de Filosofia e Teologia e membro do grupo de pesquisa "Wittgenstein", da PUCPR. É membro do conselho científico da Revista Paranaense de Filosofia, vinculada à UNESPAR. Temas de interesse: Filosofia da Linguagem; Estética e Filosoria da Arte; Ontologia e Epistemologia; Filosofia da música; Filosofia, Literatura e Poesia.
        </p>
      </Fragment>
    ),
    obs: null,
  },
  {
    name: 'Gustavo Ravagglio',
    ocupation: 'Mestre em Antropologia, especialista em Antropologia Cultural e graduado em Design de Produto.',
    image: GustavoImg,
    about: (
      <Fragment>
        <p>
          Mestre em Antropologia pela UFPR, especialista em Antropologia Cultural pela PUCPR e graduado em Design de Produto na PUCPR. É designer, ilustrador, escritor, antropólogo e professor universitário nos cursos de graduação e pós-graduação em Design na PUCPR. Gustavo possui seu próprio estúdio focado no desenvolvimento e serviços de Design. O Autor já possui 5 livros publicados até o momento e já venceu e foi finalista de diversos prêmios importantes como o Jabuti e HQ Mix.
        </p>
      </Fragment>
    ),
    obs: null,
  },
  {
    name: 'Leandro Tadeu Catapam',
    ocupation: 'Graduado em Desenho Industrial - Habilitação em Programação Visual, especialista em Teoria do Cinema...',
    image: LeandroImg,
    about: (
      <Fragment>
        <p>
          Graduado em Desenho Industrial - Habilitação em Programação Visual pela Universidade Federal do Paraná (1998), especialista em Teoria do Cinema pela Universidade Tuiuti do Paraná (2000) e mestre em Design pela Universidade Federal do Paraná (2010). Atua como designer no âmbito cultural na cidade de Curitiba e também como professor do ensino superior da Pontifícia Universidade Católica do Paraná, PUC-PR, tanto na graduação em Design quanto em especializações da área e orientações de projetos de iniciação científica da universidade. Em 2017 viabilizou a exposição GifEmotion, em que transformou colagens estáticas em gifs sequenciais animados e, dando continuidade a essa expressão, em 2018 participou da edição curitibana da Bienal Internacional de Arte Digital Subli_Me The Wrong - New Digital Art Biennale. Dentro desse contexto, desenvolve estudos em linguagem visual com foco na expressão da colagem e imagem em movimento, agregando a pesquisa de caráter discursivo em sua abordagem, por meio de aspectos históricos e filosóficos dos discursos produzidos pelo design. Em 2021, com o objetivo de aprofundar tais aspectos, ingressou no Programa de Doutorado em Design do PPGDESIGN - UFPR.
        </p>
      </Fragment>
    ),
    obs: null,
  },
  {
    name: 'Diana Chao Decock',
    ocupation: 'Graduada, Mestre e Doutora em Filosofia.',
    image: DianaImg,
    about: (
      <Fragment>
        <p>
          Doutora em Filosofia pela Universidade de São Paulo. Possui graduação e mestrado em Filosofia pela Pontifícia Universidade Católica do Paraná. É professora da Pontifícia Universidade Católica do Paraná; Integrante do GT-Schopenhauer da ANPOF e do Núcleo de Estudos Nietzschianos da UFPR; Co-fundadora e Membro da Comissão Editorial da Revista Voluntas - Estudos sobre Schopenhauer; possui interesse pela filosofia de Arthur Schopenhauer, Friedrich Nietzsche, Swami Vivekananda, pensamento indiano, yoga, ética.
        </p>
      </Fragment>
    ),
    obs: null,
  },
  {
    name: 'Cesar Leandro Ribeiro',
    ocupation: 'Graduado em Teologia, Mestre em Filosofia e Doutor em Teologia.',
    image: CesarImg,
    about: (
      <Fragment>
        <p>
          Possui Doutorado em Teologia pela Pontifícia Universidade Católica do Paraná (2018), Mestrado em Filosofia pela Pontifícia Universidade Católica do Paraná (2007), graduação em Bacharelado em Teologia pela Pontifícia Universidade Católica do Paraná (2004), graduação em Filosofia - Studium Ordem de São Basílio Magno (1996). Atualmente é professor do Curso de Bacharelado em Teologia da PUCPR e especialista da Área de Identidade e Missão do Grupo Marista.Possui Doutorado em Teologia pela Pontifícia Universidade Católica do Paraná (2018), Mestrado em Filosofia pela Pontifícia Universidade Católica do Paraná (2007), graduação em Bacharelado em Teologia pela Pontifícia Universidade Católica do Paraná (2004), graduação em Filosofia - Studium Ordem de São Basílio Magno (1996). Atualmente é professor do Curso de Bacharelado em Teologia da PUCPR e especialista da Área de Identidade e Missão do Grupo Marista.
        </p>
      </Fragment>
    ),
    obs: null,
  },
  {
    name: 'Andreia Cristina Serrato',
    ocupation: 'Graduada em Teologia e Artes Plásticas, Mestre em Teologia e Doutora em Teologia Sistemática.',
    image: AndreiaImg,
    about: (
      <Fragment>
        <p>
          Doutora na área de Teologia Sistemática pela Pontifícia Universidade Católica do Rio de Janeiro (2015). Bolsista CAPES para doutorado sanduíche na Université Catholiqué de Lyon - France. Possui mestrado em Teologia pela Faculdade Jesuíta de Filosofia e Teologia (2009). Graduação em Teologia pela Pontifícia Universidade Católica do Paraná (2005) e graduação em Artes Plásticas pela Faculdade de Artes do Paraná (1996) e curso técnico em Desenho Industrial pelo Centro Federal de Educação Tecnológica do Paraná (1990). Possui especialização em Carisma e Princípios Educativos Maristas (2015).
        </p>
      </Fragment>
    ),
    obs: null,
  },
  {
    name: 'Murilo Karasinski',
    ocupation: 'Graduado em Direito, MBA em Gestão Estratégica de Empresas, Mestrado e Doutorado em Filosofia.',
    image: MuriloImg,
    about: (
      <Fragment>
        <p>
          Possui Graduação em Direito pela Pontifícia Universidade Católica do Paraná (PUCPR), MBA em Gestão Estratégica de Empresas pelo ISAE/FGV, Especialização em Direito Tributário Empresarial pela PUCPR e Especialização em Ética também pela PUCPR. Mestrado e Doutorado em Filosofia, ambos pela PUCPR. Coordenador da Pós-Graduação em Inteligência Espiritual, Carreira e Sentido da Vida, da PUCPR Digital. Dedica-se ao estudo de temáticas relacionadas à inteligência artificial, biotecnologia, transumanismo, pós-humanismo e à cognição.
        </p>
      </Fragment>
    ),
    obs: null,
  },
  {
    name: 'Aguilar Selhorst Junior',
    ocupation: 'Graduado em Design e Mestre em Engenharia de Produção e Sistemas.',
    image: AguilarImg,
    about: (
      <Fragment>
        <p>
          Designer, mestre em Engenharia de Produção e Sistemas, professor adjunto na Escola de Belas Artes da PUCPR desde 2002. Professor em diversas pós-graduações na PUCPR / UP / Escola de Negócios Sustentare.  Sócio-Diretor da Megabox Design desde 2000, com mais de 500 produtos e serviços lançados no mercado a partir dos processos e metodologias de Design. Coordenador do curso Economia da Influência Digital.
        </p>
      </Fragment>
    ),
    obs: null,
  },
]
