import React, { useMemo, useState } from "react";

import {
  Description,
  Icon,
  InfoList,
  ItemsContent,
  ListItem,
  MoreDetails,
  NameItem,
} from "./style";

import iconduration from "../../../assets/images/icon-duration.png";
import modules from "../../../assets/images/icon-modules.png";
import iconmoney from "../../../assets/images/icon-money.png";
import iconpin from "../../../assets/images/icon-pin.png";
import icontime from "../../../assets/images/icon-time.png";
import PaymentPlans from "../PaymentPlans";

export default ({
  campus,
  modality,
  schedule,
  duration,
  monthlypayment,
  link,
  showPopUp,
  installments,
  description,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpenPopUp = () => setOpen(true);
  const handleClosePopUp = () => setOpen(false);

  const showModal = useMemo(() => open && installments?.length > 0, [open, installments]);

  return (
    <InfoList>
      <ListItem>
        <Icon src={iconpin} alt="Ícone de localização" />
        <ItemsContent>
          <NameItem>{modality === '4D' ? 'POLOS' : 'CAMPUS'}</NameItem>
          <Description>{campus}</Description>
        </ItemsContent>
      </ListItem>
      <ListItem>
        <Icon src={modules} alt="Ícone de modalidades" />
        <ItemsContent>
          <NameItem>MODALIDADES</NameItem>
          <Description>{modality === '4D' ? 'Remoto - EaD' : modality}</Description>
        </ItemsContent>
      </ListItem>
      <ListItem>
        <Icon src={icontime} alt="Ícone de relógio" />
        <ItemsContent>
          <NameItem>TURNOS</NameItem>
          <Description>{schedule}</Description>
        </ItemsContent>
      </ListItem>
      <ListItem>
        <Icon src={iconduration} alt="Ícone de cronômetro" />
        <ItemsContent>
          <NameItem>DURAÇÃO</NameItem>
          <Description>{duration}</Description>
        </ItemsContent>
      </ListItem>
      <ListItem>
        <Icon src={iconmoney} alt="Ícone de moedas" />
        <ItemsContent>
          <NameItem>CONDIÇÕES DE PAGAMENTO</NameItem>
          <Description className="styledMonthlyPayment">
            {monthlypayment}
          </Description>
          {showPopUp && (
            <>
              <NameItem>
                Planos de Pagamento:
              </NameItem>
              <MoreDetails onClick={handleOpenPopUp}>Saiba mais</MoreDetails>
            </>
          )}
        </ItemsContent>
      </ListItem>

      {showModal && (
        <PaymentPlans
          open={open}
          handleClose={handleClosePopUp}
          installments={installments}
          description={description}
        />
      )}
    </InfoList>
  );
}
