import React from "react";

import {
  ModalitiesContainer,
  Modality,
  Name,
  ModalitiesInner,
  ModalityDisabled,
  NameDisabled,
} from "./style";

export default ({ units, getPageState, setPageState }) => {
  const updateIndex = (newIndex) => {
    return setPageState({ currentIndex: newIndex });
  };

  const getCurrentIndex = () => {
    return getPageState("currentIndex", 0);
  };

  const filterItem = (name, index) =>
    index === getCurrentIndex() ? (
      <Modality key={`modality-${index}`} onClick={() => updateIndex(index)}>
        <Name>{name}</Name>
      </Modality>
    ) : (
      <ModalityDisabled
        key={`modality-${index}`}
        onClick={() => updateIndex(index)}
      >
        <NameDisabled>{name}</NameDisabled>
      </ModalityDisabled>
    );

  return (
    <ModalitiesContainer isFilter={true}>
      <h3 style={{ fontWeight: 500 }}>SELECIONE UMA UNIDADE</h3>

      <ModalitiesInner isFilter={true}>
        {units.map((name, index) => filterItem(name.toUpperCase(), index))}
      </ModalitiesInner>
    </ModalitiesContainer>
  );
};
