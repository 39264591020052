import React from "react";

import SbEditable from "storyblok-react";
import Container from "../../Common/Container";
import TitleSection from "../components/TitleSection";
import { Background, ContentWrapper, ImageWrapper, Paragraph, Subtitle } from "./styles";

export default function OneMoreThing(props) {
  return (
    <SbEditable content={props.content}>
      <Background>
        <Container>
          <TitleSection>
            {props.content.title}
          </TitleSection>

          <Subtitle>
            {props.content.subtitle}
          </Subtitle>

          <ContentWrapper>
            <ImageWrapper>
              <img src={props.content.image} alt="" width="100%" height="100%" />
            </ImageWrapper>

            <Paragraph>
              {props.content.text}
            </Paragraph>
          </ContentWrapper>
        </Container>
      </Background>
    </SbEditable>
  )
}