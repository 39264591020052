export function phoneMaskBR(object) {
  object.value = object.value.replace(/(\+55)|\D/g, "")/* Remove +55 and everything that
  is not a digit */
    .replace(/^(\d{2})(\d)/g, "+55 $1 $2")/* Add the countrycode for Brazil (+55) and
  spaces */
    .replace(/(\d)(\d{4})$/, "$1-$2")/* Insert a dash bwtween the fourth and the fifth
  digit */
    .substring(0, 17);/* Remove extra characters */
};

export function clearphoneMaskBR(object) {
  object.minLength = 14;/* Update the min length of the phone field to accept the
  entry without spaces*/
  object.value = object.value.replace(/(\+55)|\D/g, "")/* Remove +55 and everything
  that is not a digit */
    .replace(/^(\d{2})(\d)/g, "+55$1$2");/* Add the countrycode for Brazil (+55) */
};

/*  --- NAME FIELD MASK --- */
export function nameFieldMaskBR(object) {
  object.value = object.value.replace(/[^a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÊÉÈÍÏÓÔÕÖÚÇÑ\s]/g,
    '');
};

export function setPhoneMask(phoneInput) {
  phoneInput.setAttribute("minlength", "17");
  phoneInput.setAttribute("maxlength", "17");
  phoneInput.setAttribute("autocomplete", "new-password");
  phoneInput.addEventListener("keyup", function keyup() {
    phoneMaskBR(this)
  });
  phoneInput.addEventListener("focus", function focus() {
    phoneMaskBR(this)
  });
}

export function setNameMask(nameInputs) {
  nameInputs.forEach(field => {
    field.addEventListener("keyup", function key() { nameFieldMaskBR(this) });
    field.addEventListener("focus", function key() { nameFieldMaskBR(this) });
    field.addEventListener("change", function key() { nameFieldMaskBR(this) });
  });
}
