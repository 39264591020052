import React from "react";
import Slider from "react-slick";

import { Box, Button, SliderContainer, SliderItem, Title } from "./styles";

import Trilha1 from '../../../assets/images/trilhas-graduacao-4d/trilha-1.webp';
import Trilha2 from '../../../assets/images/trilhas-graduacao-4d/trilha-2.webp';
import Trilha3 from '../../../assets/images/trilhas-graduacao-4d/trilha-3.webp';
import Trilha4 from '../../../assets/images/trilhas-graduacao-4d/trilha-4.webp';
import Container from "../../Common/Container";
import Section from "../Components/Section";
import SectionDescription from "../Components/SectionDescription";
import SectionTitle from "../Components/SectionTitle";


export default function StudyTrail() {
  const settingsReactSlickSlider = {
    dots: true,
    speed: 700,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesToShow: 1,
    fade: true,
  };

  return (
    <Section bgColor="grey">
      <Container>
        <SectionTitle bgColor="grey">
          A trilha de estudos é como uma playlist: Você monta a sua
        </SectionTitle>
        <SectionDescription>
          Nessa graduação, se aprende fazendo. A partir do Projeto II, é você quem faz a trilha de disciplinas que quer cursar a cada semestre. Hands on mesmo!
        </SectionDescription>

      </Container>
      <SliderContainer>
        <Slider {...settingsReactSlickSlider}>
          <SliderItem>
            <img src={Trilha1} alt="trilha site 1" />
          </SliderItem>
          <SliderItem>
            <img src={Trilha2} alt="trilha site 2" />
          </SliderItem>
          <SliderItem>
            <img src={Trilha3} alt="trilha site 3" />
          </SliderItem>
          <SliderItem>
            <img src={Trilha4} alt="trilha site 4" />
          </SliderItem>
        </Slider>
      </SliderContainer>

      <Container>
        <Box>
          <Title>QUER ENTENDER MELHOR?</Title>
          <div style={{ marginInline: 'auto' }}>
            <Button href="https://6064046.fs1.hubspotusercontent-na1.net/hubfs/6064046/PDF%20trilha%20de%20estudos%20da%20Gradua%C3%A7%C3%A3o%204D%20PUCPR.pdf" lang="_blank">Faça o download da nossa trilha de estudos</Button>
          </div>
        </Box>
      </Container>
    </Section>
  )
};