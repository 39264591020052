import styled from "styled-components";
import colors from "../../../styles/colors";

export const Background = styled.section`
  background-color: ${colors.brightgray};
  padding: 40px 0;

  @media screen and (min-width: 1024px) {
    padding: 80px 0;
  }
`;

export const Subtitle = styled.p`
  text-align: center;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  p :first-child {
    font-weight: 500;
  }

  @media screen and (min-width: 1024px) {
    font-size: 24px;
    line-height: 27.36px;
  }
`;

export const AccordionListWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
  padding-top: 60px;
`;