import React from "react";
import Routes from "./routes";

function App() {
  // useEffect(() => {
  //   const isDevelopment = process.env.REACT_APP_IS_DEVELOPMENT === 'true';
  //   const loadScripts = () => {
  //     const consent = document.createElement("script");
  //     consent.src = "https://cdn.cookielaw.org/consent/75e4c81b-17c7-4230-ac75-9b34df98b386/OtAutoBlock.js";
  //     consent.async = true;
  //     const template = document.createElement("script");
  //     template.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
  //     template.setAttribute('data-domain-script', '75e4c81b-17c7-4230-ac75-9b34df98b386');
  //     template.async = true;

  //     document.body.appendChild(consent);
  //     document.head.appendChild(template);
  //     window.OptanonWrapper = () => { };
  //   }

  //   !isDevelopment && loadScripts();
  // }, []);

  return (
    <>
      <Routes />
    </>
  );
}

export default App;
