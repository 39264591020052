import styled from "styled-components";
import colors from "../../../styles/colors";

export const Background = styled.section`
  background-color: ${colors.brightgray};
  padding: 40px 0;
  position: relative;

  @media screen and (min-width: 1024px) {
    padding: 80px 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 60px;
  gap: 32px;
  align-items: center;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 60px;
    align-items: stretch;
  }
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
  max-width: 332px;
  width: 100%;
  min-height: 306px;
  justify-content: space-between;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CardTitle = styled.h4`
  font-size: 24px;
  line-height: 27px;
  font-weight: 500;
`;

export const CardIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: transparent;
`;

export const CardBody = styled.div`
  margin-top: 16px;

  > p {
    font-size: 20px;
    line-height: 22px;
  }
`;

export const CardListLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const CardLinkWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  &:hover {
    color: ${colors.red};
  }

  p {
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
  }

  svg {
    stroke-width: 1;
  }
`;

export const CardFooter = styled.div`
  display: flex;

`;

export const CardButton = styled.button`
  background-color: ${colors.red};
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  padding: 16px;
  color: white;
  text-align: center;
  font-weight: 700;
  border: none;
`;

export const CardLink = styled.a`
  background-color: ${colors.red};
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  padding: 16px;
  color: white;
  text-align: center;
  font-weight: 700;
`;

export const CardTextWrapper = styled.div`
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
`;

export const Anchor = styled.div`
  position: absolute;
  top: -80px;
`;