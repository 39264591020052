import React, { useEffect } from 'react';
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  CloseButton,
  ModalContainer,
  ModalDescription,
  ModalHeader,
  ModalSubtitle,
  ModalTitle,
} from "../../../../DigitalEconomy/Components/Modal/styles";
import { IframeWrapper } from "../../../Video/styles";
import { EmbedWrapper, ModalContent } from "../Carrousel/styles";

export default function Modal({ open, handleCloseModal, professional }) {

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  return (
    <ModalContainer
      isOpen={open}
    >
      <ModalContent>
        <CloseButton onClick={handleCloseModal}>
          <AiOutlineCloseCircle />
        </CloseButton>
        <EmbedWrapper>
          <IframeWrapper>
            <iframe
              src={professional.embed}
              title={professional.name}
              width="100%"
              height="100%"
            />
          </IframeWrapper>
        </EmbedWrapper>
        <ModalHeader>
          <ModalTitle>{professional.name}</ModalTitle>
          <ModalSubtitle>{professional.ocupation}</ModalSubtitle>
        </ModalHeader>
        <ModalDescription>{professional.about}</ModalDescription>
        {professional.obs && <em>*{professional.obs}</em>}
      </ModalContent>
    </ModalContainer>
  )
}