import styled, { keyframes } from "styled-components";
import { ModalContent as Content } from "../Components/Modal/styles";

const colors = {
  background: "#f2f2f2",
  darkRed: "#ff0040",
  ligthRed: '#ef6b51',
}

const errorMsg = keyframes`
  0% {
    transform: scale(0.5);
    margin-top: -5px
  }
  100% {
    transform: scale(1);
    margin-top: 0px
  }
`;

export const ModalContent = styled(Content)`
  padding: 60px 30px;
  background-color: ${colors.background};

  ul, li {
    list-style: none;
  }

  li {
    margin: 0.7rem 0;
    display: block;
  }

  ul {
    list-style: none;
    list-style-type: none;
    padding-left: 1.4rem !important;
  }

  ul.no-list.hs-error-msgs.inputs-list {
  list-style: none;
  margin: 0 0 20px;
  text-align: center;
  padding: 0;
  -webkit-animation-name: example;
  -webkit-animation-duration: 4s;
  animation-name: ${errorMsg};
  animation-duration: 0.3s;
}
`;
