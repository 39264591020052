import styled from "styled-components";

export const Button = styled.button`
  border-radius: 30px;
  border: none;
  background-color: ${props => props.primary ? "#FF0040" : props.color};
  color: white;

  display: flex;
  padding: 12px 24px;

  font-weight: 700;
  font-size: 1rem;

  transition: ease-out 200ms;

  @media screen and (max-width: 768px) {
    justify-content: center;
    width: 100%;
  }
`;

export const ButtonIcon = styled(Button)`
  background-color: #715f97;
  a {
    display: flex;
    align-items: center;
    gap: 8px
  }
`;