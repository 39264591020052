import React from "react";

import Container from "../../Common/Container";
import Section from "../Components/Section";
import SectionDescription from "../Components/SectionDescription";
import SectionTitle from "../Components/SectionTitle";

import { graduationContent } from "../accordionsText";
import ListAccordion from "../ListAccordion";


export default function GraduationContent() {
  return (
    <Section bgColor="grey">
      <Container>
        <SectionTitle bgColor="grey">
          Sua graduação 4D contém
        </SectionTitle>
        <SectionDescription>
          E algumas outras surpresas que encontrará no caminho
        </SectionDescription>
        <ListAccordion items={graduationContent} alingCenter />
      </Container>
    </Section>
  )
}