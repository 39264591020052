import styled from "styled-components";
import colors from "../../../../../styles/colors";

export const AccordionContentWrapper = styled.div`
  height: ${props => props.open ? "100%" : "0"};
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  margin: 16px 0;
  width: 100%;
  
  > p {
    text-align: center;
    font-size: 16px;
  }

  a {
    color: ${colors.red};
  }

  img {
    width: 100%;
  }

  ul {
    list-style: initial;
    text-align: center;
    padding-left: 30px;
  }
`;
