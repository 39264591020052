import React from "react";
import SbEditable from "storyblok-react";

import { IframeWrapper, VideoWrapper } from "../../4d/Video/styles";
import { Hr, TitleWrapper } from "../../4d/components/TitleSection/styles";
import Container from "../../Common/Container";
import { Content, Title } from "./styles";

export default function Multiversity(props) {
  return (
    <SbEditable content={props.content}>
      <Container>
        <Content>
          <TitleWrapper>
            <Hr />
            <Title>
              {props.content.title}
            </Title>
          </TitleWrapper>
        </Content>

        <Content>
          <VideoWrapper>
            <IframeWrapper>
              <iframe
                title={props.content.title}
                src={props.content.yt_video}
                width="100%"
                height="100%"
                allowFullScreen
              />
            </IframeWrapper>
          </VideoWrapper>
        </Content>
      </Container>
    </SbEditable>
  )
}