import styled from "styled-components";
import colors from "../../../styles/colors";

export const Background = styled.section`
  background-color: ${({ color }) => color};
  padding: 32px 0;

  @media screen and (min-width: 1024px) {
    padding: 56px 0;
  }
`;

export const Title = styled.h4`
  color: ${colors.white};
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  
  @media screen and (min-width: 1024px) {
    font-size: 48px;
    line-height: 54px;
    max-width: 850px;
    margin: 0 auto;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${colors.white};
  margin-top: 24px;
  font-weight: 400;

  @media screen and (min-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
    margin: 48px auto 0;
    max-width: 980px;
    text-align: left;
  }
`;