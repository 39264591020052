import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  0% {
    margin-top: -20px;
    opacity: 0;
  }

  100% {
   opacity: 1;
   margin-top: 0;
  }
`;

export const Card = styled.div`
  max-width: 1057px;
  display: flex;
  flex-direction: column;
  background-color: #FF0040;
  margin-top: 30px !important;
  border-radius: 25px;
  margin-inline: auto;
  padding: 60px 90px;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    padding: 50px 30px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  gap: 30px;
  padding-top: 20px;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;


    hr {
      width: 100%;
    }
  }
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px
`;

export const CardText = styled.p`
  color: #fff;
  font-size: ${({ size }) => size === "large" ? "64px" : "24px"};
  font-weight: ${({ size }) => size === "large" ? "800" : "400"};;
  line-height: ${({ size }) => size === "large" ? "73px" : "27px"};
  text-align: ${({ alignCenter }) => alignCenter && "center"};
`;

export const ButtonText = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: #fff;
  display: flex;
  gap: 8px;
  font-size: 24px;
  font-weight: 400;
  margin: 40px 0;
  line-height: 27px;
`;

export const Button = styled.button`
  background-color: #fff;
  border: none;
  padding: 16px 8px;
  border-radius: 30px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  width: 300px;
  align-self: center;
`;

export const EndIncon = styled.span`
  transform: ${({ show }) => show ? "rotate(180deg)" : "rotate(0deg)"};
  transition: 0.5s;
  height: 30px;
`;

export const KnowMoreContent = styled.div`
  animation: 0.5s ${fadeIn};
  animation-fill-mode: forwards;
  width: 100%;
  height: auto;
  display: ${({ show }) => show ? "flex" : "none"};
  justify-content: space-between;
  padding-top: 41px;
  border-top: 1px solid #fff;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }
`;


export const PaymentMethod = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

export const Installments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #fff;
  font-size: 24px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;
