import React, { Fragment, useState } from "react";
import { IoAddOutline } from 'react-icons/io5';
import { AccordionBody, AccordionDescription, Box, BoxItem, Button, ItemName, ItemsContainer } from "./styles";

const ListAccordion = ({ items, alingCenter, outlined }) => {
  const [isClicked, setIsClicked] = useState(false);


  const toggleAccordion = (index) => {
    if (isClicked === index) {
      return setIsClicked(null);
    }

    setIsClicked(index);
  };

  return (
    <Box>
      {items.map((item, index) => (
        <Fragment key={item.title.toLowerCase()}>
          <BoxItem
            outlined={outlined}
            onClick={() => toggleAccordion(index, item.title)}
            id={`${item.title}`}
            className={isClicked === index && "active"}
          >
            <ItemsContainer className={`${isClicked === index ? "active" : ""} items-container`}>
              <ItemName style={{ textAlign: alingCenter ? 'center' : 'left' }}>{item.title}</ItemName>

              <Button className={isClicked === index ? "is-active" : ""}>
                <IoAddOutline color="#FF0141" />
              </Button>
            </ItemsContainer>
            {isClicked === index ? (
              <AccordionBody>
                <AccordionDescription
                  style={{ textAlign: alingCenter ? 'center' : 'left' }}
                >
                  {item.description}
                </AccordionDescription>
              </AccordionBody>
            ) : null}
          </BoxItem>
        </Fragment>
      ))}
    </Box>
  );
};

export default ListAccordion;
