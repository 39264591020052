import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { gql } from "apollo-boost";
import { Query } from "react-apollo";

import Components from "./components";
import Course from "./components/Course";
import ScrollToTop from "./ScrollToTop";

const query = gql`
  {
    PageItems {
      items {
        id
        name
        full_slug
        path
        content {
          _uid
          component
          body
          context
        }
      }
      total
    }
  }
`;

export default function Routes() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route key="curso" path="/cursos/:slug" component={Course} />
        <Route
          key="graduacao-4d"
          path="/graduacao-4d"
          render={() =>
            (window.location.href = "https://digital4d.pucpr.br/site/")
          }
        />

        <Query query={query}>
          {(result) => {
            if (result.loading) return null;
            if (result.error) return null;

            const pages = result.data.PageItems.items.filter((item) => {
              return item.content.body && item.content.component === "page";
            });

            return pages.map((pageItem) => (
              <Route
                key={pageItem.id}
                exact
                path={pageItem.path || "/" + pageItem.full_slug}
              >
                <>
                  {Components({
                    ...pageItem.content,
                    name: pageItem.name,
                    path: pageItem.path,
                    slug: pageItem.slug,
                    full_slug: pageItem.full_slug,
                  })}
                </>
              </Route>
            ));
          }}
        </Query>
      </Switch>
    </BrowserRouter>
  );
}
