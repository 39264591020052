import React, { useState } from "react";

import SbEditable from "storyblok-react";
import { render } from "storyblok-rich-text-react-renderer";
import { AccordionContent, AccordionHeader, AccordionRoot, Background, Container, RedSquare, Title } from "./styles";

export default function AccordionList(props) {
  const [openIndex, setOpenIndex] = useState(0);
  return (
    <SbEditable content={props.content}>
      <Background>
        <Container>
          {props.content.items.map((item, index) => (
            <AccordionRoot className={`${openIndex === index ? 'open' : ''}`} key={item._uid}>
              <AccordionHeader onClick={() => setOpenIndex(index)}>
                <Title>
                  {item.number}
                  <RedSquare />
                  <p>{item.title}</p>
                </Title>
              </AccordionHeader>

              <AccordionContent>
                {item.video && (
                  <iframe title="Video" width="550px" height="319px" src={item.video} />
                )}
                {render(item.body)}
              </AccordionContent>
            </AccordionRoot>
          ))}
        </Container>
      </Background>
    </SbEditable>
  )
}