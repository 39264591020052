import styled, { keyframes } from "styled-components";
import Colors from "../../../styles/colors";

export const sideScroll = keyframes`
 0% {
   transform: translateX(100%)
 }

 100% {
   transform: translateX(0)
 }
`;

export const Overlay = styled.div`
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  overflow: hidden;
  width: 100%;
  height: 100%;
  animation: 1s ${sideScroll};

  @media (min-width: 1024px) {
    animation: none;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  padding: 25px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 1024px) {
    width: 80%;
    height: auto;
    padding: 31px 25px 40px;
  }

  @media (min-width: 1920px) {
    width: 60%;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-right: 15px;

  @media (min-width: 1024px) {
    padding: 0;
  }
`;

export const Title = styled.h2`
  font-size: 26px;
  font-weight: 800;

  @media (min-width: 1024px) {
    font-size: 32px;
  }
`;

export const SecondTitle = styled.span`
  font-weight: 500;
  margin-left: 10px;
`;

export const FilterContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background-color: #fff;
    width: 8px;
    -webkit-border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgrey;
    -webkit-border-radius: 10px;
  }

  @media (min-width: 1024px) {
    height: 70%;
  }

  @media (min-width: 1440px) {
    height: 75%;
  }
`;

export const FilterContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FilterTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;

  @media (min-width: 1024px) {
    font-size: 18px;
    margin-top: 30px;
  }
`;

export const FilterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  width: 100%;
  justify-content: center;

  @media (min-width: 1024px) {
    margin-bottom: 0;
    justify-content: flex-start;
  }
`;

export const FilterItem = styled.button`
  border: none;
  width: 47%;
  height: 40px;
  background: ${Colors.red};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin: 10px 10px 10px 0;

  @media (min-width: 1024px) {
    max-width: 30%;
    width: 180px;
    margin: 10px 20px 10px 0;
  }
`;

export const FilterItemDisabled = styled(FilterItem)`
  background: #b4b4b4;
`;

export const Name = styled.p`
  font-size: 14px;
  max-width: 715px;
  width: 100%;
  text-align: center;
  color: white;
`;

export const NameDisabled = styled(Name)`
  color: #868686;
`;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const Button = styled.a`
  width: 100%;
  height: 60px;
  background: ${Colors.red};
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: background 0.5s;

  &:hover {
    background: ${Colors.darkred};
  }

  @media (min-width: 768px) {
    width: 400px;
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    width: 300px;
    font-size: 16px;
  }
`;

export const Icon = styled.img`
  margin-left: 10px;
`;
