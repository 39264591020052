import React from "react";
import slugify from "slugify";
import Set from "../../../assets/images/Set.svg";

import {
  CardButton,
  CardImageWrapper,
  CardModalities,
  CardModalitiesInner,
  CardModalityTitle,
  CardPucprBody,
  CardPucprContainer,
  Cardpucpr,
  Modality,
  NewTag,
  Title,
} from "./style";

export default function CardPucpr({
  title,
  img,
  full_slug,
  modalities,
  redirect,
}) {
  const handleButtonCourse = () => {
    console.log("Dispatching event to Google Analytics");

    window.ga &&
      window.ga("send", {
        hitType: "event",
        eventCategory: "Cursos",
        eventAction: "Clique",
      });

    window.location.href = redirect
      ? full_slug
      : window.location.origin + full_slug;
  };

  const getModalitySlug = (modalityName) => {
    return slugify(modalityName).toLowerCase().trim();
  };

  const updateIndex = (newIndex, slug) => {
    if (slug === "novo") {
      window.location.href = window.location.origin + full_slug;
    } else {
      window.location.href = window.location.origin + full_slug + "#" + slug;
    }
  };

  const hasNew = modalities?.some(
    (modality) => modality?.toUpperCase() === "NOVO"
  );
  const filteredModalities = modalities?.filter(
    (modality) => modality?.toUpperCase() !== "NOVO"
  );

  return (
    <CardPucprContainer>
      <CardPucprBody>
        <Cardpucpr>
          <CardImageWrapper
            onClick={handleButtonCourse}
            className="card-image-wrapper"
          >
            {hasNew && <NewTag>NOVO</NewTag>}
            <img src={img} alt="Imagem do Curso" width="100%" height="100%" />
          </CardImageWrapper>
          <Title>{title}</Title>
          <CardModalities>
            <CardModalityTitle>MODALIDADES DISPONÍVEIS</CardModalityTitle>
            <CardModalitiesInner>
              {filteredModalities.map((modality, index) => {
                return (
                  <Modality
                    key={`md-${modality}`}
                    onClick={() =>
                      updateIndex(index, getModalitySlug(modality))
                    }
                  >
                    {modality}
                  </Modality>
                );
              })}
            </CardModalitiesInner>
          </CardModalities>
          <CardButton
            href={full_slug}
            rel="noopener noreferrer"
            onClick={() => {
              window.ga &&
                window.ga("send", {
                  hitType: "event",
                  eventCategory: "Cursos",
                  eventAction: "Clique",
                });
            }}
          >
            SAIBA MAIS
            <img src={Set} alt="Ir para o curso" />
          </CardButton>
        </Cardpucpr>
      </CardPucprBody>
    </CardPucprContainer>
  );
}
