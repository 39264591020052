import styled from "styled-components";
import Colors from "../../../styles/colors";

export const GuidanceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${Colors.red};
  padding: 35px;

  @media (min-width: 1024px) {
    width: 50%;
    background-color: ${Colors.red};
    border-top: 1.81rem solid ${Colors.white};
    padding: 100px 38px 30px;
    align-items: flex-start;
  }
`;

export const GuidanceInner = styled.div`
  width: 355px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-top: 50px;

  @media (min-width: 1024px) {
    width: 100%;
    align-items: flex-start;
    max-width: 538px;
  }
`;

export const Title = styled.h2`
  text-align: left;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  width: 265px;
  text-transform: uppercase;
  color: white;
  margin-top: 10px;
`;

export const FeaturedTitle = styled.span`
  text-align: left;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  width: 265px;
  z-index: 1;
`;

export const Detail = styled.span`
  position: absolute;
  top: 5%;
  left: 5%;
  z-index: 0;
  overflow: hidden;
  width: 132px;
  height: 35px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 50px;

  @media (min-width: 1024px) {
    top: 9.5%;
    left: -3.5%;
  }
`;
