import React from 'react'
import SbEditable from 'storyblok-react'
import Container from '../../Common/Container'
import { Background, ButtonLink, ContentWrapper, Title } from './styles'


export default function DownloadStudyTrail(props) {
  return (
    <SbEditable content={props.content}>
      <Background>
        <Container>
          <ContentWrapper>
            <Title>{props.content.title}</Title>

            <ButtonLink href={props.content.button_link} target="_blank">{props.content.button_text}</ButtonLink>
          </ContentWrapper>
        </Container>
      </Background>
    </SbEditable>
  )
}