import React from "react";
import SbEditable from "storyblok-react";
import Container from "../../Common/Container";
import { Background, Card, CardSubTitle, CardTiteWrapper, InfoWrapper, TitleWrapper } from "./styles";

import { render } from "storyblok-rich-text-react-renderer";

export default function CourseInfo(props) {
  return (
    <SbEditable content={props.content}>
      <Background>
        <Container>
          <TitleWrapper>
            {render(props.content.title)}
          </TitleWrapper>

          <InfoWrapper>
            {props.content.cards.map(card => (
              <Card key={card._uid}>
                <CardTiteWrapper>{render(card.title)}</CardTiteWrapper>
                <CardSubTitle>{card.subtitle}</CardSubTitle>
              </Card>
            ))}
          </InfoWrapper>
        </Container>
      </Background>
    </SbEditable>
  )
}