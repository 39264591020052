import React, { useState } from "react";

export const VideoContext = React.createContext({});

export const VideoContextProvider = (props) => {
  const [sideVideo, setSideVideo] = useState(true);

  return (
    <VideoContext.Provider value={{ sideVideo, setSideVideo }}>
      {props.children}
    </VideoContext.Provider>
  );
};

export const useVideoContext = () => React.useContext(VideoContext);
