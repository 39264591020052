import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: 0px 20px;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    padding: 0px;
    max-width: 980px;
  }

  @media screen and (min-width: 1440px) {
    max-width: 1260px;
  }
`;

export default Container;