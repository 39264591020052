import React from "react";
import SbEditable from "storyblok-react";
import faqBackground from "../../../assets/images/back-faq.jpg";
import redArrow from "../../../assets/images/red-arrow.png";
import whiteArrow from "../../../assets/images/white-arrow.png";
import {
  ButtonContainer,
  ButtonFAQ,
  ButtonFinancing,
  ButtonTitle,
  Container,
  Description,
  DescriptionFAQ,
  FAQ,
  FeaturedDescription,
  FeaturedTitleFAQ,
  FeaturedTitleFinancing,
  Financing,
  Icon,
  InnerContainer,
  TitleFAQ,
  TitleFinancing,
} from "./style";

export default (props) => (
  <SbEditable content={props.content}>
    <Container className="Container" source={faqBackground}>
      <InnerContainer>
        <Financing>
          <TitleFinancing>
            PORTAL
            <FeaturedTitleFinancing>
              DO CANDIDATO
            </FeaturedTitleFinancing>
          </TitleFinancing>

          <Description>{props.content.description}</Description>

          <ButtonFinancing
            href={props.content.button_link}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() =>
              console.log('Dispatching event to Google Analytics') || window.ga("send", {
                hitType: "event",
                eventCategory: "teste",
                eventAction: "teste",
              })
            }
          >
            <ButtonTitle>ACESSE O PORTAL <br />
              DO CANDIDATO</ButtonTitle>
            <Icon src={whiteArrow} />
          </ButtonFinancing>
        </Financing>
        <FAQ>
          <TitleFAQ>
            <FeaturedTitleFAQ>FAQ</FeaturedTitleFAQ>
            PERGUNTAS FREQUENTES
          </TitleFAQ>

          <DescriptionFAQ>
            Ainda tem alguma dúvida? <br />
            Tire todas no <FeaturedDescription>nosso FAQ!</FeaturedDescription>
          </DescriptionFAQ>

          <ButtonContainer>
            <ButtonFAQ
              href="/faq"
              rel="noopener noreferrer"
              target="_blank"
              onClick={() =>
                console.log('Dispatching event to Google Analytics') || window.ga("send", {
                  hitType: "event",
                  eventCategory: "FAQ",
                  eventAction: "Clique",
                })
              }
            >
              ACESSE O FAQ
              <Icon src={redArrow} />
            </ButtonFAQ>
            <ButtonFAQ
              rel="noopener noreferrer"
              target="_blank"
              href="/fale-conosco"
              onClick={() =>
                console.log('Dispatching event to Google Analytics') || window.ga("send", {
                  hitType: "event",
                  eventCategory: "Fale Conosco",
                  eventAction: "Clique",
                })
              }
            >
              FALE CONOSCO
              <Icon src={redArrow} />
            </ButtonFAQ>
          </ButtonContainer>
        </FAQ>
      </InnerContainer>
    </Container>
  </SbEditable>
);
