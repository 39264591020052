import styled from "styled-components";
import Colors from "../../../styles/colors";

export const Header = styled.header`
  width: 100%;
  height: auto;
  background: ${Colors.red};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 16px;

  @media (min-width: 1024px) {
    align-items: flex-start;
    padding: 26px 0;
  }
`;

export const HeaderInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    margin: 0 auto;
    max-width: 980px;
    flex-direction: row;
    align-items: center;
  }

  @media (min-width: 1440px) {
    max-width: 1260px;
  }
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 36px;
  color: #ffffff;
  line-break: anywhere;

  @media (min-width: 1024px) {
    font-size: 2rem;
  }
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ContainerInner = styled.div`
  display: flex;
`;

export const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 300px;
  height: 50px;

  img {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  min-width: 300px;
  height: 50px;
  padding: 16px 17px;

  &:focus {
    outline: none !important;
  }

  ::placeholder {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.3px;
    color: #9e9e9e;
  }

  @media (min-width: 1024px) {
    margin: 0;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;

  @media (min-width: 1024px) {
    margin-left: 36px;
  }
`;

export const ArrowMeet = styled.div`
  display: flex;

  img {
    margin-right: 8px;
  }
`;

export const Subtitle = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 16px;
  color: #ffffff;
`;
