import React from "react"

export const motives = [
  {
    title: "Influência e carreira multidisciplinar",
    description: 'Você já deve ter ouvido falar de "influencer" por aí. Já pensou que isso pode ser uma profissão, uma carreira multidisciplinar ou até mesmo seu estilo de vida? A graduação 4D é repleta de recursos para você se desenvolver por inteiro e até descobrir novas coisas sobre você.',
  },
  {
    title: "Primeiro curso cocriado do Brasil",
    description: 'Esse curso foi criado por mais de 50 influencers, profissionais do mercado, professores e futuros alunos em ambientes imersivos. Reunimos tudo e transformamos em metodologia a partir da escuta. Ficou incrível!',
  },
  {
    title: "Aulas no metarveso",
    description: 'Muitas interações acontecerão em um universo paralelo, como um game. Aulas, network, palestras e apresentações suas também acontecerão por lá. Além de ser um ambiente mega divertido para um café ou happy hour com os colegas.',
  },
  {
    title: "Você monta sua playlist de estudos",
    description: 'Algumas disciplinas são obrigatórias cumprindo algumas exigências, mas muitas delas você escolhe de acordo com seu interesse ou curiosidade. No fim das contas, você tem uma grade de cursos única, feita para você.',
  },
  {
    title: "Cocriação com inteligência artificial",
    description: 'Desde a campanha de marketing do curso até materiais de aulas, contaremos com a interação positiva com a IA. O resultado disso você poderá nos contar depois.',
  },
  {
    title: "Aprenda com influenciadores",
    description: 'Você irá aprender com quem está em frente às câmeras e produzindo conteúdo relevante por trás delas. Viva a mistura perfeita entre teoria e prática, aprenda fazendo e recebendo feedbacks em tempo real.',
  },
  {
    title: "Ética, responsabilidade e inteligência emocional",
    description: 'A PUCPR preza pela formação integral do ser humano. Um diploma da PUCPR também é um diploma de "gente boa". Para além das telas, ajudamos você a cuidar do seu eu, desde lidar com "haters" até ajudar a se posicionar como um profissional ético e responsável em qualquer "universo".',
  },
  {
    title: "Projetos com impacto social",
    description: 'Sabe o famoso TCC e as famosas notas? Na graduação 4D você será avaliado por projetos que impactem positivamente a comunidade. Todo mundo ganha muito com isso.',
  },
  {
    title: "Professores PUCPR referência",
    description: 'Os professores PUCPR são referência em conteúdos relevantes para sua formação, além disso, aqui o “papo é reto”.',
  },
  {
    title: "Mercado aquecido e estratégia de convencimento",
    description: 'Já existem mais influencers que veterinários no Brasil. Teremos um guia que ajudará você a elaborar mais estratégias de convencimento sobre o valor do profissional formado em economia da influência digital. Te interessou, nos chame no WhatsApp que enviaremos para você.',
  },
]

export const graduationContent = [
  {
    title: 'Conteúdo diferenciado',
    description: '1600 horas de conteúdo distribuídas em 2 anos de formação, com 400 horas de aula por semestre.'
  },
  {
    title: 'Acompanhamento humanizado',
    description: 'Aulas ao vivo, mentoria online e um atendimento personalizado.​'
  },
  {
    title: 'Hands on',
    description: 'Durante o curso, você desenvolverá 4 grandes projetos com mão na massa.'
  },
  {
    title: 'Aprendizagem e networking',
    description: 'Espaço de troca e convivência no metaverso.'
  },
  {
    title: 'Conexão',
    description: 'Ambiente virtual de aprendizagem e acesso a bibliotecas virtuais.'
  },
  {
    title: 'Reconhecimento',
    description: 'Diploma de Curso Superior reconhecido pelo MEC.'
  },
]

export const faqQuestions = [
  {
    title: 'O que é um Curso Superior de Tecnologia?',
    description: 'Os cursos tecnólogos são graduações de curta duração, normalmente de 2 a 3 anos. É uma modalidade de curso reconhecida pelo MEC como graduação que confere grau de Tecnólogo ao seu concluinte. Essa modalidade de graduação visa formar especialistas para atender campos específicos do mercado de trabalho e possibilita o seu ingresso em pós-graduações.'
  },
  {
    title: 'A formação nesse curso me permitirá trabalhar em quais áreas?',
    description: 'A formação nesse curso é abrangente e te possibilita atuar em qualquer área da Gestão, Negócios e Comunicação.'
  },
  {
    title: 'Qual o tempo de duração e carga horária do curso?',
    description: 'Esta graduação tem a duração de dois anos compondo 400hrs por semestre, totalizando 1600hrs.'
  },
  {
    title: 'Esta graduação tem TCC?',
    description: 'Não. É um curso dinâmico, você será avaliado através de projetos integradores em cada semestre. O Curso Superior de Tecnologia não possui obrigatoriedade de trabalho de conclusão de curso.'
  },
  {
    title: 'Terei direito ao diploma?',
    description: 'Esta graduação é válida pelo MEC conforme a Resolução CNE/CP 3, de 18 de dezembro de 2002., disponibilizando emissão do diploma após a conclusão e colação de grau.'
  },
  {
    title: 'Qual pré-requisito para eu me matricular?',
    description: 'Para ingressar nesta graduação, é necessário ter concluído o Ensino médio, ter sido aprovado no vestibular ou ter nota superior a 450 no ENEM e não tiver obtido nota 0 na redação (nos últimos 10 anos).'
  },

  {
    title: 'Qual a documentação obrigatória para matrícula?',
    description: <React.Fragment>
      <p>- Carteira de Identidade</p>
      <p>- CPF</p>
      <p>- Certidão de Nascimento ou Casamento</p>
      <p>- Certidão de Conclusão do Ensino Médio</p>
      <p>- Histórico Escolar do Ensino Médio</p>
      <p>- Contrato de Prestação de Serviços Educacionais assinado.</p>
    </React.Fragment>
  },
  {
    title: 'Como me inscrevo no vestibular?',
    description: <React.Fragment>Para se inscrever no vestibular <a href="https://4d.pucpr.br/orbita/subscription/">clique nesse link</a> e siga as instruções em tela ou entre em contato com o Contact Center <a href="https://api.whatsapp.com/send?phone=5541991321897&text=Ol%C3%A1%21%20Quero%20falar%20com%20um%20especialista">clicando aqui.</a></React.Fragment>
  },
  {
    title: 'Como faço minha matrícula?',
    description: 'Após a aprovação no vestibular você será direcionado à página para preenchimento de dados pessoais, pagamento e envio de documentação obrigatória. Após aprovação do pagamento você receberá um e-mail de confirmação com os seus dados de acesso à Plataforma de Estudos e poderá entrar em contato com o Time de Community Manager do seu curso.'
  },
  {
    title: 'Porquê esse curso é uma Graduação 4D?',
    description: 'O Curso representa a 4ª dimensão do ensino conectada à economia 4.0: união da tecnologia com a educação, e apresenta os 4 pilares: - PJBL - Project Based Learning - mão na massa, vivências reais; - Experiência no metaverso - vanguarda na agregação de uma nova tecnologia com o ensino; - Trilhas de conteúdo - autonomia para montar a grade curricular de acordo com os interesses e aspirações; - Professores atuantes - junção da aprendizagem com networking.'
  },
  {
    title: 'Quais as formas de pagamento?',
    description: 'O pagamento pode ser efetuado via boleto bancário ou cartão de crédito (não temos recorrência para pagamentos via cartão, sendo necessário pagamento mensal manualmente via portal de serviços). Todos os valores podem ser conferidos na página inicial do site.'
  },
]
