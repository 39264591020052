import React, { useState } from "react";
import Set from "../../../assets/images/Set.svg";
import { render, NODE_PARAGRAPH } from "storyblok-rich-text-react-renderer";
import searchIcon from "../../../assets/images/coolicon.png";
import filterIcon from "../../../assets/images/icon-filter.png";
import AdvancedFilter from "../../Course/AdvancedFilter";

import {
  Header,
  Title,
  Subtitle,
  ArrowMeet,
  HeaderInner,
  HeaderContent,
  ContainerInner,
  SearchInput,
  SearchWrapper,
  FilterWrapper,
} from "./style";

const strimSubtitle = (subtitle) => {
  return render(subtitle, {
    nodeResolvers: {
      [NODE_PARAGRAPH]: (children, props) => children,
    },
  });
};

export default function BannerPucpr(props) {
  const [showFilter, setShowFilter] = useState(false);

  const applySearch = () => {
    setImmediate(() => {
      const searchQuery = document.getElementById("search").value;
      props.setPageState({ searchQuery });
    });
  };

  const toggleFilterVisibility = (boolean) => {
    const htmlTag = document.querySelector("html");
    if (boolean) {
      htmlTag.classList.add("overflow-hidden");
    } else {
      htmlTag.classList.remove("overflow-hidden");
    }

    setShowFilter(boolean);
  };

  // const updateTags = (areas, modalities, cities) => {
  //   props.setPageState({ areas, modalities, cities })
  // }

  const updateTags = (areas, cities) => {
    props.setPageState({ areas, cities });
  };

  return (
    <Header>
      <HeaderInner>
        <HeaderContent>
        <Title> {(props.content.title === "CURSOS")  ? "CURSOS DE GRADUAÇÃO"  : props.content.title }</Title> 
          {strimSubtitle(props.content.subtitle)?
          <ArrowMeet>
            <img src={Set} alt="Seta apontada para a direita" />
            <Subtitle>{strimSubtitle(props.content.subtitle)}</Subtitle>
          </ArrowMeet> : ""}
        </HeaderContent>
        {props.content.title === "CURSOS" && (
          <ContainerInner>
            <SearchWrapper>
              <SearchInput
                id="search"
                placeholder="Realize sua pesquisa"
                type="text"
                onKeyDown={applySearch}
              />
              <img
                style={{ cursor: "pointer" }}
                src={searchIcon}
                alt="Pesquisar"
                onClick={applySearch}
              />
            </SearchWrapper>
            <FilterWrapper>
              <img
                style={{ cursor: "pointer" }}
                src={filterIcon}
                alt="Filtrar"
                onClick={() => toggleFilterVisibility(true)}
              />
            </FilterWrapper>
            {showFilter && (
              <AdvancedFilter
                onShowFilter={setShowFilter}
                onFilterChange={updateTags}
                content={props.context}
                currentButtonArea={props.getPageState("areas", [])}
                currentButtonCity={props.getPageState("cities", [])}
              />
            )}
          </ContainerInner>
        )}
      </HeaderInner>
    </Header>
  );
}
