import React, { useEffect } from 'react';
import CloseIcon from '../../../assets/images/close-menu.png';
import { Close, Content, Description, Installment, ListInstallments, Root, Title } from './styles';

export default function PaymentPlans({ open, handleClose, installments, description }) {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <Root>
      <Content>
        <Close onClick={handleClose}>
          <img src={CloseIcon} alt="Fechar" width="100%" height="100%" />
        </Close>

        <Title>Planos de pagamentos</Title>

        <ListInstallments>
          {installments.map(instalment => (
            <Installment key={instalment.id}>
              <span>{instalment.installments}</span>
              <span>{instalment.value}</span>
            </Installment>
          ))}
        </ListInstallments>

        <Description>{description}</Description>
      </Content>
    </Root>
  )
}