import styled from "styled-components";
import Colors from "../../../styles/colors";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;


  @media(min-width: 1024px) {
    background-image: url(${({ source }) => (source)});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
`

export const InnerContainer = styled.div`
  width: 100%;

  @media(min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    max-width: 980px;
  }

  @media(min-width: 1440px) {
    max-width: 1260px;
  }
`

export const Financing = styled.section`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 35px 26px;
  max-width: 375px;

  @media(min-width: 1024px) {
    background: transparent;
    padding: 102px 0;
    width: 50%;
    max-width: inherit;
  }
`

export const FAQ = styled(Financing)`
  background: ${Colors.red};
  padding: 40px 40px 31px;
  width: 100%;
  max-width: none;

  @media(min-width: 1024px) {
    background: transparent;
    max-width: inherit;
    padding: 102px 0;
    width: 380px;
  }
`

export const TitleFinancing = styled.h2`
  font-size: 32px;
  line-height: 36px;
  font-weight: 400;
  display: flex;
  flex-direction: column;

  @media(min-width: 1024px) {
    font-size: 42px;
    line-height: 47px;
  }
`

export const FeaturedTitleFinancing = styled.span`
  font-weight: 700;
  margin-bottom: 24px;

  @media(min-width: 1024px) {
    margin-bottom: 67px;
  }
`

export const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 35px;

  @media(min-width: 1024px) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 75px;
    max-width: 350px;
  }
`

export const TitleFAQ = styled.h2`
  width: 215px;
  font-size: 32px;
  line-height: 32px;
  font-weight: 400;
  color: white;
  text-align: left;
  display: flex;
  flex-direction: column;

  @media(min-width: 1024px) {
    font-size: 42px;
    line-height: 48px;
    margin-bottom: 24px;
  }
`

export const FeaturedTitleFAQ = styled.span`
  font-size: 72px;
  line-height: 70px;
  color: white;
  font-weight: 800;

  @media(min-width: 1024px) {
    font-size: 96px;
    line-height: 90px;
  }
`

export const DescriptionFAQ = styled(Description)`
  width: 211px;
  margin-top: 12px;
  color: white;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 72px;

  @media(min-width: 1024px) {
    margin-bottom: 46px;
  }
`

export const FeaturedDescription = styled.span`
  font-weight: 700;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media(min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`

export const ButtonFinancing = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 20px;
  height: 60px;
  background: ${Colors.red};
  width: 330px;
  transition: all .5s;

  img {
    transition: all .5s;
  }

  &:hover {
    letter-spacing: .7px;
  }

  @media(min-width: 1024px) {
    width: 350px;
  }
`

export const ButtonFAQ = styled(ButtonFinancing)`
  width: 300px;
  height: 60px;
  background: white;
  color: ${Colors.red};
  font-weight: 700;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 16px;
  padding: 0;
  line-height: 18px;
  margin-bottom: 32px;
  transition: all .5s;

  &:hover {
    letter-spacing: inherit;
  }

  @media(min-width: 1024px) {
    width: 180px;
  }
`

export const ButtonTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: white;
`

export const Icon = styled.img`
  margin-left: 10px;
  color: white;
  width: 16px;
  height: 12px;
`