import React, { useMemo, useState } from 'react';
import Slider from 'react-slick';
import YoutubeIconImg from '../../../../../assets/images/youtube-logo.png';
import Arrow from '../Arrow';
import Modal from '../Modal';
import {
  CarrouselBanner,
  CarrouselContainer,
  CarrouselDescription,
  CarrouselImage,
  CarrouselInfo,
  CarrouselItem,
  CarrouselTitle,
  YoutubeIcon
} from './styles';

export default function Carrousel({ items }) {
  const [itemSelected, setItemSelected] = useState(null);
  const isOpen = useMemo(() => !!itemSelected, [itemSelected]);

  const handleCloseModal = () => setItemSelected(null);
  const handleOpenModal = (item) => setItemSelected(item);

  const NextArrow = (props) => <Arrow direction="right" {...props} />;
  const PrevArrow = (props) => <Arrow direction="left" {...props} />;

  const mobileSettings = {
    slidesToShow: 1.2,
    slidesToScroll: 1,
    infinite: false,
    centerMode: true,
  };

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    initialSlide: 0,
    dots: false,
    centerMode: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          centerMode: false,
        }
      },
      {
        breakpoint: 700,
        settings: mobileSettings,
      },
      {
        breakpoint: 375,
        settings: {
          ...mobileSettings,
          slidesToShow: 1.1,
        }
      },
    ]
  }

  return (
    <>
      <CarrouselContainer>
        <Slider {...settings}>
          {items.map(item => (
            <CarrouselItem key={item.image} onClick={() => handleOpenModal(item)}>
              <CarrouselImage src={item.image} alt={item.name} />
              <CarrouselBanner className='carrousel-background'></CarrouselBanner>
              <CarrouselInfo>
                <YoutubeIcon
                  className='carrousel-item-youtube-icon'
                  alt=""
                  src={YoutubeIconImg}
                />
                <CarrouselTitle>{item.name}</CarrouselTitle>
                <CarrouselDescription>{item.ocupation}</CarrouselDescription>
              </CarrouselInfo>
            </CarrouselItem>
          ))}
        </Slider>
      </CarrouselContainer>

      {isOpen && (
        <Modal
          open={isOpen}
          handleCloseModal={handleCloseModal}
          professional={itemSelected}
        />
      )}
    </>
  )
}