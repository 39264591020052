import React from "react"
import icon from "../../../../../assets/images/Set.svg"
import {
  CardAction,
  CardActionIcon,
  CardActionText,
  CardContent,
  CardImage,
  CardImageWrapper,
  CardLink,
  CardTitle,
  CardWrapper,
} from "./styles"

export default function CardCarrousel({ children, title, text_link, link, image }) {
  return (
    <CardLink to={link} target="_blank">
      <CardWrapper>
        <CardImageWrapper>
          <CardImage src={image} alt={title} />
        </CardImageWrapper>
        <CardContent>
          <CardTitle>{title}</CardTitle>
          {children}
          <CardAction>
            <CardActionText>{text_link}</CardActionText>
            <CardActionIcon>
              <img src={icon} alt="" width="100%" height="100%" />
            </CardActionIcon>
          </CardAction>
        </CardContent>
      </CardWrapper>
    </CardLink>
  )
}