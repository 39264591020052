import styled from "styled-components";
import Colors from "../../../styles/colors";

export const DashedLine = styled.hr`
  border: 1px dashed ${Colors.lightpurple};
`;

export const List = styled.ul`
  width: 100%;
  margin-top: 30px;
  max-width: 780px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background-color: #fff;
    width: 8px;
    -webkit-border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgrey;
    -webkit-border-radius: 10px;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 20px 0;
  cursor: pointer;
`;

export const ItemName = styled.h3`
  font-size: 1.125rem;
  font-weight: ${(props) => (props.clicked ? `700` : `inherit`)};
  color: #000;
  width: 642px;
  padding-right: 10px;
`;

export const ItemsContainer = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled.a`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.red};
  cursor: pointer;
  margin-right: 10px;

  img {
    transition: all 0.5s;
  }

  &.is-active img {
    transform: rotate(45deg);
  }

  &:hover {
    opacity: 0.8;
  }
`;
