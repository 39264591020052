import React from "react";
import {
  GuidanceContainer,
  Title,
  FeaturedTitle,
  GuidanceInner,
  Detail,
} from "./style";
import background from "../../../assets/images/background-guidance.png";
import ListAccordion from "../ListAccordion";
import detailImg from "../../../assets/images/detail-ingresso.png";

const Guidance = (props) => {
  return (
    <GuidanceContainer
      className="guidance"
      src={background}
      id="como-ingressar"
    >
      <GuidanceInner>
        <Title>como</Title>
        <FeaturedTitle>ingressar na graduação</FeaturedTitle>

        <Detail src={detailImg} />

        <ListAccordion
          items={props.content.items}
          setGridState={props.setGridState}
          getGridState={props.getGridState}
        />
      </GuidanceInner>
    </GuidanceContainer>
  );
};

export default Guidance;
