import React from "react";
import SbEditable from "storyblok-react";
import { render } from "storyblok-rich-text-react-renderer";
import Container from "../../Common/Container";
import TitleSection from "../components/TitleSection";
import Carrousel from "./components/Carrousel";
import { Background, SubTitle } from "./styles";

export default function Professionals(props) {
  const formatedProfessionals = props.content.items.map(professional => ({
    image: professional.image,
    name: professional.name,
    ocupation: professional.ocupation,
    embed: professional.youtube_link,
    about: render(professional.description),
  }));

  return (
    <SbEditable content={props.content}>
      <Background>
        <Container>
          <TitleSection>{props.content.title}</TitleSection>

          <SubTitle>{props.content.subtitle}</SubTitle>

          <Carrousel items={formatedProfessionals} />
        </Container>
      </Background>
    </SbEditable>
  )
}