import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { default as Colors, default as colors } from "../../../styles/colors";

export const Header = styled.header`
  width: 100%;
  height: auto;
  padding: 1.3rem 24px 1.3rem;
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  background: #fff;
  border-bottom: 5px solid ${Colors.red};
  z-index: 10;
`;

export const HeaderInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    margin: 0 auto;
    max-width: 980px;
    justify-content: space-between;
  }

  @media (min-width: 1440px) {
    max-width: 1260px;
  }
`;
export const Img = styled.div`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  width: ${({ cms }) => (cms === "true" ? 142 : 144)}px;
  height: ${({ cms }) => (cms === "true" ? 58 : 20)}px;
`;

export const Nav = styled.ul`
  display: flex;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  list-style: none;
  padding: 0 15px;
  font-weight: 400;

  &:hover {
    color: ${Colors.red};
  }

  @media (max-width: 930px) {
    color: #fff;
    padding: 10px 0;

    &:hover {
      color: #fff;
      opacity: 0.8;
    }
  }

  @media (min-width: 1024px) {
    font-size: 14px;
  }

  @media (min-width: 1440px) {
    font-size: 16px;
  }
`;

export const NavLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !["isActive"].includes(prop),
})`
  color: ${(props) => (props.isActive ? `${Colors.red}` : `inherit`)};
  font-weight: ${(props) => (props.isActive ? `700` : `inherit`)};
`;

export const NavLinkExternal = styled.a`
  color: ${(props) => (props.isActive ? `${Colors.red}` : `inherit`)};
  font-weight: ${(props) => (props.isActive ? `700` : `inherit`)};
`;

export const Icon = styled.img`
  display: flex;
  position: absolute;
  right: 20px;
  cursor: pointer;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const sideScroll = keyframes`
 0% {
   transform: translateX(100%)
 }

 100% {
   transform: translateX(0)
 }
`;

export const Overlay = styled.div`
  animation: 1s ${sideScroll};
  background-color: ${colors.red};
  position: absolute;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 10;
`;

export const NavMobile = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #fff;
  width: 100%;
  font-size: 1.5rem;

  @media (max-width: 374px) {
    max-height: 56%;
    overflow-y: scroll;
  }
`;

export const HeaderMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CloseIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
`;

export const Logo = styled.img`
  max-width: 142px;
`;

export const LogoWhite = styled.span`
  margin-top: 10px;
  padding: 20px 0;
  font-size: 2.5rem;
  color: white;
  margin-left: 20px;
`;

export const ImgFooter = styled.img`
  padding-bottom: 15px;
  position: absolute;
  bottom: 3%;
  left: 6%;
`;
