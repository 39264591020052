import React, { useEffect, useState } from "react";
import arrow from "../../../assets/images/red-arrow.png";
import {
  Button,
  ButtonArea,
  Form,
  Icon,
  InfoContainer,
  InputItem,
  InputsArea,
  Label,
  Select,
  SelectItem,
  SubscribeContainer,
} from "./style";

const Subscribe = ({ methods, currentIndexModality, modalities }) => {
  const [selectValue, setSelectValue] = useState({});
  const [selectValue2, setSelectValue2] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  let isDisabled = true;
  let isDisabledCampus = true;
  const is4DModality = modalities.some(modality => modality.name === "4D");

  if (modalities[currentIndexModality].metodos?.length > 0) {
    methods = modalities[currentIndexModality].metodos;
  }

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    setSelectValue2([]);
  }, [selectValue]);

  const isDisabledMethod = selectValue.length > 0 && selectValue.includes("Selecione um método");

  if (!isDisabledMethod || (selectValue2.length > 0 && selectValue2 !== "Selecione uma cidade")) {
    isDisabled = false;
  }

  if (selectValue.length > 0 && selectValue !== "Selecione um método") {
    isDisabledCampus = false;
  }

  const handleNextStep = (event) => {
    event.preventDefault();
    window.open(event.target.campus.value, "_blank");
    // window.open(event.target.campus.value + "?" + utmzCookie, "_blank");
  };

  // function getCookie(cookieName) {
  //   const name = cookieName + "=";
  //   const cookieArray = document.cookie.split(";"); //break cookie into array
  //   for (let i = 0; i < cookieArray.length; i++) {
  //     const cookie = cookieArray[i].replace(/^\s+|\s+$/g, ""); //replace all space with '' = delete it
  //     if (cookie.indexOf(name) === 0) {
  //       return cookie.substring(name.length, cookie.length); //
  //     }
  //   }
  //   return null;
  // }

  // const utmzCookie = getCookie("__utmz"); //get GA cookie

  return (
    <SubscribeContainer className="Subscribe" isVisible={isVisible}>
      <InfoContainer>
        {methods?.length > 0 ? (
          <Form onSubmit={handleNextStep}>
            <InputsArea>
              <InputItem>
                <Label>Método de ingresso</Label>
                <Select
                  onChange={(e) => setSelectValue(e.target.value.split("->"))}
                  style={{
                    color: "black",
                  }}
                >
                  {(methods.length > 0 || !methods[0]) && (
                    <SelectItem value="Selecione um método">
                      Selecione um método
                    </SelectItem>
                  )}

                  {methods?.length >= 1
                    ? methods.map((method, index) => {
                      if (methods[index].cities.length > 0) {
                        return (
                          <SelectItem
                            key={`option-${index}`}
                            value={`${index}->${method.name}`}
                          >
                            {method.name}
                          </SelectItem>
                        );
                      }

                      return true;
                    })
                    : null}
                </Select>
              </InputItem>
              <InputItem>
                <Label>{is4DModality ? 'Polos' : 'Campus'}</Label>
                <Select
                  disabled={isDisabledCampus}
                  onChange={(e) => setSelectValue2(e.target.value)}
                  name="campus"
                  style={{
                    color:
                      selectValue.length > 0 ||
                        methods[selectValue.length > 0 ? selectValue[0] : 0]
                          ?.cities.length === 1
                        ? "black"
                        : "#0207",
                  }}
                >
                  {methods[selectValue[0]]?.cities.length >= 1 ||
                    !methods[selectValue[0]]?.cities[0] ? (
                    <SelectItem value="Selecione uma cidade">
                      Selecione uma cidade
                    </SelectItem>
                  ) : (
                    <SelectItem
                      value={
                        selectValue.length > 0 &&
                        methods[selectValue[0]].cities[0].link.url
                      }
                    >
                      {selectValue.length > 0 &&
                        methods[selectValue[0]].cities[0].name}
                    </SelectItem>
                  )}
                  {methods.map((option, index) => {
                    if (
                      option.name === selectValue[1] &&
                      methods[index].cities.length >= 1
                    ) {
                      return methods[index].cities.map((city, index) => {
                        return (
                          <SelectItem
                            key={`city-${index}`}
                            value={city.link.url}
                          >
                            {city.name
                              .replace("Polo ", "")
                              .replace("Campus ", "")}
                          </SelectItem>
                        );
                      });
                    }
                    return true;
                  })}
                </Select>
              </InputItem>
            </InputsArea>
            <ButtonArea>
              <Button
                id="btn"
                type="submit"
                onClick={() =>
                  console.log("Dispatching event to Google Analytics") ||
                  window.ga("send", {
                    hitType: "event",
                    eventCategory: "Método de Ingresso",
                    eventAction: "Clique",
                  })
                }
                disabled={isDisabled || !selectValue2.length || selectValue2 === "Selecione uma cidade"}
              >
                Inscreva-se
                <Icon src={arrow} />
              </Button>
            </ButtonArea>
          </Form>
        ) : null}
      </InfoContainer>
    </SubscribeContainer>
  );
};

export default Subscribe;
