import React from "react";
import { Container } from "./style";
import SidebarContainer from "../SidebarContainer";
import slugify from "slugify";

export default (props) => {

  const getModalitySlug = (modalityName) => {
    return slugify(modalityName).toLowerCase().trim();
  };

  const slug = window.location.hash.replace("#", "");

  const slugIndex = props.content.course.modalities.findIndex(
    (modality) => slug === getModalitySlug(modality.name)
  );

  const currentIndex = props.getGridState
    ? props.getGridState("currentIndex", slugIndex !== -1 ? slugIndex : 0)
    : props.getPageState("currentIndex", slugIndex !== -1 ? slugIndex : 0);

  const modality = props.content.course.modalities[currentIndex];

  if (props.content.course.sidebar_hidden) {
    return null;
  }

  return (
    <Container>
      <SidebarContainer
        {...modality}
        methods={props.content.course.metodos || []}
        currentIndexModality={currentIndex}
        modalities={props.content.course.modalities}
      />
      {/* {sideVideo && (
        <VideoContainer
          url={modality.video_url.url}
          alt_text={props.content.course.thumbnail.alt}
        />
      )} */}
    </Container>
  );
};
