import SbEditable from "storyblok-react";
import React from "react";
import {
  EnrollContainer,
  Text,
  FeaturedText,
  Button,
  Img,
  EnrollInner,
} from "./style";
import arrow from "../../../assets/images/purple-arrow.png";

export default (props) => (
  <SbEditable content={props.content}>
    <EnrollContainer className="enroll">
      <EnrollInner>
        <Text>
          PASSOU NO
          <FeaturedText>VESTIBULAR?</FeaturedText>
        </Text>
        <Button
          href="https://www.pucpr.br/estude-na-pucpr/graduacao/formas-de-ingresso/procedimentos-para-matricula/"
          rel="noopener noreferrer"
          onClick={() =>
            console.log('Dispatching event to Google Analytics') || window.ga("send", {
              hitType: "event",
              eventCategory: "Matrícula",
              eventAction: "Clique",
            })
          }
        >
          MATRICULE-SE AQUI
          <Img src={arrow} alt="Seta apontando para a direita" />
        </Button>
      </EnrollInner>
    </EnrollContainer>
  </SbEditable>
);
