import React, { useState } from "react";
import { IoIosCheckmark } from 'react-icons/io';

import CourseImg from '../../../assets/images/guia-curso-mockup.png';
import Container from "../../Common/Container";
import { Button } from "../Components/Button";
import Section from "../Components/Section";
import SectionTitle from "../Components/SectionTitle";

import { AiOutlineCloseCircle } from "react-icons/ai";
import Form from "../Components/HubspotForm";
import { CloseButton, ModalContainer } from "../Components/Modal/styles";
import { CouseGuideContainer, CouseGuideList, CouseGuideListItem, ModalContent, ModalHeader, Title } from "./styles";

export default function CouseGuide() {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  return (
    <Section bgColor="white">
      <Container>
        <SectionTitle bgColor="white">
          Guia do curso
        </SectionTitle>
        <CouseGuideContainer>
          <div style={{ maxWidth: '425px' }}>
            <img src={CourseImg} alt="Ebook" width="100%" />
          </div>
          <div>
            <CouseGuideList>
              <CouseGuideListItem>
                <div className='list-icon'>
                  <IoIosCheckmark />
                </div>
                <strong>Como funcionam</strong> as aulas e atividades avaliativas
              </CouseGuideListItem>
              <CouseGuideListItem>
                <div className='list-icon'>
                  <IoIosCheckmark />
                </div>
                Informações sobre a <strong>experiência 4D</strong></CouseGuideListItem>
              <CouseGuideListItem>
                <div className='list-icon'>
                  <IoIosCheckmark />
                </div>
                <strong>Ementa</strong> disciplina a disciplina
              </CouseGuideListItem>
              <CouseGuideListItem>
                <div className='list-icon'>
                  <IoIosCheckmark />
                </div>
                <strong>Preço</strong> e opções de pagamento
              </CouseGuideListItem>
              <CouseGuideListItem>
                <div className='list-icon'>
                  <IoIosCheckmark />
                </div>
                Carga horária
              </CouseGuideListItem>
            </CouseGuideList>
            <div style={{ marginTop: 30 }}>
              <Button
                onClick={handleOpenModal}
                primary
                style={{ width: '100%', justifyContent: 'center', boxShadow: '0 2px 40px rgba(6,1,11,.26)' }}
              >
                Baixe o guia
              </Button>
            </div>
          </div>
        </CouseGuideContainer>

        {openModal && (
          <ModalContainer
            isOpen={openModal}
          >
            <ModalContent>
              <CloseButton onClick={handleCloseModal}>
                <AiOutlineCloseCircle />
              </CloseButton>

              <ModalHeader>
                <Title>Baixe o Guia!</Title>
              </ModalHeader>

              <Form
                region='na1'
                portalId="6064046"
                formId="d434cc82-e28d-48d6-b79f-a6019ab8be0c"
                target='#hubspotForm'
                inlineMessage="Obrigado! Faça agora o download do seu"
                leadForm={true}
                onFormSubmit={() => {
                  console.log('initial submit')
                }}
                onFormSubmitted={() => {
                  console.log('submitted form')
                  const hubsportForm = document.querySelector('#hubspotForm');

                  const downloadGuia = document.createElement('a')
                  downloadGuia.href = "https://6064046.fs1.hubspotusercontent-na1.net/hubfs/6064046/Guia%20do%20Curso%20-%20Gradua%C3%A7%C3%A3o%204D%20PUCPR.pdf"
                  downloadGuia.target = "_blank"
                  downloadGuia.rel = "noopener"
                  downloadGuia.innerText = 'Guia do Curso'
                  downloadGuia.className = "download-guia"
                  hubsportForm.appendChild(downloadGuia)
                }}
              />

            </ModalContent>
          </ModalContainer>
        )}
      </Container>
    </Section>
  )
}
