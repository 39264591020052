import React from 'react'
import BiancaImg from '../../../assets/images/profissionais/bianca.png'
import FlavioImg from '../../../assets/images/profissionais/flavio.png'
import JonatasImg from '../../../assets/images/profissionais/jonatas.png'
import MarceloImg from '../../../assets/images/profissionais/marcelo.png'
import YasminImg from '../../../assets/images/profissionais/yasmin.png'

export const professionals = [
  {
    name: 'Marcelo Tas',
    ocupation: 'Comunicador e educador',
    image: MarceloImg,
    about: (
      <React.Fragment>
        <li>Na área da educação, Tas coordenou a criação do Telecurso (Fundação Roberto Marinho/ TV Globo) e games interativos para o Museu da Língua Portuguesa, em São Paulo; e para o Museu do Amanhã, no Rio de Janeiro.</li>
        <li>Atualmente, Marcelo Tas é apresentador do programa Provoca e comentarista do Jornal da Cultura ambos da TV Cultura; é Associado Notável da I2AI (International Association of Artificial Inteligence) e Membro do Conselho Consultivo na Fundação Osesp – Orquestra Sinfônica do Estado de São Paulo.</li>
        <li>Já atuou como colunista e colaborador nos veículos: Folha de São Paulo; O Estado de São Paulo; revista Trip; revista Crescer; rádio CBN e rádio Bandnews FM. Na Internet, tem cerca de 10 milhões de seguidores e é um dos influenciadores mais premiados do país, entre eles: Prêmio Comunique-se (2007, 2008, 2009, 2010, 2015, 2019); Especialistas da Comunicação (2020, categoria Cultura); melhor blog em língua portuguesa segundo o The Bobs – Prêmio International da Deutche Welle (Alemanha, 2007).</li>
      </React.Fragment>
    ),
    obs: 'Marcelo Tas irá ministrar uma disciplina no curso reforçando a importância de aprender fazendo com quem lidera no mercado digital.'
  },
  {
    name: 'Bianca Andrade',
    ocupation: 'Empresária e influenciadora',
    image: BiancaImg,
    about: (
      <React.Fragment>
        <li>Empresária e influenciadora. Com 18 milhões de seguidores no Instagram e 5,7 milhões de inscritos no Youtube. Bianca começou na internet há 11 anos, com vídeos de tutoriais de maquiagem e, desde então, se reinventou diversas vezes. Hoje, é dona da holding Boca Rosa Company, que engloba as marcas Boca Rosa Hair e Boca Rosa Beauty. Em 2022, Bianca foi considerada uma das 20 mulheres de destaque do ano pela Forbes.</li>
      </React.Fragment>
    ),
    obs: null,
  },
  {
    name: 'Jhonatan Marques',
    ocupation: 'Humorista, criador de conteúdo e roteirista',
    image: JonatasImg,
    about: (
      <React.Fragment>
        <li>Jhonatan Marques é humorista, criador de conteúdo com mais de 320 mil seguidores e roteirista vinculado à Associação Brasileira de Autores Roteiristas (ABRA).</li>
        <li>Teve seu primeiro contato com a escrita de comédia no Stand-up Comedy (2013), em São Paulo. Possui uma vasta experiência em produção de conteúdo de humor para plataformas digitais como o canal Parafernalha e o canal Netflix Brasil, onde assina a concepção, roteiro e também apresenta o quadro "Resumindo Netflix".</li>
        <li>Já foi integrante da produtora Huuro - onde desenvolveu formatos originais para marcas como Cinemark - e atualmente é roteirista do Porta dos Fundos, onde além de assinar esquetes editorias e de branded content, participou da criação e desenvolvimento do roteiro do Especial de Natal do Porta dos Fundos de 2021, a animação "Te Prego Lá Fora”, disponível na Paramount+, e o Especial de Natal de 2022, a comédia de terror “O Espirito do Natal”, a ser lançada também para Paramount+.</li>
        <li>Foi roteirista de um filme para uma plataforma de streaming e roteirista chefe de uma série documental, também para streaming, ambas em sigilo.</li>
      </React.Fragment>
    ),
    obs: null,
  },
  {
    name: 'Yasmin Yassine',
    ocupation: 'Criadora de conteúdo digital e host do primeiro videocast feminino do Brasil, o Venus Podcast',
    image: YasminImg,
    about: (
      <React.Fragment>
        <li>
          Yasmin Yassine é criadora de conteúdo digital e host do primeiro videocast feminino do Brasil, o Venus Podcast. Começou a trabalhar na área artística em 2014, atuando como cantora e DJ de eventos, tomando rumo contrário de sua área de formação, Comércio Exterior. Começou a ganhar notoriedade nas redes sociais em 2017, ao viralizar com vídeos de humor e imitação.  A criadora vive da sua renda 100% através do digital, trazendo tópicos como o podcast ao vivo diário, vlogs, música, lifestyle, humor, publicidade e curiosidades da cultura árabe.  Além de ser uma das artistas da nova geração que consegue transitar facilmente por diversos canais, formatos e outros veículos de comunicação (palcos, televisão, rádio).
        </li>
      </React.Fragment>
    ),
    obs: null,
  },
  {
    name: 'Flávio Santos',
    ocupation: 'CEO da MField, comunicador e escritor.',
    image: FlavioImg,
    about: (
      <React.Fragment>
        <li>
          Comunicador e escritor, o publicitário é CEO da MField desde 2017, empresa especializada em conteúdo e marketing de influência, da qual é um dos fundadores. Autor do Livro Economia da influência.
        </li>
      </React.Fragment>

    ),
    obs: null,
  },
];
