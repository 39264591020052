import styled from "styled-components";

export const Background = styled.section`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  @media screen and (min-width: 1024px) {
   min-height: 126px;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  > h4 {
    font-size: 24px;
    text-align: center;

     @media screen and (min-width: 1024px) {
      font-size: 38px;
    }
  }

  > h3 {
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    padding-top: 16px;

    @media screen and (min-width: 1024px) {
      font-size: 32px;
      line-height: 36px;
      padding-top: 24px;
    }
  }

  > p {
    text-align: center;
    margin-top: 16px;
    padding-bottom: 16px;
    font-size: 16px;
    line-height: 19px;
    
    @media screen and (min-width: 1024px) {
      margin-top: 24px;
      padding-bottom: 24px;
      font-size: 24px;
      line-height: 27px;
      
    }
  }
`;
