import React from "react";
import SbEditable from "storyblok-react";
import { render } from "storyblok-rich-text-react-renderer";
import Container from "../../Common/Container";
import Accordion from "../components/Accordion";
import TitleSection from "../components/TitleSection";
import { AccordionListWrapper, Background, Subtitle } from "./style";


export default function Accordions(props) {
  return (
    <SbEditable content={props.content}>
      <Background>
        <Container>
          <TitleSection>
            {props.content.title}
          </TitleSection>

          <Subtitle>
            {render(props.content.subtitle)}
          </Subtitle>

          <AccordionListWrapper>
            {props.content.elements.map((item) => (
              <Accordion title={item.title} key={item._uid}>
                {render(item.text)}
              </Accordion>
            ))}
          </AccordionListWrapper>
        </Container>
      </Background>
    </SbEditable>
  )
}