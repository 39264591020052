import styled from "styled-components";
import colors from "../../../styles/colors";

export const SidebarContainer = styled.div`
  background-color: ${colors.persian_indigo};
  height: auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media(min-width: 1024px) {
    align-items: flex-start;
    margin-top: -2.4rem;
  }

`

export const SidebarContainerInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media(min-width: 1024px) {
    max-width: none;
    height: auto;
    padding-left: 2rem;
    padding-top: 5.688rem;
    margin-top: -4rem;
  }
`
