
import React from "react";
import { AccordionContentWrapper } from "./styles";

export default function Content({ children, open }) {
  return (
    <AccordionContentWrapper open={open}>
      {children}
    </AccordionContentWrapper>
  )
}