import Colors from "../../../styles/colors";
import styled from "styled-components";
import iconDropdown from "../../../assets/images/icon-dropdown.png";

export const SubscribeContainer = styled.section`
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  width: auto;
  background: rgb(244, 244, 244);
  padding: 46px 0;
  justify-content: center;
  margin-top: 30px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const InputsArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const Label = styled.label`
  color: black;
  font-weight: 700;
  padding: 0 0 5px 10px;
`;

export const Select = styled.select`
  width: 300px;
  height: 50px;
  margin-bottom: 30px;
  border: 1px solid #d3d3d3;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(${iconDropdown}) no-repeat right #fff;
  background-origin: content-box;
  background-color: #f9fafb;
  font-size: 1rem;
  padding: 0 15px;

  &:focus {
    outline: none;
  }
`;

export const SelectItem = styled.option`
  color: black;
`;

export const ButtonArea = styled.div``;

export const Button = styled.button`
  width: 300px;
  height: 60px;
  background: ${Colors.red};
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  transition: all 0.5s;

  &:hover {
    background-color: ${Colors.darkred};
  }
`;

export const Icon = styled.img`
  margin-left: 10px;
  transition: all 0.7s;
`;
