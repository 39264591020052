import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import SbEditable from "storyblok-react";
import { render } from "storyblok-rich-text-react-renderer";
import Container from '../../Common/Container';
import TitleSection from "../components/TitleSection";
import { Background, Card, CardContainer, CardInfoWrapper, Description, Divider, DividerText, DividerWrapper, Price, SubscribeWrapper, Text, WhatsappIconWrapper } from "./styles";


export default function Subscribe4d(props) {
  return (
    <SbEditable content={props.content}>
      <Background>
        <Container>
          <TitleSection>{props.content.title}</TitleSection>

          <SubscribeWrapper>
            <CardContainer>
              <Card>
                <CardInfoWrapper>
                  <Text>
                    {props.content.left_price_heading}
                  </Text>

                  <Price>{render(props.content.left_value)}</Price>
                </CardInfoWrapper>

                <DividerWrapper>
                  <Divider variant="vertical" />
                  <DividerText>OU</DividerText>
                  <Divider variant="vertical" />
                </DividerWrapper>

                <CardInfoWrapper>
                  <Text>
                    {props.content.right_price_heading}
                  </Text>

                  <Price>{render(props.content.right_value)}</Price>
                </CardInfoWrapper>

                <WhatsappIconWrapper href={props.content.whatsapp_link} target="_blank" rel="noopener noreferrer">
                  <BsWhatsapp fontSize={32} color="white" />
                </WhatsappIconWrapper>
              </Card>
              <Divider variant="horizontal" />
              <Description>{props.content.description}</Description>
            </CardContainer>
          </SubscribeWrapper>
        </Container>
      </Background>
    </SbEditable>
  )
}