import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { getUtmParams } from "../../../helpers/formUtms";
import { validateSubscription } from "../../../helpers/formValidations";
import Form from "../Components/HubspotForm/index";
import { CloseButton, ModalContainer } from "../Components/Modal/styles";
import { ModalContent } from "./styles";


export default function SignUpModal({ isOpen, onRefuse }) {
  let validSubscription;

  return (
    <ModalContainer isOpen={isOpen}>
      <ModalContent>
        <CloseButton onClick={onRefuse}>
          <AiOutlineCloseCircle />
        </CloseButton>

        <Form
          region='na1'
          portalId="6064046"
          formId="419f0b8e-28e1-434b-bd5a-93b5519fa043"
          target='#hubspotForm'
          onFormSubmit={() => {
            console.log('onFormSubmit started');

            var url = new URL(window.location.href);
            var newForm;
            var orbitaFormFields = ["fullname", "email", "cellphone",
              "allowChangeCampus"];
            orbitaFormFields.push("course");
            orbitaFormFields.push("pole");
            orbitaFormFields.push("skipStepChoseCourse");

            newForm = document.createElement('form');
            newForm.action = "https://4d.pucpr.br/orbita/subscription/?education_level=GRD";
            newForm.method = "POST";
            newForm.enctype = "application/x-www-form-urlencoded";
            newForm.setAttribute("class", "orbita-form");
            newForm.setAttribute("style", "display: none;");

            if (typeof getUtmParams == 'function') {
              newForm.action += getUtmParams(url);
            } else {
              console.log("getUtmParams is " + typeof getUtmParams);
            };

            var newField;

            orbitaFormFields.forEach(field => {
              newField = document.createElement('input');
              newField.name = field;
              newField.type = "hidden";
              newForm.appendChild(newField);
            });
            document.querySelector('body').appendChild(newForm);

            document.querySelector('.orbita-form input[name="fullname"]').value =
              document.querySelector('.hs-form input[name="firstname"]').value + ' ' +
              document.querySelector('.hs-form input[name="lastname"]').value;
            document.querySelector('.orbita-form input[name="email"]').value =
              document.querySelector('.hs-form input[name="email"]').value;
            document.querySelector('.orbita-form input[name="cellphone"]').value =
              document.querySelector('.hs-form input[name="phone"]').value;
            document.querySelector('.orbita-form input[name="allowChangeCampus"]').value = true;
            document.querySelector('.orbita-form input[name="course"]').value =
              "111_1-178";
            document.querySelector('.orbita-form input[name="pole"]').value =
              "111_1-01";
            document.querySelector('.orbita-form input[name="skipStepChoseCourse"]').value = false;

            // If the "validateSubscription" function is null set the variable as validSubscription = true
            if (typeof validateSubscription == 'function') {
              validSubscription = validateSubscription('.hspt-form');// Get the "form-extra-validations.js" file to validate the Phone and Education Level fields
            } else {
              console.log('validateSubscription() is ' + validSubscription);
              validSubscription = true;
            };

            console.log('onFormSubmit finished');
          }}
          onFormSubmitted={() => {
            console.log('onFormSubmitted started');

            document.querySelector('.orbita-form input[name="email"]').type =
              "email";
            //
            // If the "validSubscription" is true submit the external and the Hubspot form
            if (validSubscription) {
              document.querySelector('.orbita-form').submit();
            };
            console.log('onFormSubmitted finished');
          }}
        />

      </ModalContent>
    </ModalContainer>
  )
}
