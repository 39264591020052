import React from 'react'
import '../styles/global.css'
import Accordions from './4d/Accordions'
import CourseInfo from './4d/CourseInfo'
import Courses from './4d/Courses'
import DownloadStudyTrail from './4d/DownloadStudyTrail'
import Footer4d from './4d/Footer'
import Hero_4d from './4d/Hero/Hero'
import HowToJoin from './4d/HowToJoin'
import OneMoreThing from './4d/OneMoreThing'
import ProfessionalFuture from './4d/ProfessionalFuture'
import Professionals from './4d/Professionals'
import StudyTrail from './4d/StudyTrail'
import Subscribe4d from './4d/Subscribe'
import TextBar from './4d/TextBar'
import Video from './4d/Video'
import Footer from './Common/Footer/Footer'
import Header from './Common/Header/Header'
import Menu from './Common/Menu/Menu'
import ContactSecion from './Contact/ContactSection/ContactSection'
import AccordionList from './Course/AccordionList'
import Collection from './Course/Collection/Collection'
import CourseGuide from './Course/CourseGuide'
import CourseRecommendation from './Course/CourseRecommendation'
import GeneralInfoContainer from './Course/GeneralInfoContainer'
import IndividualCourse from './Course/IndividualCourse'
import CourseSecureDownload from './Course/SecureDownload/SecureDownload'
import SideItems from './Course/SideItems'
import DigitalEconomy from './DigitalEconomy'
import FaqSecureDownload from './FAQ/SecureDownload/SecureDownload'
import TopicItemContent from './FAQ/TopicItemContent'
import TopicsContainer from './FAQ/TopicsContainer'
import Grid from './Grid/Grid'
import Enroll from './Home/Enroll/Enroll'
import FeaturingCourses from './Home/FeaturingCourses/FeaturingCourses'
import Financing from './Home/Financing/Financing'
import Graduation4D from './Home/Graduation4D'
import Guidance from './Home/Guidance/Guidance'
import GuidanceAccordion from './Home/GuidanceAccordion/index'
import Hero from './Home/Hero/Hero'
import Multiversity from './Home/Multiversity'
import Subscribe from './Home/Subscribe/Subscribe'
import Page from './Page'

const Components = {
  'page': Page,
  'grid': Grid,
  'menu': Menu,
  'hero': Hero,
  'header': Header,
  'featuring-courses': FeaturingCourses,
  'subscribe': Subscribe,
  'guidance': Guidance,
  'guidance-accordion': GuidanceAccordion,
  'enroll': Enroll,
  'financing': Financing,
  'footer': Footer,
  'faq_topics': TopicsContainer,
  'faq_items': TopicItemContent,
  'course_collection': Collection,
  'course_content': IndividualCourse,
  'course_modality': GeneralInfoContainer,
  'course_recommendation': CourseRecommendation,
  'course_secure_download': CourseSecureDownload,
  'faq_secure_download': FaqSecureDownload,
  'contact_section': ContactSecion,
  'digital-influency': DigitalEconomy,
  'graduacao_4d': Graduation4D,
  'hero_4d': Hero_4d,
  'color_text_bar_4d': TextBar,
  'text_video_section_4d': Video,
  'text_blocks_4d': CourseInfo,
  'accordions_4d': Accordions,
  'carousel_section_4d': Courses,
  'image_section_4d': StudyTrail,
  'color_text_button_bar_4d': DownloadStudyTrail,
  'image_text_section_4d': OneMoreThing,
  'card_blocks_4d': HowToJoin,
  'subscribe_section_4d': Subscribe4d,
  'footer_4d': Footer4d,
  'solid_background_text_section_4d': ProfessionalFuture,
  'course_side_items': SideItems,
  'course_guide': CourseGuide,
  'horizontal_accordion': AccordionList,
  'professionals_carousel': Professionals,
  'multiversity': Multiversity,
}

export default (blok) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const parameters = {
      key: blok._uid,
      content: blok,
      context: blok.context,
      setGridState: blok.setGridState,
      getGridState: blok.getGridState,
      setPageState: blok.setPageState,
      getPageState: blok.getPageState,
    }

    return React.createElement(Components[blok.component], parameters)
  }

  console.log(`The component ${blok.component} has not been created yet.`)
}
