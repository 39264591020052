import styled from "styled-components";
import { default as Colors, default as colors } from "../../../styles/colors";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const InnerContainer = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: stretch;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Financing = styled.section`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px 26px;
  position: relative;
  background-color: ${colors.lightpurple};
  color: ${colors.white};

  @media (min-width: 1024px) {
    background-image: url(${({ src }) => src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 50%;
    max-width: inherit;
    color: ${colors.black};
  }
`;

export const ContainerFinancing = styled.div`
  max-width: 375px;
`;

export const FAQBack = styled(Financing)`
  padding: 40px 40px 31px;
  position: relative;
  background-color: ${colors.lightpurple};
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    max-width: inherit;
    width: 50%;
  }
`;

export const FAQ = styled(Financing)`
  max-width: 375px;
  background: transparent;
  padding: 35px 10px;
  align-items: flex-start;

  @media (min-width: 1024px) {
    max-width: inherit;
    width: 380px;
  }
`;

export const TitleFinancing = styled.h2`
  font-size: 32px;
  line-height: 36px;
  font-weight: 400;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    font-size: 42px;
    line-height: 47px;
  }
`;

export const FeaturedTitleFinancing = styled.span`
  font-weight: 700;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    margin-bottom: 67px;
  }
`;

export const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 35px;

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 75px;
  }
`;

export const TitleFAQ = styled.h2`
  width: 215px;
  font-size: 32px;
  line-height: 32px;
  font-weight: 400;
  color: white;
  text-align: left;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    font-size: 42px;
    line-height: 48px;
    margin-bottom: 24px;
  }
`;

export const FeaturedTitleFAQ = styled.span`
  font-size: 72px;
  line-height: 70px;
  color: white;
  font-weight: 800;

  @media (min-width: 1024px) {
    font-size: 96px;
    line-height: 90px;
  }
`;

export const DescriptionFAQ = styled(Description)`
  width: 211px;
  margin-top: 12px;
  color: white;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 72px;

  @media (min-width: 1024px) {
    margin-bottom: 46px;
  }
`;

export const FeaturedDescription = styled.span`
  font-weight: 700;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
`;

export const ButtonFinancing = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    transition: all 0.5s;
  }

  &:hover span {
    text-decoration: underline;
  }
`;

export const ButtonFAQ = styled(ButtonFinancing)`
  max-width: 300px;
  width: 100%;
  height: 60px;
  color: ${Colors.lightpurple};
  background-color: ${Colors.white};
  font-weight: 700;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 32px;
  transition: all 0.5s;

  &:hover {
    letter-spacing: 0.7px;
  }

  @media (min-width: 1024px) {
    width: 180px;
  }
`;

export const ButtonTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  transition: all 0.5s;

  span {
    font-size: 24px;
    font-weight: 700;
    line-height: 27px;
  }
`;

export const Icon = styled.img`
  margin-left: 10px;
`;

export const Divider = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    height: 29px;
    width: 100%;
    background-color: #f0c0cc;
  }
`;
