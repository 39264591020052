import styled from "styled-components";

export const InfoList = styled.ul`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding: 15px;

  ::-webkit-scrollbar {
    width: 0px;
  }

  @media (min-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const ListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-right: 1px dashed #fff8;
  font-size: 14px;

  @media (min-width: 1024px) {
    width: 23.438rem;
    border-bottom: 1px dashed #fff8;
    border-right: none;
    padding: 20px 0;
    font-size: 16px;
  }
`;

export const Icon = styled.img`
  width: 30px;
  height: auto;
`;

export const ItemsContent = styled.div`
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  width: 180px;

  @media (min-width: 1024px) {
    width: 100%;
  }
`;

export const NameItem = styled.span`
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 20.52px;
`;

export const Description = styled.div`
  color: white;
  line-height: 20.8px;

  a {
    text-decoration: underline;
  }

  i {
    font-size: 12px;
    line-height: 15.6px;
  }

  &.styledMonthlyPayment {
    margin-left: 20px;
  }
`;

export const Arrow = styled.img`
  margin-left: 10px;
`;

export const MoreDetails = styled.a`
  color: white;
  text-decoration: underline;
  display: block;
  cursor: pointer;
  font-size: 16px;
`; 
