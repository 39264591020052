import styled from "styled-components";
import colors from "../../../styles/colors";

export const Background = styled.section`
 background-color: ${colors.brightgray};
  padding: 40px 0;

  @media screen and (min-width: 1024px) {
    padding: 80px 0;
  }
`;

export const SubTitle = styled.p`
  margin: 16px 0 24px;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  
  @media screen and (min-width: 1024px) {
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
  }
`;