import { Hr, Title, Wrapper } from "./styles";
import React from "react";

const SectionTitle = ({ children, bgColor }) => (
  <Wrapper>
    <Hr />
    <Title bgColor={bgColor}>
      {children}
    </Title>
  </Wrapper>
)

export default SectionTitle;