export function getUtmParams(url) {
  var utmParams = "";
  var utms = [
    {
      lpParamName: "utm_source",
      orbitaParamName: "source",
      value: "not_set"
    }, {
      lpParamName: "utm_medium",
      orbitaParamName: "medium",
      value: "not_set"
    }, {
      lpParamName: "utm_campaign",
      orbitaParamName: "campaign",
      value: "not_set"
    },
    {
      lpParamName: "utm_content",
      orbitaParamName: "content",
      value: "not_set"
    },
    {
      lpParamName: "utm_term",
      orbitaParamName: "term",
      value: "not_set"
    },
    {
      lpParamName: "utm_keyword",
      orbitaParamName: "keyword",
      value: "not_set"
    },
    {
      lpParamName: "utm_id",
      orbitaParamName: "campaign_code",
      value: "not_set"
    }
  ];

  for (var i = 0; i < utms.length; i++) {
    var utm_value = url.searchParams.get(utms[i].lpParamName);
    if (utm_value)
      utms[i].value = utm_value;
    else
      utms[i].value = "not_set";
  };

  for (var j = 0; j < utms.length; j++) {
    utmParams += "&" + utms[j].orbitaParamName + "=" + utms[j].value;
  };
  return utmParams;
};