import styled from "styled-components";


export const SliderContainer = styled.div`
  padding: 0 50px 40px;
  max-width: 1500px;
  margin: 0 auto;

  .slick-list {
    padding-inline: 20px;
  }

  .slick-dots li button:before {
    font-size: 20px;
  }

  .slick-next:before, .slick-prev:before {
    color: #a3a3a3;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const SliderItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  img {
    width: 100%;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  padding-top: 44px;
`;

export const Title = styled.h2`
  text-align: center;
  letter-spacing: 4px;
  font-weight: bolder;
  line-height: 40px;
  font-size: 20px;
`;

export const Button = styled.a`
  background-color: #FF0040;
  color: white;
  border: none;
  border-radius: 30px;
  display: flex;
  font-weight: 600;
  font-size: 1rem;
  padding: 15px 30px;
  transition: all 0.15s linear;

  :hover {
    filter: contrast(2);
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    width: 100%;
  }
`;
