import styled from "styled-components";

export const Banner = styled.section`
  background-image: linear-gradient(0,rgba(11,11,17,1) 3%,rgba(11,11,17,0.2) 100%), url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: inherit;
  padding: 60px 0;
  max-width: 100%;
  height: 100%;
  margin-bottom: 1px;

  @media screen and (max-width: 768px) {
    background-position: center;
  }

  > div {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-items: flex-start;

    @media screen and (max-width: 768px) {
      padding: 40px 20px;

    }

    h1 {
      font-size: 45px;
      font-weight: bold;

      @media screen and (max-width: 768px) {
        font-size: 32px;
      }
    }

    > div {
      display: flex;
      align-items: center;
      gap: 12px;

      padding-top: 35px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 30px;
      }
    }
  }
`;

export const Text = styled.p`
  font-size: 40px;
  font-weight: 800;
  text-align: center;
  padding: 32px 0;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;
