import React from 'react';
import SbEditable from 'storyblok-react';
import { render } from 'storyblok-rich-text-react-renderer';
import Container from '../../Common/Container';
import { Background, Text, Title } from './styles';

export default function ProfessionalFuture(props) {
  return (
    <SbEditable content={props.content}>
      <Background color={props.content.background}>
        <Container>
          <Title>{props.content.title}</Title>

          <Text>{render(props.content.text)}</Text>
        </Container>
      </Background>
    </SbEditable>
  )
}