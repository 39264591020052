import styled from "styled-components";
import colors from "../../../styles/colors";

export const Background = styled.section`
  background-color: ${colors.brightgray};
  padding: 40px 0;

  @media screen and (min-width: 1024px) {
    padding: 80px 0;
  }
`;

export const Subtitle = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  margin-top: 16px;

  @media screen and (min-width: 1024px) {
    font-size: 24px;
    line-height: 27.36px;
  }

`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
  width: 100%;
  
  @media screen and (min-width: 1024px) {
    justify-content: center;
    gap: 90px;
    flex-direction: row;
  }
`;

export const Paragraph = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;

  @media screen and (min-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
    text-align: justify;
    max-width: 575px;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 484px;
  width: 100%;
  margin: 0 auto;
`;

