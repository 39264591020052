import styled from "styled-components";
import { default as Colors, default as colors } from "../../../styles/colors";

export const Container = styled.div`
  display: flex;
  gap: 1.16rem;
  width: 100%;
  align-items: center;
  margin-bottom: 38px;
  flex-direction: column;
  max-width: 1237px;
  justify-content: flex-end;
  
  @media (min-width: 1237px) {
    flex-direction: row;
  }

`;

export const AsideKnowMore = styled.div`
  width: 100%;
  min-height: 160px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 9.5rem;
  padding: 20px 5px;
  min-width: 261px;
  max-width: 980px;

  h3 {
    max-width: 375px;
    width: 100%;
    text-align: left;
    padding: 0 5px;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
    letter-spacing: 0.3px;
    font-weight: 500;
  }

  @media (min-width: 1024px) {
    width: 100%;
    justify-content: center;
    margin-top: 38px;
    padding: 20px 31px;
  }
`;

export const Icon = styled.img`
  margin-left: 10px;
  color: white;
  width: 16px;
  height: 12px;
`

export const ButtonKnowMore = styled.a`
  min-width: 180px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin: 0 22px 0 5px;
  background-color: ${Colors.darkgrey};
  border: none;

  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;

  @media (min-width: 1024px) {
    max-width: 30%;
  }
`;

export const ModalitiesContainer = styled.div`
  width: 100%;
  max-height: 160px;
  min-height: ${(props) => (props.isFilter ? "160px" : " 0")};
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: ${(props) => (props.isFilter ? "20px 5px" : " 0")};

  h3 {
    width: 100%;
    text-align: left;
    padding: 0 5px;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
    letter-spacing: 0.3px;
    font-weight: 500;
  }

  @media (max-width: 576px) {
    min-width: 100vw;
  }

  @media (min-width: 1024px) {
    ${(props) => (props.isFilter ? "margin: 0 auto;" : "")}
    width: 100%;
    justify-content: center;
    max-width: ${(props) => (props.isFilter ? "980px" : "780px")};
    padding: ${(props) => (props.isFilter ? "20px 40px 20px 70px" : "0")};
    align-items: ${(props) => (props.isFilter ? "flex-start" : "auto")};
    margin-top: ${(props) => (props.isFilter ? "38px" : "0")};
  }

  @media (min-width: 1237px) {
    max-width: ${(props) => (props.isFilter ? "1000px" : "780px")};
  }
`;

export const ModalitiesInner = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  gap: 15px;
  overflow-x: auto;
  padding: ${(props) => (props.isFilter ? "0 5px" : " 40px 5px")};
  padding-bottom: 15px;

  @media (min-width: 1024px) {
    padding: ${(props) => (props.isFilter ? " 10px 0 0 0" : "10px 0 20px 0")};
    overflow-x: visible;
    max-width: none;
    min-width: 840px;
    flex-wrap: wrap;
    width: 100%;
    max-width: ${(props) => (props.isFilter ? "inherit" : "600px")};
  }
`;

export const Modality = styled.span`
  min-width: 180px;
  height: 40px;
  background-color: ${colors.persian_indigo};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  @media (min-width: 1024px) {
    max-width: 30%;
  }

  &:hover {
    opacity: 0.8;
  }
  
`;

export const Name = styled.p`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  max-width: 715px;
  text-align: center;
  color: white;
`;

export const IconModality = styled.img`
  width: 24px;
  height: 24px;
`;

export const Description = styled.p`
  line-height: 20.8px;
`;

export const ModalityDisabled = styled(Modality)`
  background-color: ${colors.darkred};


  &#disabled-novo {
    cursor: none;
    background: ${Colors.red};
  }
  &:hover {
    opacity: 0.8;
  }
`;

export const NameDisabled = styled(Name)`
  color: white;
`;
