import React from "react";
import SbEditable from "storyblok-react";

import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import purpleArrow from "../../../assets/images/purple-arrow.png";

import Card from "../CardCourses";

import {
  Button,
  ButtonValue,
  Container,
  CoursesContainer,
  FeaturingCourses,
  Icon,
  IntroContainer,
  Title,
} from "./style";

const query = gql`
  {
    CourseItems(per_page: 20, with_tag: "Destaque", sort_by: "updated_at:DESC") {
      items {
        full_slug
        id
        name
        content {
          thumbnail {
            filename
            alt
            title
          }
        }
      }
    }
  }
`;

export default (props) => (
  <SbEditable content={props.content}>
    <FeaturingCourses className="FeaturingCourses">
      <Container>
        <IntroContainer>
          <Title>
            CURSOS DE GRADUAÇÃO EM <span>DESTAQUE</span>
          </Title>
          <Button
            onClick={() =>
              console.log('Dispatching event to Google Analytics') || window.ga("send", {
                hitType: "event",
                eventCategory: "Cursos > Saiba Mais (Todos os cursos)",
                eventAction: "Clique",
              })
            }
            href="/cursos"
          >
            <ButtonValue>
              Confira todos <br />
              os cursos
            </ButtonValue>
            <Icon src={purpleArrow} />
          </Button>
        </IntroContainer>

        <CoursesContainer>
          <Query query={query}>
            {(result) => {
              if (result.loading) return null;
              if (result.error)
                return <p>Falha ao carregar os cursos em destaque</p>;
              return (
                <>
                  {result.data.CourseItems.items.map((course) => (
                    <Card
                      key={course.full_slug}
                      title={course.name}
                      full_slug={course.full_slug}
                      src={course.content.thumbnail.filename}
                      tag="Presencial"
                    />
                  ))}
                </>
              );
            }}
          </Query>
        </CoursesContainer>
      </Container>
    </FeaturingCourses>
  </SbEditable>
);
