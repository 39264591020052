import styled from "styled-components";

export const ContactCardWrapper = styled.div`
    width: 100%;
    margin: 38px auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1024px) {
        max-width: 1260px;
        display: block;
    }
`

export const ContactCard = styled.div`
    width: 100%;
    height: auto;
    background: #EAEAEA;
    margin: 0 5px 10px;
    padding: 20px 10px;
    width: 100%;
    max-width: 350px;
    box-sizing: border-box;
    overflow-y: auto;
    display: inline-block;

    ::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: 1024px) {
        width: 32.2%;
        height: 348px;
        margin: 0 .56% 10px;
        padding: 70px 16px 32px;
        max-width: none;
    }
`
export const ContactCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-items: flex-start;
    
    height: 220px;

    overflow-y: auto;

    ::-webkit-scrollbar {
        display: none;
    }
`
// export const ContactLargeCard = styled.div`
//     width: calc(100% - 25px);
//     height: 348px;
//     background: #EAEAEA;
//     position: relative;
//     float: left;
//     margin: 0 5px 10px;
//     padding: 32px 16px;
//     box-sizing: border-box;
//     overflow-y: auto;

//     ::-webkit-scrollbar {
//         display: none;
//     }
// `

export const ContactUnitName = styled.h2`
   text-transform: uppercase;
   font-size: 32px;
   line-height: 36px;
   letter-spacing: 0.3px;
   font-weight: 400;
   margin-bottom: 32px;
   padding-left: 4px;
`

export const ContactCardTitle = styled.h3`
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.3px;
    font-weight: 800;
`