import React from "react";
import Container from "../../Common/Container";
import { faqQuestions } from "../accordionsText";
import Section from "../Components/Section";
import SectionTitle from "../Components/SectionTitle";
import ListAccordion from "../ListAccordion";

export default function Faq() {
  return (
    <Section bgColor="grey">
      <Container>
        <SectionTitle bgColor="grey">
          Perguntas que nos fazem
        </SectionTitle>
        <ListAccordion items={faqQuestions} outlined alingCenter />
      </Container>
    </Section>

  )
}
