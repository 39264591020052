import styled from "styled-components";
import Colors from "../../../styles/colors";

export const CardPucprContainer = styled.div`
  background: #fff;
  width: 350px;
  margin: 0 7px;
  position: relative;

  @media (min-width: 1024px) {
    width: 300px;
  }
`;

export const CardPucprBody = styled.div`
  display: flex;
  margin: auto;
  margin-bottom: 16px;
  align-items: center;

  @media (min-width: 1024px) {
    margin-bottom: 32px;
  }
`;

export const Cardpucpr = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: column;
  padding: 16px;
  position: static;
  width: 350px;
  height: 350px;
  position: relative;
  background: #eaeaea;

  @media (min-width: 1024px) {
    width: 296px;

    :hover img {
      transform: scale(1.2);
    }
  }
`;

export const CardModalitiesInner = styled.div`
  margin-top: 8px;
  display: flex;
  overflow-x: auto;

  ::-webkit-scrollbar {
    background-color: #fff;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    -webkit-border-radius: 10px;
  }
`;

export const Modality = styled.div`
  position: relative;
  float: left;
  min-width: 83px;
  height: 28px;
  padding: 0 5px;
  background: ${Colors.darkpurple};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 9px;
  letter-spacing: 0.3px;
  font-weight: 500;
  color: white;
  margin-right: 9px;
  cursor: pointer;

  &:hover {
    background-color: #3F00A3;
    transition: all 0.7s ease;
  }

  @media (min-width: 1024px) {
    margin-right: 4px;
    margin-bottom: 10px;
  }
`;

export const CardModalities = styled.div`
  margin-top: 15px;
`;

export const CardModalityTitle = styled.span`
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.3px;
  color: #686868;
`;

export const CardImageWrapper = styled.div`
  height: 130px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 5px;
  position: relative;
  cursor: pointer;

  img {
    transition: all 0.5s;
  }
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  margin-top: 8px;
  color: #000000;
  max-height: 41px;
  overflow: hidden;
  height: 41px;
`;

export const CardButton = styled.a`
  position: absolute;
  bottom: 20px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: none;

  height: 40px;
  background: ${Colors.red};
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  transition: all 0.5s;
  width: 320px;

  @media (min-width: 1024px) {
    width: 264px;
  }

  img {
    margin-left: 8px;
    transform: scale(1) !important;
  }

  &:hover {
    background-color: #FF1F57;
  }
`;

export const NewTag = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: ${Colors.red};
  color: #fff;
  font-size: 9px;
  padding: 9px 26px;
  z-index: 3;
`;
