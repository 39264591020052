import styled from "styled-components";

export const VideoWrapper = styled.div`
  max-width: 515px;
  width: 100%;
  margin: 0 auto;
`;

export const Video = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;