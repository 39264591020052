import { clearphoneMaskBR } from "./formFieldMask";

/* --- FORM EXTRA VALIDATIONS ---
Load it at the end of the page to ensure that the form has been loaded already when it
runs.
*/
var educationLevelFieldHS;
var educationLevelFieldGRD;
var phoneField;
var validEducationLevel;
var validPhone;
//
export function validateSubscription() {
  educationLevelFieldHS = document.querySelector(`.hbspt-form input[name="voc_j_concluiu_o_ensino_m_dio_"][value="Sim"]`);
  educationLevelFieldGRD = document.querySelector(`.hbspt-form select[name="voce_ja_tem_graduacao_completa_"][value="Sim"]`);
  phoneField = document.querySelector(`.hbspt-form input[name="phone"]`);
  validEducationLevel = false;
  validPhone = false;
  if (educationLevelFieldHS != null && typeof educationLevelFieldHS === 'object') {
    /*  Validate High School  */
    if (educationLevelFieldHS.checked) { validEducationLevel = true; };/*
*/} else if (educationLevelFieldGRD != null && typeof educationLevelFieldGRD ===
    'object') {
    /*  Validate Underdegree  */
    if (educationLevelFieldGRD.value === "Sim") { validEducationLevel = true; };
  } else {
    console.log('No education level fields were detected.');
    validEducationLevel = true;
  };
  if (phoneField != null && typeof phoneField === 'object') {
    /* Validate phone */
    clearphoneMaskBR(phoneField);
    if (
      phoneField.value.length >= phoneField.minLength && phoneField.value.length <=
      phoneField.maxLength
    ) {
      validPhone = true;
    };
  } else {
    console.log('No phone field was detected.');
    validPhone = true;
  };
  if (validEducationLevel && validPhone) { return true; } else { return false; };
};