import styled from "styled-components";

export const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  h3 {
    text-align: center;
    font-size: 21px;
    font-weight: 500;
    line-height: 40px;
    color: #020203;
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  gap: 80px;
  justify-content: space-around;
  margin: 50px auto;
  max-width: 1140px;
  padding-inline: 15px;
  width: 100%;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: space-between;  
  }
`;

export const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 358px;
  min-width: 280px;
  @media screen and (max-width: 1024px) {
    padding: 15px;    
  }
`;

export const StepItemTitle = styled.div`
  font-size: 20px;
  font-weight: bold;

  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0;
  width: 100%;

  ::after {
    content: '-----';
    color: #FF0141;
  }
`;

export const StepItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;

  font-size: 16px;

  p {
    line-height: 1;
  }
`;

export const ButtonDownload = styled.a`
  background-color: #FF0141;
  color: white;
  border: none;
  border-radius: 30px;
  display: flex;
  font-weight: 700;
  font-size: 1rem;
  padding: 12px 24px;
  justify-content: center;
  width: 100%;
`;
