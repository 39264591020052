import styled from "styled-components";
import { ModalContent as ModalBox } from "../Components/Modal/styles";

export const CouseGuideContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  padding-inline: 15px;

  div {
    max-width: 425px;
    padding: 0 20px;
  }

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }
`;

export const CouseGuideList = styled.ul`
  @media screen and (max-width: 1024px) {
    margin-left: 30px;
  }
`;

export const CouseGuideListItem = styled.li`
  list-style: none;
  text-align: left;
  font-size: 16px;
  margin: 10px 0;
  position: relative;

  list-style-type: none;

  & .list-icon {
    color: red;
    font-weight: 900;
    left: -49px;
    top: 3px;
    position: absolute;
    content: ${props => props.content};;  
  }
`;

export const ModalContent = styled(ModalBox)`
  width: 400px;
  padding: 30px;
  border-radius: 28px;
  background-color: #f2f2f2;

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    border-radius: 0;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 22px;
`;

export const Title = styled.h3`
  color: #ff0040;
  font-size: 1.375rem;
`;
