import styled from "styled-components";
import colors from "../../../styles/colors";

export const Background = styled.section`
  background-color: ${colors.brightgray};
  padding: 40px 0;

  @media screen and (min-width: 1024px) {
    padding: 80px 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  > h3 {
    position: relative;
    z-index: 2;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 27px;

    @media screen and (min-width: 1024px) {
      font-size: 48px;
      line-height: 54.72px;
    }
  }
`;

export const Detail = styled.div`
  position: absolute;
  bottom: -20%;
  right: 10px;
  width: 119px;
  height: 15px;
  background-image: ${({ src }) => `url(${src})`};

  @media screen and (min-width: 1024px) {
    width: 260px;
    height: 40px;
    right: 22%;
  }
`;

export const IconWrapper = styled.div`
  width: 34px;
  height: 34px;
  margin: -16px auto 0;
`;

export const Subtitle = styled.p`
  background-color: ${colors.lightpurple};
  padding: 8px;
  color: white;
  text-align: center;
  font-weight: 700;
  width: fit-content;
  margin: 0 auto;
  font-size: 24px;
  line-height: 27px;

  @media screen and (min-width: 1024px) {
    font-size: 36px;
    line-height: 41px;
  }
`;

export const CarrouselRoot = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
`;

export const CarrouselWrapper = styled.div`
  overflow-x: scroll;
  scroll-behavior: smooth;
  max-width: 1028px;
  display: flex;
  margin: 24px auto 0;
  gap: 16px;

  @media screen and (min-width: 1024px) {
    gap: 48px;
    ::-webkit-scrollbar {
      display: none;
    }
    max-width: 80%;
  }

  ::-webkit-scrollbar {
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ccc;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.red};
    border-radius: 3px;
  }
`;
