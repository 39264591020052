import React from "react";
import Container from "../../Common/Container";
import { motives } from "../accordionsText";
import Section from "../Components/Section";
import SectionTitle from "../Components/SectionTitle";
import ListAccordion from "../ListAccordion";

export default function Motives() {
  return (
    <Section bgColor="white">
      <Container>
        <SectionTitle bgColor="white">
          10 motivos para você fazer este curso
        </SectionTitle>

        <ListAccordion items={motives} alingCenter />
      </Container>
    </Section>
  )
}
