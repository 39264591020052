import React, { useMemo, useRef, useState } from 'react';
import Modal from '../Components/Modal';
import Arrow from './carrouselArrow';
import { CarrouselBanner, CarrouselButton, CarrouselContainer, CarrouselDescription, CarrouselImage, CarrouselInfo, CarrouselItem, CarrouselItems, CarrouselTitle } from './styles';

export default function CarrouselList({ items, buttonText }) {
  const [itemSelected, setItemSelected] = useState(null);
  const [isFirstCarrouselItem, setIsFirstCarrouselItem] = useState(true);
  const [isLastCarrouselItem, setIsLastCarrouselItem] = useState(false);
  const carrousel = useRef(null);

  const isOpen = useMemo(() => !!itemSelected, [itemSelected]);

  const handleCloseModal = () => {
    setItemSelected(null);
  };

  const handleLeftClick = (e) => {
    e.preventDefault();
    const calc = carrousel.current.scrollLeft -= (carrousel.current.offsetWidth - 16);
    if (calc <= 0) {
      setIsFirstCarrouselItem(true);
    }
    setIsLastCarrouselItem(false);
  };

  const handleRightClick = (e) => {
    e.preventDefault();
    const calc = carrousel.current.scrollLeft += (carrousel.current.offsetWidth - 16);
    const maxScroll = carrousel.current.scrollWidth - carrousel.current.offsetWidth;
    if (calc >= maxScroll) {
      setIsLastCarrouselItem(true);
    }

    if (calc > 0) {
      setIsFirstCarrouselItem(false);
    }
  };


  const handleClickCarrouselButton = (item) => {
    setItemSelected(item);
  }


  return (
    <>
      <CarrouselContainer>
        <CarrouselItems ref={carrousel}>
          {items.map(item => (
            <CarrouselItem key={item.image} onClick={() => handleClickCarrouselButton(item)}>
              <CarrouselImage src={item.image} alt={item.name} />
              <CarrouselBanner className='carrousel-background'></CarrouselBanner>
              <CarrouselInfo>
                <CarrouselTitle>{item.name}</CarrouselTitle>
                <CarrouselDescription>{item.ocupation}</CarrouselDescription>
              </CarrouselInfo>
              <CarrouselButton>{buttonText}</CarrouselButton>
            </CarrouselItem>
          ))}
        </CarrouselItems>

        <Arrow directon="left" onCLick={handleLeftClick} disabled={isFirstCarrouselItem} />
        <Arrow directon="right" onCLick={handleRightClick} disabled={isLastCarrouselItem} />
      </CarrouselContainer>

      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={handleCloseModal}
          title={itemSelected.name}
          subtitle={itemSelected.ocupation}
          obs={itemSelected.obs}
        >
          {itemSelected.about}
        </Modal>
      )}
    </>
  )
}