import styled from "styled-components";
import colors from "../../../styles/colors";

export const Background = styled.section`
  background-color: ${colors.brightgray};
  padding: 20px 0px;

  @media screen and (min-width: 1024px) {
    padding: 56px 0px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;
  
  @media screen and (min-width: 1024px) {
    justify-content: space-between;
    gap: 48px;
    padding: 48px 0 24px;
  }
`;

export const Title = styled.h4`
  font-weight: 500;
  text-align: center;
  font-size: 24px;
  line-height: 27px;
  
  @media screen and (min-width: 1024px) {
    font-size: 48px;
    line-height: 54px;
  }
`;

export const SubTitle = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  font-weight: 300;
  margin-top: 16px;

  @media screen and (min-width: 1024px) {
    font-size: 24px;
    line-height: 27px;
    font-weight: 400;
  }
`;

export const Paragraph = styled.div`
  margin-top: 30px;
  max-width: 980px;
  margin: 0 auto;

  font-size: 16px;
  line-height: 19px;
  text-align: center;

  > p :nth-child(1) {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }

  @media screen and (min-width: 1024px) {
    font-size: 24px;
    text-align: left;
    line-height: 28px;

    > p :nth-child(1) {
      font-size: 32px;
      text-align: left;
      line-height: 38px;
    }
  }
`;

export const VideoWrapper = styled.div`
  max-width: 850px;
  margin: 0 auto;
  width: 100%;
`;

export const IframeWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    border-radius: 8px;
  }
`;
