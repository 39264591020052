import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';
import SbEditable from "storyblok-react";
import { Actions, Button, Divider, HeroContainer, InfoContainer, Title, Wrapper } from './style';

export default function Hero_4d(props) {
  return (
    <SbEditable content={props.content}>
      <HeroContainer src={props.content.imagem} srcMobile={props.content.imagem_mobile}>
        <InfoContainer>
          <Wrapper>
            <Title>{props.content.title}</Title>

            <Actions>
              <Button
                color="lightpurple"
                href={props.content.whatsapp_link}
              >
                <BsWhatsapp />
                Tire suas dúvidas
              </Button>

              <Button
                color="red"
                href={props.content.subscribe_link}
              >
                Inscreva-se
              </Button>
            </Actions>
          </Wrapper>
        </InfoContainer>
      </HeroContainer>
      <Divider />
    </SbEditable>
  )
}