import styled from "styled-components";
import colors from "../../../styles/colors";
import { Title as Heading } from "../../4d/components/TitleSection/styles";

export const Background = styled.div`
  background-color: ${colors.brightgray};
`

export const Title = styled(Heading)`
  background-color: white;
  text-transform: none;
`;

export const Content = styled.div`
  padding: 40px 0;
`;