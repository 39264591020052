import styled from "styled-components";
import { ModalContent as Content } from "../../../../DigitalEconomy/Components/Modal/styles";
import { VideoWrapper } from "../../../Video/styles";

export const CarrouselContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    max-width: 900px;
  }
`;

export const CarrouselItem = styled.a`
  border: none;
  position: relative;
  min-width: 280px;
  max-width: 280px;
  height: 344px;
  border-radius: 4px;
  margin: 16px;
  color: #fff;
  cursor: pointer;

  display: flex !important;
  flex-direction: column;
  justify-content: flex-end;

  padding: 180px 15px 0 15px;
  box-shadow: 0 5px 10px #0002;
  background: #FDF2F4;
  overflow: hidden;

  :hover {
    .carrousel-item-youtube-icon {
      display: block;
    }
  }

  .carrousel-item-youtube-icon {
      display: none;
    }
`;

export const CarrouselImage = styled.img`
  float: unset;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  animation: fade-in 400ms;
  max-width: 100%;
`;

export const CarrouselBanner = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(#76717100, #746E6E);
  background-size: 60% 110%;
  background-position: center top;
  transition: ease-in-out 400ms;

  ${CarrouselItem}:hover & {
    background-position: center bottom;
  }
`;

export const CarrouselInfo = styled.div`
  position: relative;
  width: 100%;
  bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CarrouselTitle = styled.h3`
  border-bottom: 1pt solid #ff0040;
  font-weight: 600;
  font-size: 1.2rem;
  padding-bottom: 12px;
  margin-bottom: 12px;
  width: 100%;
  text-align: center;
`;

export const CarrouselDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;

export const CarrouselArrow = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;

  ::before {
    content: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ direction }) => direction === 'left'
    ? 'rotate(90deg)'
    : 'rotate(-90deg)'};
  z-index: 2;

  width: 55px;
  height: 55px;

  @media screen and (max-width: 1023px) {
    display: none!important;
  }

  @media screen and(min-width: 1440px) {
    width: 77px;
    height: 77px;
  }

  @media screen and (min-width: 1024px) { 
    display: ${({ disabled }) => disabled ? "none" : "flex"};
  }
`

export const YoutubeIcon = styled.img`
  width: 100px;
  height: 100px;
`;

export const EmbedWrapper = styled(VideoWrapper)`
  padding: 50px 0 20px;
`;

export const ModalContent = styled(Content)`
  max-width: 980px;
`;
