import React from "react";
import SbEditable from "storyblok-react";
import arrowBig from "../../../assets/images/arrow-big.png";
import backFAQ from "../../../assets/images/back-faq.jpg";
import whiteArrow from "../../../assets/images/white-arrow.png";
import {
  ButtonFinancing,
  ButtonRight,
  ButtonRightTitle,
  ButtonTitle,
  Container,
  Description,
  DescriptionFAQ,
  FAQ,
  FAQBack,
  FeaturedTitleFAQ,
  FeaturedTitleFinancing,
  Financing,
  Icon,
  IconRight,
  InnerContainer,
  TitleFAQ,
  TitleFinancing,
} from "./style";

export default (props) => (
  <SbEditable content={props.content}>
    <Container className="Container" src={backFAQ}>
      <InnerContainer>
        <Financing>
          <TitleFinancing>
            JORNADA DA
            <FeaturedTitleFinancing>MATRÍCULA</FeaturedTitleFinancing>
          </TitleFinancing>

          <Description>{props.content.description_1}</Description>

          <ButtonFinancing
            onClick={() =>
              console.log("Dispatching event to Google Analytics") ||
              window.ga("send", {
                hitType: "event",
                eventCategory: "teste",
                eventAction: "teste",
              })
            }
            href={props.content.button_link_1}
            rel="noopener noreferrer"
          >
            <ButtonTitle>Jornada da Matrícula</ButtonTitle>
            <Icon src={whiteArrow} />
          </ButtonFinancing>
        </Financing>
        <FAQBack>
          <FAQ>
            <TitleFAQ>
              <FeaturedTitleFAQ>{props.content.title_2}</FeaturedTitleFAQ>
              {props.content.subtitle_2}
            </TitleFAQ>

            <DescriptionFAQ>{props.content.description_2}</DescriptionFAQ>

            <ButtonRight
              href={props.content.button_link_2}
              rel="noopener noreferrer"
              onClick={() =>
                console.log("Dispatching event to Google Analytics") ||
                window.ga("send", {
                  hitType: "event",
                  eventCategory: "Bolsas e Financiamento",
                  eventAction: "Clique",
                })
              }
            >
              <ButtonRightTitle>{props.content.button_text_2}</ButtonRightTitle>
              <IconRight src={arrowBig} />
            </ButtonRight>
          </FAQ>
        </FAQBack>
      </InnerContainer>
    </Container>
  </SbEditable>
);
