import React, { useState } from "react";
import SbEditable from "storyblok-react";
import detailImg from "../../../assets/images/detail-subscribe.png";
import backgroundMobile from "../../../assets/images/subscribe-mobile.png";
import background from "../../../assets/images/subscribe.png";
import arrow from "../../../assets/images/white-arrow.png";
import {
  Button,
  Description,
  Detail,
  FeaturedTitle,
  Form,
  Icon,
  InfoContainer,
  Label,
  Select,
  SelectItem,
  SubscribeContainer,
  Title,
} from "./style";

const Subscribe = (props) => {
  const [selectValue, setSelectValue] = useState([]);
  let isDisabled = true;
  const methods = props.content.methods;

  if (selectValue.length > 0 && selectValue !== "Selecione um método") {
    isDisabled = false;
  }

  const handleNextStep = (event) => {
    event.preventDefault();
    window.open(event.target.campus.value, "_blank");
    // window.open(event.target.campus.value + "?" + utmzCookie, "_blank");
  };

  // function getCookie(cookieName) {
  //   const name = cookieName + "=";
  //   const cookieArray = document.cookie.split(";"); //break cookie into array
  //   for (let i = 0; i < cookieArray.length; i++) {
  //     const cookie = cookieArray[i].replace(/^\s+|\s+$/g, ""); //replace all space with '' = delete it
  //     if (cookie.indexOf(name) === 0) {
  //       return cookie.substring(name.length, cookie.length); //
  //     }
  //   }
  //   return null;
  // }

  // const utmzCookie = getCookie("__utmz"); //get GA cookie

  const checkCity = (event) => {
    if (event.target.value === "Selecione uma cidade") {
      const button = document.getElementById("btn");
      button.disabled = true;
    } else {
      const button = document.getElementById("btn");
      button.disabled = false;
    }
  };

  return (
    <SbEditable content={props.content}>
      <SubscribeContainer className="Subscribe" src={background} srcMobile={backgroundMobile}>
        <InfoContainer>
          <Title>Inscreva-se na</Title>
          <FeaturedTitle>PUCPR</FeaturedTitle>

          <Detail src={detailImg} />

          <Description>{props.content.description}</Description>

          <Form onSubmit={handleNextStep}>
            <Label>Método de ingresso</Label>
            <Select
              onChange={(e) => setSelectValue(e.target.value)}
              style={{ color: selectValue.length > 0 ? "black" : "#0207" }}
            >
              <SelectItem value="Selecione um método">
                Selecione um método
              </SelectItem>
              {methods.map((method, index) => {
                return (
                  <SelectItem key={`option-${index}`} value={method.name}>
                    {method.name}
                  </SelectItem>
                );
              })}
            </Select>

            <Label>Campus</Label>
            <Select
              disabled={isDisabled}
              name="campus"
              style={{ color: selectValue.length > 0 ? "black" : "#0207" }}
              onChange={checkCity}
            >
              <SelectItem>Selecione uma cidade</SelectItem>
              {methods.map((option, index) => {
                if (option.name === selectValue) {
                  return methods[index].cities.map((city, index) => {
                    return (
                      <SelectItem key={`city-${index}`} value={city.link.url}>
                        {city.name}
                      </SelectItem>
                    );
                  });
                }
                return true;
              })}
            </Select>

            <Button
              id="btn"
              type="submit"
              onClick={() =>
                console.log("Dispatching event to Google Analytics") ||
                window.ga("send", {
                  hitType: "event",
                  eventCategory: "Método de Ingresso",
                  eventAction: "Clique",
                })
              }
              disabled
            >
              Próximo passo
              <Icon src={arrow} />
            </Button>
          </Form>
        </InfoContainer>
      </SubscribeContainer>
    </SbEditable>
  );
};

export default Subscribe;
