import styled from "styled-components";
import colors from "../../../styles/colors";

export const Background = styled.section`
  background-color: ${colors.lightpurple};
  padding: 26px 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const Title = styled.h4`
  color: ${colors.white};
  font-weight: 700;
  text-align: center;
  font-size: 24px;
  line-height: 27px;

  @media screen and (min-width: 1024px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const ButtonLink = styled.a`
  background-color: ${colors.red};
  padding: 12px;
  color: white;
  margin: 0 auto;

  font-weight: 700;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
`;