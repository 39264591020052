import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"
import { Background, Container, ContentWrapper, SubTitle, Text, Title, TitleWrapper } from "./styles"

export default function SideItems(props) {
  return (
    <SbEditable content={props.content}>
      <Background>
        <Container>
          <TitleWrapper>
            <Title>{props.content.title}</Title>
            <SubTitle>{render(props.content.subtitle)}</SubTitle>
          </TitleWrapper>

          <ContentWrapper>
            {props.content.items.map(item => (
              <Text key={item._uid}>
                {item.text}
              </Text>
            ))}
          </ContentWrapper>
        </Container>
      </Background>
    </SbEditable>
  )
}
