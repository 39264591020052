import styled from "styled-components";
import colors from "../../../styles/colors";

export const Background = styled.section`
  background-color: ${colors.brightgray};
  padding: 40px 0;

  @media screen and (min-width: 1024px) {
    padding: 80px 0;
  }
`;

export const SubscribeWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 32px;

  @media screen and (min-width: 1024px) {
    margin-top: 72px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 35px;
  border-radius: 16px;
  position: relative;
  background-color: ${colors.persian_indigo};

  @media screen and (min-width: 1024px) {
    height: 415px;
    border-radius: 32px;
    padding: 60px;
    justify-content: space-between;
    min-width: 980px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media screen and (min-width: 1024px) {
    justify-content: space-between;
    flex-direction: row;
    gap: 32px;
  }
`;

export const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 1024px) {
    align-self: flex-start;
    padding-bottom: 40px;
  }
`;

export const Text = styled.div`
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  color: ${colors.white};
  text-align: center;

  @media screen and (min-width: 1024px) {
    text-align: left;
    line-height: 27px;
    font-size: 24px;
  }
`;

export const Price = styled.div`
    p {
      font-size: 35px;
      line-height: 40px;
      font-weight: 500;
    }
    p b {
      font-size: 50px;
      line-height: 57px;
      font-weight: 900;
    }
    color: ${colors.white};
    text-align: center;
    
    @media screen and (min-width: 1024px) {
      p {
        font-size: 40px;
        line-height: 46px;
        text-align: left;
        font-weight: 500;
      }
      p b {
        font-size: 64px;
        line-height: 73px;
        text-align: left;
        font-weight: 900;
      }
  }
`;

export const Divider = styled.hr`
  border: 1px solid rgba(240, 240, 240, 0.5);
  width: 100%;
  margin: ${({ variant }) => variant === "horizontal" ? "16px 0" : "none"};

  @media screen and (min-width: 1024px) {
    display: block;
    height: ${({ variant }) => variant === "vertical" ? "100%" : "1px"};
    margin: ${({ variant }) => variant === "horizontal" ? "16px 0" : "0 auto"};
    width: ${({ variant }) => variant === "vertical" ? "1px" : "100%"};
  }
`;

export const DividerText = styled.p`
  padding: 0 16px;
  color: ${colors.white};
  @media screen and (min-width: 1024px) {
    padding: 16px 0;
  }
`;

export const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  
  @media screen and (min-width: 1024px) {
    flex-direction: column;
  }
`;


export const WhatsappIconWrapper = styled.a`
  position: absolute;
  cursor: pointer;
  bottom: -30px;
  right: 0;
  background-color: #2BB23B;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  height: 58px;
  width: 58px;

  @media screen and (min-width: 1024px) {
    right: -40px;
    bottom: -40px;

    height: 80px;
    width: 80px;
  }
`;

export const Description = styled.p`
  font-size: 16px;
  color: ${colors.white};
`;
