import { gql } from "apollo-boost";
import React, { useEffect, useState } from "react";
import { Query } from "react-apollo";
import slugify from "slugify";
import Card from "../Card/Card";
import Modalities from "../Modalities";
import { Listener } from "./style";
const _ = require("lodash");

const query = gql`
  {
    CourseItems(per_page: 100, sort_by: "name:ASC") {
      items {
        full_slug
        tag_list
        id
        content {
          modalities
          redirect
          redirect_url
          thumbnail {
            filename
            alt
            title
          }
        }
        name
        created_at
      }
    }
  }
`;

export default function Home(props) {
  const modalitiesCourses = [
    // "TODAS",
    "PRESENCIAL",
    "EAD",
    // "SEMIPRESENCIAL",
    "INTERNACIONAL",
    "NOVO",
    "4D",
  ];

  const getModalitySlug = (modalityName) => {
    return slugify(modalityName).toLowerCase().trim();
  };

  const slug = window.location.hash.replace("#", "");

  const slugIndex = modalitiesCourses.findIndex(
    (modality) => slug === getModalitySlug(modality)
  );

  const currentIndex = props.getPageState(
    "currentIndex",
    slugIndex !== -1 ? slugIndex : 0
  );

  // const searchModalities = props.getPageState("modalities", null);
  const searchQuery = props.getPageState("searchQuery", null);
  const searchAreas = props.getPageState("areas", []);
  const searchCities = props.getPageState("cities", []);
  const tagQuery = _.concat(searchAreas, searchCities).filter(
    (item) => item !== "Todas"
  );

  if (window.location.hash) {
    window.scrollTo(0, 0);
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 1);
  }

  const currentModality =
    modalitiesCourses[
      currentIndex ? currentIndex : props.getPageState("currentIndex", 0)
    ];

  const [modalityStatus, setModalityStatus] = useState(false);

  useEffect(() => {
    if (currentModality === "NOVO") {
      setModalityStatus(true);
    }

    if (currentModality === "TODAS") {
      setModalityStatus(false);
    }
  }, [currentModality]);

  return (
    <>
      <Listener>
        <Modalities
          isFilter={true}
          setPageState={props.setPageState}
          getPageState={props.getPageState}
        />
        <Query query={query}>
          {(result) => {
            if (result.loading) return <span>Carregando cursos...</span>;
            if (result.error)
              return <span>Ocorreu um erro ao carregar os cursos.</span>;

            let courses = result.data.CourseItems.items;

            if (searchQuery) {
              const searchTerms = searchQuery
                .trim()
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "");

              courses = courses.filter((course) => {
                if (currentModality !== "TODAS") {
                  if (!course.content.modalities) {
                    return false;
                  }

                  let isAnyTrue = false;

                  for (const modality of course.content.modalities) {
                    if (
                      modality.name.trim().toUpperCase() === currentModality
                    ) {
                      isAnyTrue = true;
                    }
                  }

                  if (!isAnyTrue) {
                    return false;
                  }
                }

                if (
                  course.name
                    .trim()
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .indexOf(searchTerms) !== -1
                ) {
                  return true;
                }

                return false;
              });

              if (!courses.length) {
                return (
                  <span>
                    Nenhum curso encontrado para "<b>{searchQuery}</b>".
                  </span>
                );
              }
            } else if (tagQuery.length > 0) {
              courses = courses.filter((course) => {
                if (currentModality !== "TODAS") {
                  if (!course.content.modalities) {
                    return false;
                  }

                  let isAnyTrue = false;

                  for (const modality of course.content.modalities) {
                    if (
                      modality.name.trim().toUpperCase() === currentModality
                    ) {
                      isAnyTrue = true;
                    }
                  }

                  if (!isAnyTrue) {
                    return false;
                  }
                }

                for (const tagTerm of tagQuery) {
                  if (course.tag_list.indexOf(tagTerm) === -1) {
                    return false;
                  }
                }

                return true;
              });

              if (!courses.length) {
                return (
                  <span>
                    Nenhum curso encontrado para a sua busca avançada.
                  </span>
                );
              }
            } else {
              if (currentModality !== "TODAS") {
                courses = courses.filter((course) => {
                  if (!course.content.modalities) {
                    return false;
                  }

                  let isAnyTrue = false;
                  const modalities = course.content.modalities;

                  if (modalityStatus) {
                    for (const modality of modalities) {
                      if (
                        modality.name.trim().toUpperCase() === currentModality
                      ) {
                        isAnyTrue = true;
                      }
                    }
                  }

                  for (const modality of modalities) {
                    if (
                      modality.name.trim().toUpperCase() === currentModality &&
                      !modalityStatus
                    ) {
                      isAnyTrue = true;
                    }
                  }

                  return isAnyTrue;
                });
              }
            }

            if (!courses.length) {
              return <span>Nenhum curso disponível no momento.</span>;
            }

            return (
              <>
                {courses.map((course) => (
                  <Card
                    key={course.full_slug}
                    img={course.content.thumbnail.filename}
                    full_slug={
                      course.content.redirect
                        ? course.content.redirect_url
                        : `/${course.full_slug}`
                    }
                    redirect={course.content.redirect}
                    title={course.name}
                    modalities={_.map(course.content.modalities, "name")}
                  />
                ))}
              </>
            );
          }}
        </Query>
      </Listener>
    </>
  );
}
