import React from "react";

import {
  Card,
  CourseImg,
  CourseImgWrapper,
  CourseTitle,
  CourseFooter,
  CoursePresencial,
  CourseButton,
} from "./style";

export default ({ src, title, tag, full_slug }) => (
  <Card
    href={`/${full_slug}`}
    onClick={() =>
      console.log('Dispatching event to Google Analytics') || window.ga("send", {
        hitType: "event",
        eventCategory: "Cursos",
        eventAction: "Clique",
      })
    }
  >
    <CourseImgWrapper>
      <CourseImg src={src} />
    </CourseImgWrapper>
    <CourseTitle>{title}</CourseTitle>
    <CourseFooter>
      <CoursePresencial>{tag}</CoursePresencial>
      <CourseButton />
    </CourseFooter>
  </Card>
);
