import React from 'react';
import { Button, Carroussel, Container, Description, Detail, GraduaitonContent, LineHeader, ProfessionalCard, ProfessionalGradient, ProfessionalInfo, ProfessionalName, ProfessionalOcupation, Professionals, Section, Title, TitleWrapper } from './styles';

import { Link } from 'react-router-dom';
import backgroundBtn from "../../../assets/images/detail-subscribe.png";
import backgoundImage from '../../../assets/images/graduacao-4d.png';
import arrow from "../../../assets/images/white-arrow.png";
import { professionals } from '../../DigitalEconomy/MarketProfessionals/professionals';

export default function Graduation4D() {
  return (
    <>
      <LineHeader />
      <Section src={backgoundImage}>
        <Container>
          <GraduaitonContent>
            <TitleWrapper>
              <Title>conheça a <strong>Graduação</strong></Title>
              <Title strong>
                <span>
                  4D
                </span>
                <Detail src={backgroundBtn} />
              </Title>
            </TitleWrapper>
            <Description>
              E tenha aulas com quem é referência no assunto. O curso
              de Economia da Influência digital
              que já era tendência, agora virou Graduação completa.
            </Description>
            <Link to="/economia-da-influencia-digital">
              <Button src={backgroundBtn}>
                Conferir Curso
                <img src={arrow} alt="ícone de seta" />
              </Button>
            </Link>
          </GraduaitonContent>

          <Carroussel>
            <Professionals>
              {professionals.map((professional) => (
                <ProfessionalCard key={professional.name} src={professional.image}>
                  <ProfessionalInfo>
                    <ProfessionalName>{professional.name}</ProfessionalName>
                    <ProfessionalOcupation>{professional.ocupation}</ProfessionalOcupation>
                  </ProfessionalInfo>
                  <ProfessionalGradient />
                </ProfessionalCard>
              ))}
            </Professionals>
          </Carroussel>

        </Container>
      </Section>
    </>
  )
}