import React from "react";
import SbEditable from "storyblok-react";
import blackArrow from "../../../assets/images/black-arrow.png";
import backFAQ from "../../../assets/images/home-faq.png";
import purpleArrow from "../../../assets/images/purple-arrow.png";
import {
  ButtonContainer,
  ButtonFAQ,
  ButtonFinancing,
  ButtonTitle,
  Container,
  ContainerFinancing,
  Description,
  DescriptionFAQ,
  Divider,
  FAQ,
  FAQBack,
  FeaturedDescription,
  FeaturedTitleFAQ,
  FeaturedTitleFinancing,
  Financing,
  Icon,
  InnerContainer,
  TitleFAQ,
  TitleFinancing,
} from "./style";

export default (props) => {
  return (
    <SbEditable content={props.content}>
      <Divider />
      <Container className="Container">
        <InnerContainer>
          <Financing src={backFAQ}>
            <ContainerFinancing>
              <TitleFinancing>
                {props.content.Titulo_1}
                <FeaturedTitleFinancing>
                  {props.content.Titulo_2}
                </FeaturedTitleFinancing>
              </TitleFinancing>

              <Description>{props.content.Descricao}</Description>

              <ButtonFinancing
                href={props.content.Link.url}
                rel="noopener noreferrer"
                onClick={() =>
                  console.log("Dispatching event to Google Analytics") ||
                  window.ga("send", {
                    hitType: "event",
                    eventCategory: "Bolsas e Financiamento",
                    eventAction: "Clique",
                  })
                }
              >
                <ButtonTitle>
                  Conheça as opções que <br />a <span>PUCPR</span> oferece!
                </ButtonTitle>
                <Icon src={blackArrow} />
              </ButtonFinancing>
            </ContainerFinancing>
          </Financing>
          <FAQBack>
            <FAQ>
              <TitleFAQ>
                <FeaturedTitleFAQ>FAQ</FeaturedTitleFAQ>
                PERGUNTAS FREQUENTES
              </TitleFAQ>

              <DescriptionFAQ>
                Ainda tem alguma dúvida? <br />
                Tire todas no{" "}
                <FeaturedDescription>nosso FAQ!</FeaturedDescription>
              </DescriptionFAQ>

              <ButtonContainer>
                <ButtonFAQ
                  href="/faq"
                  onClick={() =>
                    console.log("Dispatching event to Google Analytics") ||
                    window.ga("send", {
                      hitType: "event",
                      eventCategory: "FAQ",
                      eventAction: "Clique",
                    })
                  }
                >
                  ACESSE O FAQ
                  <Icon src={purpleArrow} />
                </ButtonFAQ>
                <ButtonFAQ
                  href="/fale-conosco"
                  onClick={() =>
                    console.log("Dispatching event to Google Analytics") ||
                    window.ga("send", {
                      hitType: "event",
                      eventCategory: "Fale Conosco",
                      eventAction: "Clique",
                    })
                  }
                >
                  FALE CONOSCO
                  <Icon src={purpleArrow} />
                </ButtonFAQ>
              </ButtonContainer>
            </FAQ>
          </FAQBack>
        </InnerContainer>
      </Container>
    </SbEditable>
  );
};
