import SbEditable from "storyblok-react";
import React from "react";
import { ContentContainer, TitleTopic, TitleTopicAnchor } from "./style";
import FAQAccordionList from "../FAQAccordionList";
import slugify from "slugify";

const getTopicSlug = (topicName) => {
  return slugify(topicName).toLowerCase().trim();
};

export default ({ content, context, getGridState }) => {
  const topics = context.ctx_faq_topic_list.topics;
  const slug = window.location.hash.replace("#t-", "");
  const slugIndex = topics.findIndex(
    (topic) => slug === getTopicSlug(topic.title.replace(".", "-"))
  );
  const currentIndex = getGridState(
    "currentIndex",
    slugIndex !== -1 ? slugIndex : 0
  );
  const topic = topics[currentIndex];

  return (
    <SbEditable content={content}>
      <ContentContainer>
        <TitleTopicAnchor id={`t-${slug}`}></TitleTopicAnchor>
        <TitleTopic>{topic.title}</TitleTopic>
        <FAQAccordionList items={topic.items} />
      </ContentContainer>
    </SbEditable>
  );
};
