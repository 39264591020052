import styled from "styled-components";

const colors = {
  darkRed: 'rgba(139,24,56,1)',
  grey: '#eaeaea',
  white: '#ededed',
};

export const Section = styled.section`
  width: 100%;
  padding: ${(props) => props.noGutter ? '0' : '20px 0 50px'};
  background-color: ${(props) => colors[props.bgColor] || colors.grey};
`;

export default Section;
