import React from "react";
import { CoursesContainer } from "./style";
import Card from "../../Home/CardCourses";

export default ({ course }) => {
  const related_courses = course.related_courses;

  return (
    <CoursesContainer>
      {related_courses.map((course) => (
        <Card
          key={`curso-recomendado-${course.slug}`}
          src={course.content.thumbnail.filename}
          title={course.content.title}
          full_slug={course.fullSlug}
          tag={course.content.modalities[0].name}
        />
      ))}
    </CoursesContainer>
  );
};
