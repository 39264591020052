import React from "react";
import { render } from "storyblok-rich-text-react-renderer";

import { GuidanceAccordion, GuidanceDescription } from "./style";

export default ({ content }) => {
  return (
    <>
      <GuidanceAccordion>
        <GuidanceDescription>{render(content)}</GuidanceDescription>
      </GuidanceAccordion>
    </>
  );
};
