import React, { useEffect, useState } from "react";

import brasaopuc from "../../../assets/images/brasao-puc.png";
import iconmenu from "../../../assets/images/burger-menu.png";
import closeicon from "../../../assets/images/close-menu.png";
import logoMenu from "../../../assets/images/pucpr.jpg";

import {
  CloseIcon,
  Header,
  HeaderInner,
  HeaderMobile,
  Icon,
  Img,
  ImgFooter,
  Logo,
  LogoWhite,
  Nav,
  NavItem,
  NavLink,
  NavLinkExternal,
  NavMobile,
  Overlay,
} from "./style";

const Menu = (props) => {
  const [isShowMenu, setIsShowMenu] = useState(false);
  const pathName = window.location.pathname;
  const darkLogo = props.content.logo;

  const scrollToHowToJoin = (hash) => {
    const currentHash = hash.replace("t-", "");
    if (!currentHash) return;
    const hasHowToJoin =
      currentHash === "#4d"
        ? document.getElementById(currentHash.replace("#"))
        : document.querySelector(currentHash);
    const htmlTag = document.querySelector("html");
    if (!hasHowToJoin) return;

    hasHowToJoin.scrollIntoView({
      behavior: "smooth",
    });

    setIsShowMenu(false);
    htmlTag.classList.remove("overflow-hidden");
    // window?.ga("send", "pageview");
  };

  const toggleMenuVisibility = (boolean) => {
    const htmlTag = document.querySelector("html");
    if (boolean) {
      htmlTag.classList.add("overflow-hidden");
    } else {
      htmlTag.classList.remove("overflow-hidden");
    }

    setIsShowMenu(boolean);
  };

  useEffect(() => {
    scrollToHowToJoin(window.location.hash);
  }, []);

  const dispatchEventToGoogleAnalytics = (category) => {
    toggleMenuVisibility(false);
    return window?.ga("send", {
      hitType: "event",
      eventCategory: category,
      eventAction: "Clique",
    });
  };

  return (
    <Header className="menu">
      <HeaderInner>
        <a href="/">
          {darkLogo && darkLogo.filename ? (
            <Img
              cms="true"
              src={darkLogo?.filename}
              alt={darkLogo?.alt || "PUCPR Grupo Marista"}
            />
          ) : (
            <Logo src={logoMenu} />
          )}
        </a>
        <Icon
          src={iconmenu}
          onClick={() => toggleMenuVisibility(true)}
          alt="Abrir o menu"
        />
        <Nav>
          <NavItem>
            <NavLink
              isActive={pathName === "/"}
              to="/"
              onClick={() =>
                console.log("Dispatching event to Google Analytics") ||
                window?.ga("send", {
                  hitType: "event",
                  eventCategory: "Home",
                  eventAction: "Clique",
                })
              }
            >
              Início
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              isActive={pathName.indexOf("/cursos") !== -1}
              to="/cursos"
              onClick={() =>
                console.log("Dispatching event to Google Analytics") ||
                window?.ga("send", {
                  hitType: "event",
                  eventCategory: "Página de Cursos",
                  eventAction: "Clique",
                })
              }
            >
              Cursos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLinkExternal
              isActive={pathName === "/graduacao-4d"}
              onClick={() =>
                console.log("Dispatching event to Google Analytics") ||
                window?.ga("send", {
                  hitType: "event",
                  eventCategory: "Pagina de Graduação 4D",
                  eventAction: "Clique",
                })
              }
              rel="noopener noreferrer"
              href="https://digital4d.pucpr.br/site/"
            >
              Graduação 4D
            </NavLinkExternal>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={() => scrollToHowToJoin("#como-ingressar")}
              to="/#como-ingressar"
            >
              Como Ingressar
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              isActive={pathName.indexOf("/faq") !== -1}
              to="/faq"
              onClick={() =>
                console.log("Dispatching event to Google Analytics") ||
                window?.ga("send", {
                  hitType: "event",
                  eventCategory: "FAQ",
                  eventAction: "Clique",
                })
              }
            >
              FAQ
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLinkExternal
              href="https://resultado.pucpr.br/"
              rel="noopener noreferrer"
              onClick={() =>
                console.log("Dispatching event to Google Analytics") ||
                window?.ga("send", {
                  hitType: "event",
                  eventCategory: "Resultados",
                  eventAction: "Clique",
                })
              }
            >
              Resultados
            </NavLinkExternal>
          </NavItem>
          <NavItem>
            <NavLinkExternal
              href="https://www.pucpr.br/vestibular/editais"
              rel="noopener noreferrer"
              onClick={() =>
                console.log("Dispatching event to Google Analytics") ||
                window?.ga("send", {
                  hitType: "event",
                  eventCategory: "Editais e Gabaritos",
                  eventAction: "Clique",
                })
              }
            >
              Editais e Gabaritos
            </NavLinkExternal>
          </NavItem>
          <NavItem>
            <NavLink
              isActive={pathName === "/fale-conosco"}
              to="/fale-conosco"
              onClick={() =>
                console.log("Dispatching event to Google Analytics") ||
                window?.ga("send", {
                  hitType: "event",
                  eventCategory: "Fale Conosco",
                  eventAction: "Clique",
                })
              }
            >
              Fale Conosco
            </NavLink>
          </NavItem>
        </Nav>
        {isShowMenu ? (
          <Overlay>
            <HeaderMobile>
              <LogoWhite>
                <b>PUCPR</b>
              </LogoWhite>

              <CloseIcon
                src={closeicon}
                onClick={() => toggleMenuVisibility(false)}
                alt="Botão para fechar o menu"
              />
            </HeaderMobile>

            <NavMobile>
              <NavItem>
                <NavLink
                  to="/"
                  onClick={() => dispatchEventToGoogleAnalytics("Home")}
                >
                  Início
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/cursos"
                  onClick={() =>
                    dispatchEventToGoogleAnalytics("Página de Cursos")
                  }
                >
                  Cursos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLinkExternal
                  href="https://digital4d.pucpr.br/site/"
                  rel="noopener noreferrer"
                  onClick={() =>
                    dispatchEventToGoogleAnalytics("Pagina de Graduação 4D")
                  }
                >
                  Graduação 4D
                </NavLinkExternal>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/#como-ingressar"
                  onClick={() => scrollToHowToJoin("#como-ingressar")}
                >
                  Como Ingressar
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/faq"
                  onClick={() => dispatchEventToGoogleAnalytics("FAQ")}
                >
                  FAQ
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLinkExternal
                  href="https://resultado.pucpr.br/"
                  rel="noopener noreferrer"
                  onClick={() => dispatchEventToGoogleAnalytics("Resultados")}
                >
                  Resultados
                </NavLinkExternal>
              </NavItem>
              <NavItem>
                <NavLinkExternal
                  href="https://www.pucpr.br/vestibular/editais"
                  rel="noopener noreferrer"
                  onClick={() =>
                    dispatchEventToGoogleAnalytics("Editais e Gabaritos")
                  }
                >
                  Editais e Gabaritos
                </NavLinkExternal>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/fale-conosco"
                  onClick={() => dispatchEventToGoogleAnalytics("Fale Conosco")}
                >
                  Fale Conosco
                </NavLink>
              </NavItem>
            </NavMobile>
            <ImgFooter src={brasaopuc} alt="Brasão PUC" />
          </Overlay>
        ) : null}
      </HeaderInner>
    </Header>
  );
};

export default Menu;
