import React from "react";
import SbEditable from "storyblok-react";
import { IframeWrapper } from "../Video/styles";
import { Background, Divider, Image, MobileImage, VideoContainer } from "./styles";

export default function StudyTrail(props) {
  const hasYtembed = !!props.content.yt_embed;

  return (
    <SbEditable content={props.content}>
      <Divider />
      <Background color={props.content.background_color} hasYtembed={hasYtembed}>
        {hasYtembed ? (
          <VideoContainer>
            <IframeWrapper>
              <iframe
                title="Guia do curso"
                src={props.content.yt_embed}
                width="100%"
                height="100%"
                allowFullScreen
              />
            </IframeWrapper>
          </VideoContainer>
        ) : (
          <>
            <Image src={props.content.image} />
            <MobileImage src={props.content.imagem_mobile} />
          </>
        )}
      </Background>
    </SbEditable>
  )
}