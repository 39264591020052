import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  CloseSituation,
  ItemName,
  ItemsContainer,
  List,
  ListItem,
  OpenSituation,
} from "./style";

import slugify from "slugify";
import addIcon from "../../../assets/images/add-icon-purple.png";
import backgroundBtnClosed from "../../../assets/images/btn-back-closed.png";
import backgroundBtnOpen from "../../../assets/images/btn-back-open.png";
import GuidanceAccordion from "../GuidanceAccordion";

const ListAccordion = ({ items, getGridState, setGridState }) => {
  const [isClicked, setIsClicked] = useState(false);

  const getTopicSlug = (topicName) => {
    return slugify(topicName)
      .toLowerCase()
      .trim()
      .replace(":", "-")
      .replace(".", "-");
  };

  const slug = window.location.hash
    .replace("t-", "")
    .replace(":", "-")
    .replace(".", "-");
  const slugIndex = items.findIndex(
    (topic) => slug.replace("#", "") === getTopicSlug(topic.title)
  );

  const currentIndex = getGridState(
    "currentIndex",
    slugIndex !== -1 ? slugIndex : 0
  );

  useEffect(() => {
    if (window.location.hash.includes("#t-")) {
      setIsClicked(currentIndex);
    }
  }, [currentIndex]);

  const updateIndex = (newIndex, slug) => {
    window.history.pushState(
      "",
      "",
      `/#t-${slug.replace(":", "-").replace(".", "-")}`
    );
    setGridState({ currentIndex: newIndex });
  };

  const toggleAccordion = (index, topic) => {
    if (isClicked === index) {
      return setIsClicked(null);
    }

    updateIndex(index, getTopicSlug(topic));

    setIsClicked(index);
  };

  return (
    <List>
      {items.map((item, index) => (
        <Fragment key={`guidance-${item.title.toLowerCase()}`}>
          <ListItem
            onClick={() => toggleAccordion(index, item.title)}
            id={`${getTopicSlug(item.title)
              .replace(":", "-")
              .replace(".", "-")}`}
          >
            <ItemsContainer>
              <ItemName>{item.title}</ItemName>
              {item.situation === "abertas" ? (
                <OpenSituation src={backgroundBtnOpen}>{item.situation}</OpenSituation>
              ) : (
                <CloseSituation src={backgroundBtnClosed}>{item.situation}</CloseSituation>
              )}
            </ItemsContainer>

            <Button className={isClicked === index ? "is-active" : ""}>
              <img src={addIcon} alt="Ícone de abrir" />
            </Button>
          </ListItem>
          {isClicked === index ? (
            <GuidanceAccordion
              title={item.title}
              description={item.description}
              content={item.content}
            />
          ) : null}
        </Fragment>
      ))}
    </List>
  );
};

export default ListAccordion;
