import Colors from "../../../styles/colors";
import styled from "styled-components";

export const ModalitiesContainer = styled.div`
  width: 100%;
  height: auto;
  background: #e7e6e8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => (props.isFilter ? "20px 5px" : " 0")};

  h3 {
    max-width: 375px;
    width: 100%;
    text-align: left;
    padding: 0 5px;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
    letter-spacing: 0.3px;
  }

  @media (min-width: 1024px) {
    background: ${(props) => (props.isFilter ? "#e7e6e8" : "white")};
    margin: 0 auto;
    width: 100%;
    justify-content: flex-start;
    max-width: ${(props) => (props.isFilter ? "980px" : "780px")};
    padding: ${(props) => (props.isFilter ? "20px 32px" : "0")};
    align-items: ${(props) => (props.isFilter ? "flex-start" : "center")};
    margin-top: ${(props) => (props.isFilter ? "38px" : "0")};
  }

  @media (min-width: 1440px) {
    max-width: ${(props) => (props.isFilter ? "1260px" : "780px")};
  }
`;

export const ModalitiesInner = styled.div`
  max-width: 375px;
  width: 100%;
  height: auto;
  display: flex;
  overflow-x: auto;
  padding: ${(props) => (props.isFilter ? "0 5px" : " 40px 5px")};

  @media (min-width: 1024px) {
    padding: ${(props) => (props.isFilter ? " 10px 0 0 0" : "10px 0 50px 0")};
    max-width: none;
    width: 100%;
    max-width: ${(props) => (props.isFilter ? "inherit" : "600px")};
  }
`;

export const Modality = styled.span`
  width: 180px;
  height: 40px;
  background: ${Colors.red};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin: 0 22px 0 5px;

  @media (min-width: 1024px) {
    max-width: 30%;
  }
`;

export const ModalityDisabled = styled(Modality)`
  background: #b4b4b4;
`;

export const Name = styled.p`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  max-width: 715px;
  width: 100%;
  text-align: center;
  color: white;
`;

export const NameDisabled = styled(Name)`
  color: #868686;
`;

export const Description = styled.p`
  line-height: 20.8px;
`;
