import React from "react";
import addIcon from '../../../../../assets/images/icon-add.png';
import { AccordionHeaderWrapper, AccordionIconWrapper, AccordionTitle, Button } from "./styles";

export default function Header({ title, handleChange, open }) {
  const className = open ? 'active' : '';
  return (
    <AccordionHeaderWrapper className={className} onClick={handleChange}>
      <AccordionTitle className={className}>
        {title}
      </AccordionTitle>

      <AccordionIconWrapper>
        <Button className={className}>
          <img src={addIcon} alt="Abrir accordion" />
        </Button>
      </AccordionIconWrapper>
    </AccordionHeaderWrapper>
  )
}