import styled, { keyframes } from "styled-components";
import colors from "../../../styles/colors";

const animationContent = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Background = styled.section`
  background-color: ${colors.brightgray};
`;

export const Container = styled.div`
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  overflow: auto;

  @media screen and (min-width: 1024px) {
    width: 1178px;
  }
`;

export const AccordionRoot = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 95px;

  transition: all 1s ease;

  &.open {
    height: auto;
  }

  

  @media screen and (min-width: 1024px) {
    width: 132px;
    position: relative;
    border-right: 1px solid ${colors.white};
    float: left;
    height: 690px;
    flex-direction: row;

    &.open {
      width: 782px;
      border: none;
    }
  }
`;

export const AccordionHeader = styled.div`
  background-color: ${colors.darkpurple};
  cursor: pointer;
  height: 95px;
  width: 100%;
  display: flex;

  @media screen and (min-width: 1024px) {
    width: 132px;
    display: block;
    height: 690px;
    padding: 0 48px;
  }
`;

export const RedSquare = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${colors.red};
  border: 1px solid ${colors.raspberry};
  
  @media screen and (min-width: 1024px) {
    margin-top: 24px;
  }
`;

export const Title = styled.h2`
    color: ${colors.white};
    font-size: 40px;
    min-width: max-content;
    width: 100%;
    outline: 0 none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 20px 0;
    
    @media screen and (min-width: 1024px) {
      padding: 40px 0 0;
      display: block;
      font-size: 48px;
      gap: 0;
      justify-content: flex-start;
      align-items: flex-start;
    }
    
    p {
      @media screen and (min-width: 1024px) {
        position: relative;
        left: 0px;
        top: 160px;
        transform: rotate(270deg);
        transform-origin: 0 0;
        font-size: 24px;
        line-height: 23px;
        font-weight: 700;
        letter-spacing: 0.5px;
        margin: 0;
      }
    }
`;

export const AccordionContent = styled.div`
  background-color: ${colors.brightgray};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;

  @media screen and (min-width: 1024px) {
    border-top: 8px solid ${colors.darkpurple};
    padding: 40px 50px;
    max-height: 690px;
    overflow-y: auto;
    overflow-x: hidden;

    animation: ${animationContent} 1s ease;
  }

  iframe {
    margin: 0 auto;
    border: none;
  }

  p {
    img {
      width: 100%;
      height: 100%;

      @media screen and (min-width: 1024px) {
        width: 550px;
        height: 319px;
      }
    }

    padding: 10px 30px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.5px;

    @media screen and (min-width: 1024px) {
      width: 550px;
      padding: 20px 0 0;
    }
  }
`;
