import React from 'react';
import SbEditable from 'storyblok-react';
import TopicsItem from '../TopicsItem/index';
import { TopicsContainer, TopicsContainerInner, TopicsRedBar } from './style';
const _ = require('lodash')


export default (props) => {
  const topics = _.map(props.context.ctx_faq_topic_list.topics, 'title')

  return (
    <SbEditable content={props.content}>
      <TopicsContainer className="TopicsContainer">
        <TopicsRedBar></TopicsRedBar>
        <TopicsContainerInner>
          <TopicsItem
            topics={topics}
            setGridState={props.content.setGridState}
            getGridState={props.content.getGridState}
          />
        </TopicsContainerInner>
      </TopicsContainer>
    </SbEditable>
  );
}
