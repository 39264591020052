import styled from "styled-components";
import iconDropdown from "../../../assets/images/icon-dropdown.png";
import Colors from "../../../styles/colors";

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  ::-webkit-scrollbar {
    width: 0px;
  }

  @media (min-width: 1024px) {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 60%;
    height: auto;
    padding: 1.688rem 1.25rem 0 7.5rem;
    background: #fff;
  }
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  text-align: left;
  padding: 0 20px;
  margin: 20px 0;

  > :nth-child(n) {
    margin: 12px 0;
  }

  @media (min-width: 1024px) {
    margin-top: 0;
    padding: 0;
    width: 100%;
    max-width: 780px;
    padding-right: 20px;
  }
`;

export const Description = styled.section`
  line-height: 20.8px;
  padding: 0px 20px;
  margin-bottom: 20px;

  > :nth-child(n) {
    margin: 24px 0;
  }

  li {
    margin-left: 20px;

    > :nth-child(n) {
      margin: 10px 0;
    }
  }

  img {
    width: 100%;
  }

  @media (min-width: 1024px) {
    padding: 0;
    width: 100%;
    max-width: 780px;
    padding-right: 20px;
  }
`;

export const SelectArea = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 15px;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 0;
    max-width: 780px;
    padding-right: 20px;
  }
`;

export const Select = styled.select`
  width: 300px;
  height: 60px;
  border: 1px solid #d3d3d3;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(${iconDropdown}) no-repeat right #fff;
  background-origin: content-box;
  background-color: #f9fafb;
  font-size: 1rem;
  padding: 0 15px;
  color: #0207;

  &:focus {
    outline: none;
  }

  @media (min-width: 1024px) {
    width: 350px;
    margin-right: 20px;
  }
`;

export const SelectItem = styled.option`
  color: black;
`;

export const Button = styled.button`
  border: none;
  width: 300px;
  height: 60px;
  font-size: 16px;
  font-weight: 700;
  color: white;
  background: ${Colors.red};
  cursor: pointer;
  margin: 20px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;

  img {
    transition: all 0.5s;
  }

  &:hover {
    background: ${Colors.darkred};
  }

  @media (min-width: 1024px) {
    margin: 0;
    width: 250px;
  }
`;

export const Icon = styled.img`
  margin-left: 10px;
`;

export const Arrow = styled.img`
  margin-left: 10px;
`;

export const CourseGuideWrapper = styled.div`
  display:  flex;
  align-items: center;
  gap: 16px;
  margin:  0 0 24px;
  justify-content: center;
  width: 100%;

  @media (min-width: 1024px) {
    max-width: 780px;
    justify-content: flex-start;
  }
`;

export const CourseGuideDescription = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

export const Link = styled.a`
  background-color: ${Colors.red};
  display: block;
  padding: 0 24px;
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;

  &:hover {
    background: ${Colors.darkred};
  }
`;
