import React from "react";
import SbEditable from "storyblok-react";
import { render } from "storyblok-rich-text-react-renderer";
import Container from "../../Common/Container";
import { Background, HeadingWrapper } from "./styles";

export default function TextBar(props) {
  return (
    <SbEditable content={props.content}>
      <Background bgColor={props.content.background_color} color={props.content.color}>
        <Container>
          <HeadingWrapper>
            {render(props.content.text)}
          </HeadingWrapper>
        </Container>
      </Background>
    </SbEditable>
  )
}