import React from "react";
import SbEditable from "storyblok-react";
import { render } from "storyblok-rich-text-react-renderer";
import Container from "../../Common/Container";
import { Background, ContentWrapper, IframeWrapper, Paragraph, SubTitle, Title, VideoWrapper } from "./styles";

export default function Video(props) {
  return (
    <SbEditable content={props.content}>
      <Background>
        <Container>
          <Title>{props.content.title}</Title>

          <SubTitle>{props.content.subtitle}</SubTitle>

          <ContentWrapper>
            <Paragraph>{render(props.content.text)}</Paragraph>

            {props.content.youtube_link && (
              <VideoWrapper>
                <IframeWrapper>
                  <iframe
                    title={props.content.title}
                    src={props.content.youtube_link}
                    width="100%"
                    height="100%"
                    allowFullScreen
                  />
                </IframeWrapper>
              </VideoWrapper>
            )}

          </ContentWrapper>
        </Container>
      </Background>
    </SbEditable>
  )
}