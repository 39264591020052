import React, { useCallback, useEffect, useRef, useState } from "react";
import SbEditable from "storyblok-react";
import { render } from "storyblok-rich-text-react-renderer";

import detail from "../../../assets/images/detail-subscribe.png";
import polygon from "../../../assets/images/polygon.png";
import Container from "../../Common/Container";
import Arrow from "../components/Carrousel/Arrow";
import CardCarrousel from "../components/Carrousel/Card";
import { Background, CarrouselRoot, CarrouselWrapper, ContentWrapper, Detail, IconWrapper, Subtitle, TitleWrapper } from "./styles";

export default function Courses(props) {
  const carrouselRef = useRef(null);
  const [disabledLeftIcon, setDisabledLeftIcon] = useState(true);
  const [disabledRightIcon, setDisabledRightIcon] = useState(false);
  const [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    if (carrouselRef.current) {
      setScrollWidth(carrouselRef.current.scrollWidth - carrouselRef.current.offsetWidth);
    }
  }, [carrouselRef])

  const handleScroll = useCallback(() => {
    const { scrollLeft } = carrouselRef.current;
    setDisabledLeftIcon(scrollLeft === 0);
    setDisabledRightIcon(!(scrollLeft < scrollWidth - 16));
  }, [scrollWidth]);

  const handleLeft = useCallback((e) => {
    e.preventDefault();
    carrouselRef.current.scrollLeft -= (carrouselRef.current.offsetWidth + 16);
  }, []);

  const handleRight = useCallback((e) => {
    e.preventDefault();
    carrouselRef.current.scrollLeft += (carrouselRef.current.offsetWidth + 16);
  }, []);

  useEffect(() => {
    if (carrouselRef.current) {
      carrouselRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      carrouselRef.current.removeEventListener('scroll', handleScroll);
    }
  }, [carrouselRef, handleScroll])

  return (
    <SbEditable content={props.content}>
      <Background>
        <Container>
          <ContentWrapper>
            <TitleWrapper>
              {render(props.content.title)}
              <Detail src={detail} />
            </TitleWrapper>

            <IconWrapper>
              <img src={polygon} alt="" width="100%" height="100%" />
            </IconWrapper>

            <Subtitle>{props.content.subtitle}</Subtitle>

            <CarrouselRoot>
              <CarrouselWrapper ref={carrouselRef}>
                {props.content.carousel_item_4d.map((course) => (
                  <CardCarrousel
                    key={course._uid}
                    title={course.title}
                    text_link={course.text_link}
                    link={course.link}
                    image={course.course_image}
                  >
                    {render(course.text)}
                  </CardCarrousel>
                ))}

              </CarrouselWrapper>

              <Arrow
                direction="left"
                disabled={disabledLeftIcon}
                onClick={handleLeft}
              />
              <Arrow
                direction="right"
                disabled={disabledRightIcon}
                onClick={handleRight}
              />
            </CarrouselRoot>
          </ContentWrapper>
        </Container>
      </Background>
    </SbEditable>
  )
}