import React from "react";
import Container from "../../Common/Container";
import CarrouselList from "../Carrousel";
import Section from "../Components/Section";
import SectionDescription from "../Components/SectionDescription";
import SectionTitle from "../Components/SectionTitle";
import { professionals } from "./professionals";

export default function MarketProfessionals() {
  return (
    <Section bgColor="white">
      <Container>
        <SectionTitle bgColor="white">
          Profissionais de mercado
        </SectionTitle>
        <SectionDescription>
          Masterclass com quem vive a influência digital na prática
        </SectionDescription>
        <CarrouselList items={professionals} buttonText="Conheça os profissionais de mercado" />
      </Container>
    </Section>
  )
}
