import Colors from "../../../styles/colors";
import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
 0% {
   opacity: 0;
 }

 100% {
   opacity: 1;
 }
`;

export const GuidanceAccordion = styled.div`
  animation: 2s ${fadeIn} ease-out;
  width: 100%;
  height: auto;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 0px 13px;
`;

export const GuidanceDescription = styled.div`
  width: 100%;
  height: auto;
  font-weight: 400;
  color: ${Colors.burgundy};
  line-height: 20px;
  font-size: 16px;
  margin-bottom: 26px;

  > :nth-child(n) {
    margin: 10px 0;
  }

  li {
    margin-left: 20px;

    > :nth-child(n) {
      margin: 10px 0;
    }
  }

  a {
    text-decoration: underline;
    font-weight: bold;
  }
`;

export const GuidanceTitlePresencial = styled.h4`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: ${Colors.red};
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const GuidanceContainerBtn = styled.div`
  width: 100%;
  margin-bottom: 18px;
`;

export const GuidanceCampusBtn = styled.a`
  width: 160px;
  height: 40px;
  background: ${Colors.red};
  color: white;
  font-size: 14px;
  position: relative;
  float: left;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 4px;

  @media (min-width: 1024px) {
    width: 163px;
    margin-right: 7px;
  }
`;

export const GuidanceCampusBtnDisabled = styled(GuidanceCampusBtn)`
  pointer-events: none;
  background: #cbcbcb;
  color: #b7b7b7;
`;

export const GuidanceTitleEAD = styled(GuidanceTitlePresencial)`
  color: ${Colors.lightpurple};
`;

export const GuidanceSubscribeBtn = styled(GuidanceCampusBtn)`
  width: 100%;
  background: ${Colors.lightpurple};
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.purple};
  }
`;

export const Icon = styled.img`
  margin-left: 10px;
  transition: all 0.5s;
`;
