import React, { useState } from "react";
import Header from "./Header";
import Content from "./content";
import { AccordionRootWrapper } from "./styles";

export default function Accordion({ children, title }) {
  const [open, setOpen] = useState(false);

  const handleChangeOpen = () => setOpen(prev => !prev);

  return (
    <AccordionRootWrapper>
      <Header title={title} handleChange={handleChangeOpen} open={open} />
      <Content open={open} handle>
        {children}
      </Content>
    </AccordionRootWrapper>
  )
}