import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
 0% {
   opacity: 0;
 }

 100% {
   opacity: 1;
 }
`;

export const FAQAccordion = styled.div`
  animation: 2s ${fadeIn} ease-out;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  margin-bottom: 10px;
`;

export const FAQDescription = styled.div`
  width: 100%;
  height: auto;
  font-weight: 400;
  color: black;
  line-height: 20.8px;

  > :nth-child(n) {
    margin: 10px 0;
  }

  li {
    margin-left: 20px;

    > :nth-child(n) {
      margin: 10px 0;
    }
  }
`;
