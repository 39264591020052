import styled, { keyframes } from "styled-components";

const lightboxon = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

export const ModalContainer = styled.div`
  animation: ${lightboxon} 5s normal;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  z-index: 100;

  @media screen and (max-width: 768px) {
    align-items: stretch;
  }
`;

export const ModalContent = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 2px;
  padding: 20px 40px;
  width: 100%;
  max-width: 540px;
  transition: transform 0.3s ease-in-out;
  transform: ${(props) =>
    props.isClosing ? "scale(0.9)" : "scale(1)"};

  ::-webkit-scrollbar {
    width: 14px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ff0040;
    border-radius: 50px;
    border: 4px solid #f2f2f2;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  @media screen and (max-width: 768px) {
    overflow-y: scroll;
    padding: 40px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 50px;
  color: #ff0040;
  cursor: pointer;
`;

export const ModalHeader = styled.div``;

export const ModalTitle = styled.h3`
  font-size: 20px;
  letter-spacing: 4px;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

export const ModalSubtitle = styled.p`
  border-bottom: 1pt solid #0002;
  padding-bottom: 12px;
  margin-bottom: 12px;
  color: #ff0040;
  font-size: 1rem;
`

export const ModalDescription = styled.div`
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 10px;
`;
