import styled from "styled-components";

export const Listener = styled.div`
  width: 100%;
  margin: 38px auto;
  min-height: calc(100vh - 400px);
  max-width: 375px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 1024px) {
    max-width: 100%;
  }
  @media (min-width: 1440px) {
    max-width: 1270px;
  }
`;
