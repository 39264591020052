import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
 0% {
    margin-top: -20px;
    opacity: 0;
 }

 100% {
   opacity: 1;
   margin-top: 0;
}
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 20px;
  justify-content: center;

`;

export const BoxItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  border: ${props => props.outlined && '1px solid'};
  padding: 0 10px;
  cursor: pointer;
  min-width: 350px;
  width: 90%;
  margin-top: ${props => props.outlined ? '16px' : '0'};

  border-color: ${props => props.className === 'active' ? '#FF0141' : '#ccc'};

  :hover  {
    border-color: #FF0040;
  }

  > .items-container {
    border-bottom: ${props => !props.outlined ? '1px solid' : 'none'};
    border-color: ${props => props.className === 'active' ? '#FF0141' : '#ccc'};
    font-weight: ${props => props.className === 'active' ? 700 : 400};

    :hover  {
      border-color: #FF0141;
    }
  }

  @media (min-width: 1024px) {
    width: 520px;
    margin-left: 40px;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;


  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ItemName = styled.summary`
  font-size: 16px;
  color: #020203;
  width: 100%;

  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;


export const Button = styled.a`
  width: 30px;
  height: 30px;
  border: 1px solid #FF0141;
  color: #FF0141;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;

  svg {
    transition: all 0.7s;
  }

  &.is-active svg {
    transform: rotate(45deg);
  }
`;

export const AccordionBody = styled.div`
  animation: 0.5s ${fadeIn};
  animation-fill-mode: forwards;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
`;

export const AccordionDescription = styled.div`
  width: 100%;
  height: auto;
  font-weight: 300;
  color: #020203;
  line-height: 22px;
  font-size: 16px;
  margin-bottom: 15px;

  a {
    text-decoration: underline;
  }
`;
