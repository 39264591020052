import React from 'react'

import About from './About'
import CouseGuide from './CouseGuide'
import Faq from './Faq'
import Footer from './Footer'
import GraduationContent from './GraduationContent'
import { Header as CourseHeader } from './Header'
import MarketProfessionals from './MarketProfessionals'
import Motives from './Motives'
import OneMoreThing from './OneMoreThing'
import Prices from './Prices'
import StepsToJoin from './StepsToJoin'
import StudyTrail from './StudyTrail'
import Teachers from './Teachers'
import WhatsappIcon from './WhatsappIcon'

export default function DigitalEconomy() {
  return (
    <>
      <CourseHeader />
      <About />
      <Motives />
      <StudyTrail />
      <MarketProfessionals />
      <OneMoreThing />
      <Teachers />
      <StepsToJoin />
      <CouseGuide />

      <GraduationContent />

      <Prices />
      <Faq />
      <Footer />
      <WhatsappIcon />
    </>
  )
}
