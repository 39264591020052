import styled from "styled-components";
import { VideoWrapper } from "../Video/styles";

export const Background = styled.section`
  background-color: ${({ color }) => color};
  padding: ${({ hasYtembed }) => hasYtembed ? "20px" : "0"};
`;

export const Divider = styled.div`
  background-color: #454545;
  width: 100%;
  height: 2px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

export const MobileImage = styled(Image)`
  display: block;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const VideoContainer = styled(VideoWrapper)`
  padding: 40px 0;
`;
