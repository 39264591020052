import React, { useState } from "react";
import { BsWhatsapp } from 'react-icons/bs';
import { Button, ButtonIcon } from "../Components/Button";

import BannerImage from "../../../assets/images/course-banner-header.webp";

import Container from "../../Common/Container";
import Section from "../Components/Section";
import SignUpModal from "../SignUpModal";
import { Banner, Text } from "./styles";

export function Header() {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  return (
    <>
      <Banner image={BannerImage}>
        <Container>
          <h1>Economia da Influência Digital</h1>

          <p>
            Economia da Influência digital que já era tendência, <br />
            agora virou Graduação completa.
          </p>


          <div>
            <ButtonIcon >
              <a href="https://api.whatsapp.com/send?phone=5541991321897&text=Ol%C3%A1%21+Quero+falar+com+um+especialista.">
                <BsWhatsapp />
                Tire suas dúvidas
              </a>
            </ButtonIcon>
            <Button primary onClick={handleOpenModal}>Inscreva-se</Button>
          </div>
        </Container>
      </Banner>
      <Section noGutter bgColor="darkRed">
        <Text>NÃO FIQUE DE FORA!</Text>
      </Section>

      {openModal && (
        <SignUpModal isOpen={openModal} onRefuse={handleCloseModal} />
      )}
    </>
  )
}