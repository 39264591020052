import React from "react";
import { IoIosCheckmark } from "react-icons/io";
import SbEditable from "storyblok-react";
import TitleSection from "../../4d/components/TitleSection";
import { Background, Button, Container, CourseGuideImgWrapper, CourseGuideList, CourseGuideListItem, CouseGuideWrapper, IconWrapper } from "./styles";

export default function CourseGuide(props) {
  return (
    <SbEditable content={props.content}>
      <Background>
        <Container>
          <TitleSection>{props.content.title}</TitleSection>

          <CouseGuideWrapper>
            <CourseGuideImgWrapper>
              <img src={props.content.image.filename} alt={props.content.image.alt} />
            </CourseGuideImgWrapper>

            <CourseGuideList>
              {props.content.list.map(item => (
                <CourseGuideListItem key={item._id}>
                  <IconWrapper>
                    <IoIosCheckmark />
                  </IconWrapper>
                  <p>{item.text}</p>
                </CourseGuideListItem>
              ))}

              <Button
                href={props.content.button_link}
                target="_blank"
              >
                {props.content.button_text}
              </Button>
            </CourseGuideList>
          </CouseGuideWrapper>
        </Container>
      </Background>
    </SbEditable>
  )
}