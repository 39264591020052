import React from "react";
import { Icon, Img, Link, VideoContainer, VideoInner } from "./style";

export default ({ url, alt_text, size, isModality4d }) => {
  if (!url) {
    return null;
  }

  const hasYouTubeFullink = url.indexOf("youtube.com") !== -1;
  const hasYouTubeShortLink = url.indexOf("youtu.be") !== -1;

  if (!hasYouTubeFullink && !hasYouTubeShortLink) {
    return null;
  }

  let videoId = "";

  if (hasYouTubeShortLink) {
    videoId = url.split("youtu.be/")[1].split("?")[0];
  } else {
    videoId = url.split("v=")[1].split("&")[0];
  }

  const thumbnail = `https://img.youtube.com/vi/${videoId}/0.jpg`;

  return (
    <VideoContainer size={size}>
      <VideoInner size={size}>
        <Link
          size={size}
          isModality4d={isModality4d}
          rel="noopener noreferrer"
          href={url}
          onClick={() =>
            console.log("Dispatching event to Google Analytics") ||
            window.ga("send", {
              hitType: "event",
              eventCategory: "Vídeo do Curso",
              eventAction: "Clique",
            })
          }
        >
          <div>
            <Icon alt="Ícone play vídeo" size={size} />
            <p>
              {!isModality4d ? (
                <>
                  ASSISTA AO <br /> VÍDEO DO CURSO
                </>
              ) : (
                <>
                  CONHEÇA A <br /> GRADUAÇÃO 4D
                </>
              )}
            </p>
          </div>
          <Img
            width="144px"
            height="108px"
            src={thumbnail}
            alt={alt_text}
            size={size}
          />
        </Link>
      </VideoInner>
    </VideoContainer>
  );
};
