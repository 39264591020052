import styled from "styled-components";
import colors from "../../../styles/colors";

export const Root = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;

export const Content = styled.div`
  background-color: ${colors.persian_indigo};
  max-width: 380px;
  border-radius: 24px;
  padding: 40px 0;
  height: auto;
  width: 100%;
  position: relative;

  @media screen and (min-width: 768px) {
    min-height: 447px;
    padding: 0;
  }
`;

export const Close = styled.button`
  background-color: transparent;
  border: none;
  width: 24px;
  height: 24px;

  position: absolute;
  top: 16px;
  right: 16px;
`;

export const Title = styled.h2`
  color: ${colors.white};
  font-size: 24px;
  line-height: 27px;
  font-weight: 700;
  text-align: center;

  padding: 10px 0 30px;

  @media screen and (min-width: 768px) {
    padding: 50px 0;
  }
`;

export const ListInstallments = styled.ul`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and (min-width: 768px) {
    padding: 0 70px;
  }
`;

export const Installment = styled.li`
  color: ${colors.white};
  list-style: none;
  font-size: 24px;
  line-height: 27px;
  font-weight: 400;
  display: flex;


  & span:nth-child(1) {
    display: inline-block;
    width: 100px;
  }

  & span:nth-child(2) {
    display: inline-block;
    width: 144px;
  }
`;

export const Description = styled.p`
  color: ${colors.white};
  font-size: 16px;
  line-height: 19px;

    
  @media screen and (min-width: 768px) {
    padding: 10px 70px;
  }
`;
