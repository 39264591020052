import Components from '../index'
import SbEditable from 'storyblok-react'
import React from 'react'
import styled from 'styled-components'
const _ = require('lodash')

class Grid extends React.Component {
  constructor() {
    super();

    this.state = {
      gridState: {},
    }
  }

  getGridState(key, defaultValue) {
    if (key) {
      return _.get(this.state.gridState, key, defaultValue)
    }

    return this.state.gridState
  }

  setGridState(state) {
    this.setState({
      gridState: {
        ...this.state.gridState,
        ...state
      },
    })
  }

  render() {
    return (
      <SbEditable content={ this.props.content }>
        <GridWrapper reverse={ this.props.content.config[0].reverse }>
          { this.props.content.columns.map((blok) =>
            Components({
              ...blok,
              grid: this.props.content.config[0],
              course: this.props.content.course,
              setGridState: this.setGridState.bind(this),
              getGridState: this.getGridState.bind(this),
              context: this.props.context,
            })
          ) }
        </GridWrapper>
      </SbEditable>
    )
  }
}

export default Grid

const GridWrapper = styled.div`
  display: flex;
  height: auto;
  flex-direction: ${props => props.reverse ? 'column-reverse' : 'column'};

  @media(min-width: 1024px) {
    flex-direction: row;
  }
`
