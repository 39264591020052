import styled from "styled-components";
import Colors from "../../../styles/colors";

export const FooterContainer = styled.footer`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    align-items: center;
  }
`;

export const LineLogo = styled.div`
  width: 100%;
  background: ${Colors.burgundy};
  display: flex;
  gap: 100px;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  padding: 50px 8px;
  position: relative;
  justify-content: center;
  
  @media (max-width: 1440px) {
    gap: 40px;
    padding: 20px 8px;
    justify-content: space-between;
  }
`;

export const LineQrCodeWrapper = styled.div`
  position: relative;

  @media screen and (min-width: 1440px) {
    position: absolute;
    right: calc(50% - 630px);
    bottom: 50%;
    transform: translateY(50%);
  }
`;

export const FooterSocial = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 23px 0;
  align-items: center;
  justify-content: center;
  margin: 0;

  @media (min-width: 1024px) {
    max-width: 980px;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: 1440px) {
    max-width: 1260px;
  }
`;

export const Copyright = styled.p`
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.42px;

  @media (min-width: 1024px) {
    margin-left: 70px;
  }
`;

export const SocialItems = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  @media (min-width: 1024px) {
    margin: 0;
  }
`;

export const Link = styled.a`
  padding: 0 5px;

  &:hover {
    opacity: 0.8;
  }
`;

export const LinkPrivacyTerms = styled.a`
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  text-decoration: underline;
  margin-top: 8px;

  @media (min-width: 1024px) {
    font-weight: 500;
    text-decoration: none;
  }

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const ImgWrapper = styled.div`
  min-width: 145px;
  
  @media screen and (min-width: 1024px) {
   width: 217px;
  }
`;

export const Img = styled.img``;

export const QRCodeDesktop = styled.img`
  cursor: pointer;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const QRCodeMobile = styled.img`
  width: 176px;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const QRCodeModal = styled.img`
  width: 100%;
  max-width: 689px;
  position: relative;
`;

export const ModalQRCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -110px;
  left: 0;
  width: 100%;

  min-height: calc(100vh + 110px);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 11;
`;

export const QRCodeWrapper = styled.div`
  width: fit-content;
  position: relative;
  margin: 0 auto;
  padding: 16px;
`;

export const Close = styled.img`
  width: 0.5rem;
  position: absolute;
  top: 2rem;
  right: 3.5rem;
  z-index: 11;

  &:hover {
    cursor: pointer;
  }
`;

// <a
//   href="https://emec.mec.gov.br/emec/consulta-cadastro/detalhamento/d96957f455f6405d14c6542552b0f6eb/MTA="
//   target="_blank"
//   rel="noopener noreferrer"
// >
//   <QRCodeMobile src={qrcode} />
// </a>
// <QRCodeDesktop onClick={handleModal} src={qrcode} />
