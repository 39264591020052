import React from "react";
import { AiOutlineEye, AiOutlineMail } from "react-icons/ai";
import { BsFillSquareFill, BsTelephone, BsWindow } from "react-icons/bs";

import Container from "../../Common/Container";
import Section from "../Components/Section";

import { Box, FooterContainer, FooterInformation, FooterInformationIcon, FooterText, IconContent, Span } from "./styles";

export default function Footer() {
  const renderIcons = () => {
    let icons = []
    for (let i = 0; i < 5; i++) {
      icons.push(<BsFillSquareFill color="#8A143A" size={6} />)
    }
    return icons
  }

  const icons = renderIcons();

  return (
    <Section bgColor="grey" noGutter>
      <Container>
        <FooterContainer>
          <Box borderBottom>
            {icons.map((icon, key) => (
              <IconContent key={key}>{icon}</IconContent>
            ))}
            <FooterText>
              Graduação 4D
            </FooterText>
            {icons.map((icon, key) => (
              <IconContent key={key}>{icon}</IconContent>
            ))}
          </Box>

          <Box borderBottom>
            <FooterText>
              Além das
              <AiOutlineEye size={30} />
              pespectivas
            </FooterText>
          </Box>
          <Box>
            <FooterInformation>
              <Span>
                Mais informações:
              </Span>

              <FooterInformationIcon>
                <AiOutlineMail size={30} />
                E-mail: <a href="mailto:graduacaodigital.news@pucpr.br">graduacaodigital.news@pucpr.br</a>
              </FooterInformationIcon>

              <FooterInformationIcon>
                <BsTelephone size={30} />
                Fone: 0800 881 2019
              </FooterInformationIcon>

              <FooterInformationIcon>
                <BsWindow size={30} />
                Site: <a href="https://4d.pucpr.br/">4d.pucpr.br</a>
              </FooterInformationIcon>

            </FooterInformation>
          </Box>
        </FooterContainer>
      </Container>
    </Section>
  )
}