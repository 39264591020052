import styled from "styled-components";
import colors from "../../../styles/colors";

export const Background = styled.section`
  background-color: ${colors.majorelle_blue};
  padding: 40px 0;

  @media screen and (min-width: 1024px) {
      padding: 80px 0;
  }
`;

export const TitleWrapper = styled.div`
  > h3 {
    text-align: center;
    color: ${colors.white};
    font-size: 24px;
    line-height: 27px;
    font-weight: 400;
    
    @media screen and (min-width: 1024px) {
      font-size: 48px;
      line-height: 54.72px;
    }
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  margin: 48px 0px;
  
  @media screen and (min-width: 1024px){
    gap: 16px;
  }
`;

export const Card = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 166px;
  height: 166px;
  border-radius: 24px;
  border: 0.5px solid rgba(255, 255, 255, 0.50);
  background-color: ${colors.indigo};
  box-shadow: 2px 2px 8px 0px ${colors.lightpurple};

  @media screen and (min-width: 1024px) {
    width: 280px;
    height: 280px;
  }
`;

export const CardTiteWrapper = styled.div`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 19px;

  h2 {
    font-size: 24px;
    line-height: 27px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 24px;
    line-height: 27px;

    h2 {
      font-size: 48px;
      line-height: 54px;
    }
  }
`;

export const CardSubTitle = styled.p`
  color: ${colors.white};
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-weight: 400;

   @media screen and (min-width: 1024px) {
    font-size: 20px;
    line-height: 22px;
  }
`;
