import styled from "styled-components";

export const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 20px 16px;

  ::-webkit-scrollbar {
    width: 0px;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 60%;
    height: 37.875rem;
    padding: 1.688rem 1.25rem 0 7.5rem;
    background: #fff;
    overflow-y: auto;
  }
`;

export const TitleTopic = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 780px;
  width: 100%;
  text-align: left;
`;

export const TitleTopicAnchor = styled.span`
  position: absolute;
  top: -102px;

  @media (min-width: 1024px) {
    top: -300px;
  }
`;
