import React from "react";
import ArrowIcon from '../../../../../assets/images/polygon-red.png';
import { CarrouselArrow } from "./styles";

export default function Arrow({ direction, onClick, disabled }) {

  return (
    <CarrouselArrow onClick={onClick} direction={direction} disabled={disabled}>
      <img src={ArrowIcon} alt="" width="100%" height="100%" />
    </CarrouselArrow>
  )
}
