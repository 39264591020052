import React from "react";
import { render } from "storyblok-rich-text-react-renderer";

import {
  FAQAccordion,
  FAQDescription,
} from './style'

export default ({ content }) => (
  <FAQAccordion>
    <FAQDescription>{ render(content) }</FAQDescription>
  </FAQAccordion>
);
