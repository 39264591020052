import styled from "styled-components";
import Colors from "../../../styles/colors";

export const VideoContainer = styled.div`
  width: 100%;
  height: auto;
  background: ${Colors.persian_indigo};
  display: flex;
  justify-content: center;
  padding: 20px 0;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const VideoInner = styled.div``;

export const Img = styled.img`
  margin-right: 16px;
  margin-bottom: 15px;
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  flex-direction: ${({ isModality4d }) => !isModality4d ? "column-reverse" : "column"};
  gap: 8px;

  &:hover {
    text-decoration: underline;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;

export const Icon = styled.img`
  margin-right: 10px;
`;
