import styled from "styled-components";
import colors from "../../../styles/colors";

export const Background = styled.section`
  background-color: ${colors.brightgray};
  padding: 48px 10px;

  @media screen and (min-width: 1024px){
    padding: 80px 0;
  }
`;

export const Container = styled.div`
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
`;

export const CouseGuideWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  padding: 0 15px;
  margin-top: 50px;

  @media (min-width: 1024px) {
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 120px;
    padding: 0 70px 0 150px;
  }
`;

export const CourseGuideImgWrapper = styled.div`
  max-width: 320px;
  width: 100%;

  img {
    width: 100%;
  }
`;

export const CourseGuideList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 1024px) {
  }
`;

export const CourseGuideListItem = styled.li`
  list-style: none;
  font-weight: 400;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  margin-left: 30px;
  letter-spacing: 3%;

  list-style-type: none;

  @media screen and (min-width: 1024px) {
    line-height: 32.2px;
    margin-left: 0;
    font-size: 24px;
  }
`;

export const IconWrapper = styled.div`
    color: ${colors.red};
    font-weight: 900;
    left: -24px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    font-size: 24px;
    width: 24px;
    height: 24px;
`;

export const Button = styled.a`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.red};
  padding: 8px;
  color: ${colors.white};
  height: 60px;
  width: 100%;
  
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
  
  @media screen and (min-width: 1024px) {
    max-width: 380px;
  }
`;
