import styled from "styled-components";
import whiteArrow from "../../../assets/images/white-arrow.png";
import Colors from "../../../styles/colors";

export const Card = styled.a`
  width: 178px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background: white;
  height: 151px;
  width: 210px;
  padding: 0;
  box-sizing: border-box;
  margin-left: 0;
  margin-right: 10px;

  :hover button {
    background-position: 60%;
  }

  :hover img {
    transform: scale(1.2);
  }
`;

export const CourseImgWrapper = styled.div`
  height: 75px;
  width: 178px;
  overflow: hidden;
  margin-bottom: 5px;
`

export const CourseImg = styled.img`
  width: 100%;
  transition: all .5s;
`;

export const CourseTitle = styled.p`
  width: 175px;
  height: 32px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  overflow: hidden;
`;

export const CourseFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CoursePresencial = styled.span`
  width: 114px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.darkpurple};
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
`;

export const CourseButton = styled.button`
  width: 58px;
  height: 27px;
  background-color: ${Colors.red};
  background-image: url(${whiteArrow});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border: 0;
  transition: all .5s;
`;
