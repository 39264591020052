import styled from "styled-components";
import colors from "../../../../styles/colors";

export const TitleWrapper = styled.div`
  position: relative;
  margin: 0 auto;
`;

export const Hr = styled.hr`
  background-color: ${colors.brightgray};
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

export const Title = styled.h4`
  position: relative;
  background-color: ${colors.brightgray};
  font-weight: 500;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  font-size: 20px;
  line-height: 22px;
  text-transform: uppercase;

  @media (min-width: 1024px) {
    font-size: 32px;
    line-height: 36px;
    padding: 0 80px;
  }  
`;