import styled from "styled-components";
import Colors from "../../../styles/colors";

export const Container = styled.div`
  background-color: ${Colors.persian_indigo};
  @media (min-width: 1024px) {
    width: 40%;
  }
`;

export const ComplementaryText = styled.div`
  width: 100%;
  height: 25rem;
  padding: 30px;
  background: ${Colors.burgundy};
  font-size: 22px;
  color: white;
  font-weight: 400;

  p {
    margin-bottom: 1rem;
  }

  a {
    color: ${Colors.lightpurple};
  }

  a:hover {
    text-decoration: underline;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;
