import React from 'react'
import CampusSection from '../CampusSection'
import { render } from "storyblok-rich-text-react-renderer";

import {
    ContactCard,
    ContactUnitName, ContactCardTitle, ContactCardContent,
    ContactCardWrapper,
} from './style'

const _ = require('lodash')

export default (props) => {
    const { units } = props.content;
    const unitNames = _.map(units, 'title')

    const getCurrentIndex = () => {
        return props.getPageState('currentIndex', 0)
    }

    const unitComponent = (unit, index) => (
        <ContactCard key={`unit-${index}`}>
            <ContactCardContent>
                <ContactCardTitle>{unit.title}</ContactCardTitle>
                {render(unit.content)}
            </ContactCardContent>
        </ContactCard>
    )

    const currentUnit = units[getCurrentIndex()]

    return (
        <>
            <CampusSection
                units={unitNames}
                getPageState={props.getPageState}
                setPageState={props.setPageState}
            />
            <ContactCardWrapper>
                <ContactUnitName>Unidade de <b>{currentUnit.title}</b></ContactUnitName>
                {currentUnit.items.map((unit, index) => unitComponent(unit, index))}
            </ContactCardWrapper>
        </>
    )
}
