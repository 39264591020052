import styled from 'styled-components';

export const CoursesContainer = styled.div`
display: flex;
overflow-x: auto;
width: 100%;
gap: 16px;

::-webkit-scrollbar {
  background-color: #fff;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  -webkit-border-radius: 10px;
}
`;