import React from "react";
import ArrowImage from '../../../../../assets/images/polygon.png';
import { CarrouselArrow } from "../Carrousel/styles";

export default function Arrow(props) {
  const { onClick, direction, className } = props;
  const disabled = className.includes('slick-disabled');

  return (
    <CarrouselArrow
      onClick={onClick}
      direction={direction}
      className={className}
      style={{
        ...props.style,
        display: disabled ? 'none' : 'flex',
        right: direction === 'right' ? '-120px' : 'auto',
        left: direction === 'left' ? '-120px' : 'auto',
      }}
    >
      <img src={ArrowImage} alt="" width="100%" height="100%" />
    </CarrouselArrow>
  )
}
