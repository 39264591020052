export default {
  red: "#FF0040",
  darkred: "#8A0538",
  lightpurple: "#9654FF",
  purple: "#782FEB",
  green: "#26AD89",
  burgundy: "#8A0538",
  grey: "#EAEAEA",
  brightgray: "#EDEDED",
  darkgrey: "#B4B4B4",
  white: "#FFFFFF",
  darkpurple: "#2F007A",
  silver: "#A4A4A4",
  persian_indigo: "#2F007A",
  indigo: "#420B99",
  darkgray: "#878484",
  redWine: "#8A144A",
  raspberry: "#ED145B",
  majorelle_blue: "#7046DB",
  black: "#000000",
};
