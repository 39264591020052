import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

import boletoImg from "../../../assets/images/boleto.svg";
import creditCardImg from "../../../assets/images/cartao.svg";
import Container from "../../Common/Container";
import Section from "../Components/Section";
import SectionTitle from "../Components/SectionTitle";
import SignUpModal from "../SignUpModal";

import { Button, ButtonText, Card, CardContent, CardItem, CardText, EndIncon, Installments, KnowMoreContent, PaymentMethod } from "./styles";

export default function Prices() {
  const [isOpenSignUpModal, setIsOpenSignUpModal] = useState(false);
  const [showKnowMore, setShowKnowMore] = useState(false);

  const handleShowKnowMore = () => {
    setShowKnowMore(!showKnowMore);
  }

  const handleOpenSignUpModal = () => {
    setIsOpenSignUpModal(true);
  }

  const handleCloseSignUpModal = () => {
    setIsOpenSignUpModal(false);
  }

  const bankSlipOptions = [
    { value: 'R$ 1.000,00', label: '48x' },
    { value: 'R$ 2.000,00', label: '24x' },
    { value: 'R$ 4.000,00', label: '12x' },
  ]

  return (
    <Section bgColor="white">
      <Container>
        <SectionTitle bgColor="white">
          inscreva-se
        </SectionTitle>

        <Card>
          <CardContent>
            <CardItem>
              <CardText>
                Aulas
                <strong>100% digitais</strong>
                <br />
                em 48x de
              </CardText>
              <CardText size="large">
                R$ 1000,00
              </CardText>
            </CardItem>
            <hr />
            <CardItem>
              <CardText alignCenter>
                Pague no boleto
                <br />
                ou no cartão de crédito
              </CardText>
              <Button onClick={handleOpenSignUpModal}>Matricule-se</Button>
            </CardItem>
          </CardContent>

          <ButtonText onClick={handleShowKnowMore}>
            Saiba mais
            <EndIncon show={showKnowMore}>
              <MdKeyboardArrowDown size={30} />
            </EndIncon>
          </ButtonText>

          <KnowMoreContent show={showKnowMore}>
            <PaymentMethod>
              <img src={boletoImg} alt="boleto bancario" />
              <Installments>
                {bankSlipOptions.map((option) => (
                  <span key={option.label}>{option.label}</span>
                ))}
              </Installments>
              <Installments>
                {bankSlipOptions.map((option) => (
                  <span key={option.label}>{option.value}</span>
                ))}
              </Installments>
            </PaymentMethod>

            <PaymentMethod>
              <img src={creditCardImg} alt="cartao de credito" />
              <Installments>
                <span>{bankSlipOptions[0].label}</span>
              </Installments>
              <Installments>
                <span>{bankSlipOptions[0].value}</span>
              </Installments>
            </PaymentMethod>
          </KnowMoreContent>
        </Card>

        {isOpenSignUpModal && (
          <SignUpModal isOpen={isOpenSignUpModal} onRefuse={handleCloseSignUpModal} />
        )}
      </Container>
    </Section>
  )
}
