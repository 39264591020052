import styled from "styled-components";

const colors = {
  darkRed: 'rgba(139,24,56,1)',
  grey: '#eaeaea',
  white: '#ededed',
};


export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
`

export const Hr = styled.hr`
  opacity: .5;
  background-color: #000;
  position: relative;
  top: 22px;
  border-width: 2px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.h2`
  background-color: ${props => colors[props.bgColor] || colors.grey};
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  width: fit-content;
  margin: 0 auto;
  padding: 0 30px; 
  line-height: 40px;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 0 5%;
  }
`;
