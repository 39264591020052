import styled from "styled-components";
import colors from "../../../styles/colors";

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  margin-top: 120px;
`;

export const FeaturedTitle = styled.span`
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
`;

export const List = styled.ul`
  width: 100%;
  margin-top: 30px;
  padding-right: 10px;

  @media (min-width: 1024px) {
    overflow-y: auto;
    max-height: 525px;

    &::-webkit-scrollbar {
      background-color: #fff;
      width: 8px;
      -webkit-border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: lightgrey;
      -webkit-border-radius: 10px;
    }
  }
`;

export const ListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 10px;
  cursor: pointer;
  border-bottom: 1px dashed #fff8;

  @media (min-width: 1024px) {
    padding: 28px 0 20px;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 76px);
  }
`;

export const ItemName = styled.h3`
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
  width: 240px;

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 20px;
    width: 322px;
  }
`;

export const OpenSituation = styled.span`
  width: 109px;
  height: 25px;
  background-color: ${colors.lightpurple};
  margin-top: 4px;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    width: 114px;
    height: 27px;
  }
`;

export const CloseSituation = styled(OpenSituation)`
  text-align: center;
  background-color: ${colors.red};
`;

export const Button = styled.a`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  cursor: pointer;

  @media (min-width: 1024px) {
    width: 30px;
    height: 30px;
  }

  img {
    transition: all 0.7s;
  }

  &.is-active img {
    transform: rotate(45deg);
  }
`;
