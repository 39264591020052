import styled from "styled-components";
import colors from "../../../styles/colors";
import Container from "../../Common/Container";

export const HeroContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  min-height: 708px;
  background-image: url(${({ srcMobile }) => srcMobile});
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (min-width: 728px) {
    background-image: url(${({ src }) => src});
  }

  @media screen and (min-width: 1024px) {
    min-height: 660px;
    flex-direction: row;
    justify-content: flex-start;
    background-position: center;
    align-items: center;
  }

  @media screen and (min-width: 1600px) {
    background-position: center top;
  }
`;

export const InfoContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  
  height: 100%;
  padding-bottom: 60px;

  @media screen and (min-width: 1024px) {
    padding: 200px 0 0;
    align-items: flex-end;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 519px;
  gap: 32px;
  
  @media screen and (min-width: 1024px) {
    gap: 48px;
  }

`;

export const Title = styled.h4`
  font-weight: 900;
  color: ${colors.white};
  font-size: 32px;
  line-height: 41px;
  text-align: center;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  
  @media screen and (min-width: 1024px) {
    font-size: 48px;
    text-align: left;
    line-height: 54px;
    margin-top: 30px;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  @media screen and (min-width: 1024px) {
    gap: 16px;
    width: auto;
  }
`;

export const Button = styled.a`
  background-color: ${({ color }) => colors[color]};
  color: ${colors.white};
  padding: 20px 0px;
  scroll-behavior: smooth;
  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  font-size: 16px;
  font-weight: 700;
  width: 50%;
  
  @media screen and (min-width: 1024px) {
    width: auto;
    padding: 20px 32px;
  }
`;

export const Divider = styled.div`
  height: 29px;
  width: 100%;
  background-color: ${colors.white};
`;