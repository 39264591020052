import styled from "styled-components";
import iconDropdown from "../../../assets/images/icon-dropdown.png";
import colors from "../../../styles/colors";

export const SubscribeContainer = styled.section`
  background-color: ${colors.lightpurple};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-image: url(${({ srcMobile }) => srcMobile});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 46px 0;
  justify-content: center;

  @media (min-width: 1024px) {
    border-top: 1.81rem solid ${colors.white};
    background-image: url(${({ src }) => src});
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    width: 50%;
    justify-content: flex-end;

    padding: 100px 38px;
  }

  @media (min-width: 1600px) {
    /* background-position: bottom; */
  }
`;

export const Img = styled.img`
  width: 50%;
  height: 50%;
  margin-right: 20px;

  @media (max-width: 1520px) {
    height: 45%;
  }

  @media (max-width: 769px) {
    display: none;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  position: relative;
  padding-top: 50px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  width: 205px;
  font-size: 32px;
  line-height: 36px;
  text-transform: uppercase;
  font-weight: 400;
  color: white;

  @media (min-width: 1024px) {
    color: #fff;
  }
`;

export const Detail = styled.span`
  position: absolute;
  top: 11%;
  right: 10%;
  z-index: 0;
  overflow: hidden;
  width: 200px;
  height: 45px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 50px;
`;

export const FeaturedTitle = styled.span`
  font-size: 72px;
  line-height: 82px;
  font-weight: 800;
  color: white;
  z-index: 1;

  @media (min-width: 1024px) {
    color: #fff;
  }
`;

export const Description = styled.p`
  width: 272px;
  font-size: 18px;
  line-height: 21px;
  color: white;
  margin: 24px 0 60px;

  @media (min-width: 1024px) {
    color: #fff;
  }
`;

export const Label = styled.label`
  color: #fff;
  font-weight: 700;
  padding: 0 0 5px 10px;
`;

export const Select = styled.select`
  width: 300px;
  height: 50px;
  margin-bottom: 30px;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(${iconDropdown}) no-repeat right #fff;
  background-origin: content-box;
  font-size: 1rem;
  padding: 0 15px;
  color: #0207;

  &:focus {
    outline: none;
  }
`;

export const SelectItem = styled.option`
  color: black;
`;

export const Button = styled.button`
  width: 300px;
  height: 60px;
  background-color: ${colors.red};
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  transition: all 0.5s;

  &:hover {
    letter-spacing: 0.7px;
  }
`;

export const Icon = styled.img`
  margin-left: 10px;
  transition: all 0.7s;
`;
