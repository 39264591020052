import Colors from "../../../styles/colors";
import styled from "styled-components";

export const FeaturingCourses = styled.div`
  width: 100%;
  display: flex;
  background: none;
  justify-content: center;
  height: auto;
  position: relative;

  @media (min-width: 1024px) {
    height: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 36px 10px 17px;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 1024px) {
    position: absolute;
    top: -100px;
    width: 100%;
    max-width: 980px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: 1440px) {
    max-width: 1260px;
  }
`;

export const IntroContainer = styled.div`
  width: 100%;
  padding: 0 23px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (min-width: 1024px) {
    flex-direction: column;
    width: 170px;
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  line-height: 27px;
  font-weight: 400;
  letter-spacing: 0px;
  width: 220px;

  span {
    font-weight: 700;
  }

  @media (min-width: 1024px) {
    width: 170px;
    margin-bottom: 20px;
  }
`;

export const Button = styled.a`
  font-size: 16px;
  line-height: 18px;
  width: 150px;
  color: ${Colors.lightpurple};
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonValue = styled.p``;

export const Icon = styled.img`
  margin-left: 10px;
  transition: all 0.5s;
`;

export const CoursesContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;

  @media (min-width: 1024px) {
    width: 80%;
  }

  @media (min-width: 1440px) {
    width: 82%;
  }

  ::-webkit-scrollbar {
    background-color: #fff;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    -webkit-border-radius: 10px;
  }
`;
