import ApolloClient, { InMemoryCache } from "apollo-boost";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import { InMemoryCache, } from '@apollo/client'
import { ApolloProvider } from "react-apollo";
import { VideoContextProvider } from "./helpers/videoContext";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const token = process.env.REACT_APP_API_TOKEN;

const client = new ApolloClient({
  uri: "https://gapi.storyblok.com/v1/api",
  cache: new InMemoryCache(),
  request: (operation) => {
    console.log(process.env.NODE_ENV)
    operation.setContext({
      headers: {
        token,
        version: process.env.REACT_APP_API_VERSION || 'published',
      },
    });
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <VideoContextProvider>
      <App />
    </VideoContextProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
