import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { WhatsappIconWrapper } from "./styles";

export default function WhatsappIcon() {
  return (
    <WhatsappIconWrapper
      href="https://api.whatsapp.com/send?phone=5541991321897&text=Ol%C3%A1%21+Quero+falar+com+um+especialista."
      target="_blank"
    >
      <FaWhatsapp />
    </WhatsappIconWrapper>
  )
}