import React, { useState } from "react";
import {
  Button,
  DashedLine,
  ItemName,
  ItemsContainer,
  List,
  ListItem
} from './style';

import addIcon from "../../../assets/images/white-add-icon.png";
import FAQAccordion from "../FAQAccordion";

const FAQAccordionList = (props) => {
  const [isClicked, setIsClicked] = useState(false);

  const toggleAccordion = (index) => {
    if (isClicked === index) {
      return setIsClicked(null);
    }

    setIsClicked(index);
  };

  return (
    <List>
      {props.items.map((item, index) => {
        return (
          <React.Fragment key={`topic-item-${index}`}>
            <ListItem onClick={() => toggleAccordion(index)}>
              <ItemName clicked={isClicked === index}>{item.name}</ItemName>
              <ItemsContainer>
                <Button className={isClicked === index ? 'is-active' : ''}>
                  <img src={addIcon} alt='Ícone de abrir' />
                </Button>
              </ItemsContainer>
            </ListItem>
            {isClicked === index ? (
              <FAQAccordion content={item.content} />
            ) : null}
            <DashedLine />
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default FAQAccordionList;
