import React from "react"
import Container from "../../Common/Container"
import Section from "../Components/Section"
import SectionDescription from "../Components/SectionDescription"
import SectionTitle from "../Components/SectionTitle"

import GatherGif from '../../../assets/images/gather.gif'


export default function OneMoreThing() {
  return (
    <Section bgColor="grey">
      <Container>
        <SectionTitle bgColor="grey">
          Ops... One More Thing
        </SectionTitle>
        <SectionDescription>
          Não é só no presencial, a convivência virtual já existe
        </SectionDescription>

        <div
          style={{ marginTop: '80px', display: 'flex', gap: 50, paddingInline: '20px', width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}
        >
          <div style={{ maxWidth: '325px' }}>
            <img src={GatherGif} alt="Gif Gather" style={{ maxWidth: '100%' }} />
          </div>
          <div style={{ maxWidth: '600px', display: 'flex', alignItems: 'center', fontWeight: 'normal' }}>
            <p style={{ fontWeight: 'normal', textAlign: 'justify', fontSize: '20px' }}>
              Um espaço virtual infinito, criado a partir da mente humana, onde todas as interações são possíveis e você é o protagonista da sua história. Nesse ambiente, como num game, você pode viajar para lugares inimagináveis e conhecer pessoas incríveis. Vai ter aulas e palestras, realizar apresentações e fazer network. O metaverso é um lugar onde a imaginação é o limite, venha viver essa experiência única e inesquecível.
            </p>
          </div>
        </div>
      </Container>
    </Section>
  )
}