import styled from "styled-components";
import colors from "../../../styles/colors";

export const TopicsContainer = styled.div`
  background-color: ${colors.persian_indigo};
  height: auto;
  width: 100%;
  padding: 40px 16px 20px;
  position: relative;
  display: flex;
  justify-content: center;

  @media(min-width: 1024px) { 
    justify-content: flex-start;
    width: 40%;
  }

`

export const TopicsContainerInner = styled.div`
  width: 100%;
  max-width: 375px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media(min-width: 1024px) { 
    max-width: none;
    height: 41.875rem;
    padding-left: 2rem;
  }
`

export const TopicsRedBar = styled.div`
  display: none;
  background-color: ${colors.persian_indigo};

  @media(min-width: 1024px) { 
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
    height: 65px;
    top: -65px;
  }
`

