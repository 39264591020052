import styled, { keyframes } from "styled-components";

const WppBtn = keyframes`
  0% {
    margin-bottom: -100px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-bottom: 0;
  }
`;

export const WhatsappIconWrapper = styled.a`
  animation: ${WppBtn} 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  background: #128c7e;
  background: radial-gradient(50% 50% at 50% 50%,#39c34a 70.66%,#25ab35 100%);
  border-radius: 100%;
  bottom: 20px;
  box-shadow: 1px 2px 5px #0000005c;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 30px;
  opacity: 0;
  padding: 10px 12px 7px;
  position: fixed;
  right: 20px;
  transition: all .2s linear;
  width: fit-content;
  z-index: 50;

  :hover {
    background: radial-gradient(50% 50% at 50% 50%,#39c34a 70.66%,#25ab35 100%);
    transform: scale(1.1);
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
  
  svg {
    text-shadow: 1px 1px 1px #07655c;
  }
`;
