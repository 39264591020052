import React, { useState } from "react";
import close from "../../../assets/images/close.png";
import facebook from "../../../assets/images/facebook.png";
import instagram from "../../../assets/images/instagram.png";
import linkedin from "../../../assets/images/linkedin.png";
import logo from "../../../assets/images/logo-footer.png";
import qrcodefull from "../../../assets/images/qr-code-full.png";
import qrcode from "../../../assets/images/qr-code.png";
import youtube from "../../../assets/images/youtube.png";
import {
  Close,
  Copyright,
  FooterContainer,
  FooterSocial,
  Img,
  ImgWrapper,
  LineLogo,
  LineQrCodeWrapper,
  Link,
  LinkPrivacyTerms,
  ModalQRCode,
  QRCodeDesktop,
  QRCodeMobile,
  QRCodeModal,
  QRCodeWrapper,
  SocialItems
} from "./style";

export default (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const date = new Date();
  const currentYear = date.getFullYear();
  const showQrCode = props.content.qrcode || false;


  const handleModal = (e) => {
    if (e.target !== this) {
      setIsModalOpen(false);
    }

    setIsModalOpen(!isModalOpen);

    document.querySelector("body").style.overflow = isModalOpen
      ? "initial"
      : "hidden";
  };

  return (
    <FooterContainer className="footer">
      {isModalOpen && (
        <ModalQRCode onClick={handleModal}>
          <QRCodeWrapper>
            <Close onClick={handleModal} src={close} />
            <QRCodeModal src={qrcodefull} />
          </QRCodeWrapper>
        </ModalQRCode>
      )}

      <LineLogo>
        <ImgWrapper>
          <Img width="100%" src={logo} alt="Logo PUC - Grupo Marista" />
        </ImgWrapper>

        {showQrCode && (
          <LineQrCodeWrapper>
            <QRCodeDesktop onClick={handleModal} src={qrcode} />
            <a
              href="https://emec.mec.gov.br/emec/consulta-cadastro/detalhamento/d96957f455f6405d14c6542552b0f6eb/MTA="
              target="_blank"
              rel="noopener noreferrer"
            >
              <QRCodeMobile src={qrcode} />
            </a>
          </LineQrCodeWrapper>
        )}
      </LineLogo>
      <FooterSocial>
        <LinkPrivacyTerms href="https://privacidade.grupomarista.org.br/Privacidade/">
          Termos de Privacidade
        </LinkPrivacyTerms>
        <Copyright>
          {props.content.copyright_text ||
            `Todos os Direitos Reservados ® - PUCPR - ${currentYear}`}
        </Copyright>
        <SocialItems>
          <Link href="https://www.facebook.com/pucproficial">
            <Img src={facebook} alt="Ícone do Facebook" />
          </Link>
          <Link href="https://www.instagram.com/pucproficial/">
            <Img src={instagram} alt="Ícone do Instagram" />
          </Link>
          <Link href="https://www.youtube.com/user/canalpucpr">
            <Img src={youtube} alt="Ícone do Youtube" />
          </Link>
          <Link href="https://www.linkedin.com/school/pontificia-universidade-catolica-do-parana/?originalSubdomain=br">
            <Img src={linkedin} alt="Ícone do LinkedIn" />
          </Link>
        </SocialItems>
      </FooterSocial>
    </FooterContainer>
  );
};
