import React from "react";
import { render } from "storyblok-rich-text-react-renderer";
import InfoCourseList from "../InfoCourseList";
import Subscribe from "../Subscribe/Subscribe";
import { SidebarContainer, SidebarContainerInner } from "./style";

const strimContent = (content) => {
  return render(content);
};

export default ({
  name,
  campus,
  turno,
  duracao,
  link,
  mensalidades,
  methods,
  modalities,
  currentIndexModality,
}) => {
  let normalizedUrl = typeof link === "string" ? link : link.url;

  if (normalizedUrl && normalizedUrl.indexOf("object Object") !== -1) {
    console.log("Este curso possui um URL com problema!");
    normalizedUrl = "";
  }

  return (
    <SidebarContainer>
      <SidebarContainerInner>
        <InfoCourseList
          modality={name}
          campus={campus}
          schedule={turno}
          duration={duracao}
          link={normalizedUrl}
          monthlypayment={strimContent(mensalidades)}
          showPopUp={modalities[currentIndexModality].show_pop_up}
          installments={modalities[currentIndexModality].payment_plans}
          description={modalities[currentIndexModality].installment_description}
        />
        <Subscribe
          methods={methods}
          currentIndexModality={currentIndexModality}
          modalities={modalities}
        />
      </SidebarContainerInner>
    </SidebarContainer>
  );
};
